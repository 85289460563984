// react 
import React, { useEffect } from 'react';
// ..........

// package components
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
// ..........

ChartJS.register(ArcElement, Tooltip, Legend);

// Chart data as props
export const data = {
  labels: ['Completed', 'To visit again', 'NOT Yet Started'],

  datasets: [
    {
      data: [40, 10, 20],
      backgroundColor: [
        '#0AB39C',
        '#299CDB',
        '#F06548'
      ],
      hoverOffset: 2,
      borderColor: [
        '#ffff',
        '#ffff',
        '#ffff',
      ],
      borderWidth: 3,
    },
  ],
};
// ..........

interface ChartComponentProps {
  onLabelClick: (label: string) => void;
  counts: any;
  className?: any
}

const Phchart: React.FC<ChartComponentProps> = ({ counts, onLabelClick }) => {
  data.datasets[0].data = counts;

  // make chart labels interactive 
  const handleChartClick = (event: any, chartData: any) => {
    if (chartData[0]) {
      const index = chartData[0].index;
      const label = data.labels[index]; // Get clicked label

      onLabelClick(label);

    }
  };
  // ..........

  return <Doughnut
    data={data}
    style={{ width: '100%', height: 100, cursor: 'pointer' }}
    options={{
      onClick: (event, chartData) => handleChartClick(event, chartData),
      responsive: true,
      plugins: {
        subtitle: {
          display: true,
          text: 'Chart.js Doughnut Chart'
        },
        tooltip: {
          enabled: true,
        }
        ,
        decimation: {
          enabled: false
        },
        filler: {
          propagate: true
        }
        ,
        legend: {
          display: false
        },
      }
    }} />;
}
export default Phchart;
// react 
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// ..........

// scss file
import './casesummary.scss';
// ..........

// package components
import { Input, Modal, Pagination } from 'antd'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Badge, Drawer, Typography } from '@mui/material';
import Table from 'antd/es/table'
// ..........

// child components
import { useGlobalState } from '../../Statecontext';
import { NotifyAlert } from '../../components/notify_alert';
import { ZTable2 } from '../../components/table2';
import { serviceConfig } from '../../core/services';
// ..........

// icons
import filterIcon from '../../assets/images/filter.svg';
import active_filtericon from '../../assets/images/filter_active_icon.svg';
import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import searchIcon from '../../assets/images/search.svg';
import { AppContext } from '../../core/utils/Context';
import EastIcon from '@mui/icons-material/East';
import { Utils } from '../../core/utils/utils';
// ..........

interface Componentprops {
    selectedgst: any;
}

export const Casessumary: React.FC<Componentprops> = (props) => {

    // form values states
    const [States, setStates] = useState([]);
    const [case_type, setCasetype] = useState([]);
    const [Gst_in, setGstin] = useState([])
    const [gstid, setGstid] = useState('')
    const [selectedstate, setSelectedState] = useState('')
    const [managegstdetails, setManagegstdetails] = useState([])
    const [fileurl, setFileurl] = useState([])
    const [toggledrawer, setToggledrawer] = useState(false)
    const [userRole, setUserRole] = useState<any>()
    const [noticeObj, setNoticeObj] = useState(false);
    const [drawersource, setDrawersource] = useState<any>([])
    const [summarydetails, setSummarydetails] = useState<any>([])
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [expanded, setExpanded] = useState<boolean>(false)
    var [tableHeader, setTableHeader] = useState<any>()
    const inputFileElement = useRef<any>();
    const { formValues, handleChange, handleInitial } = useContext(AppContext)
    const { gstin, } = formValues;
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // limit & filter states
    const gstTableElement = useRef<any>();
    const [filter, setFilter] = useState(false)
    const [caseIdFilter, setCaseIdFilter] = useState<any>([])
    const [secondaryFilter, setSecondaryFilter] = useState<any>([])
    const [secFilterCase, setSecFilterCase] = useState('')
    const [arnFilter, setArnFilter] = useState<any>([])
    const [sessionFilter, setSessionFilter] = useState<any>([])
    const { setBreadcrumbPath, setSyncDetails, setProgres, setreportModal, masterData, overviewDetails, filterQuery, setFilterQuery, sorterQuery, searchquery, gstSyncModal, setGstSyncModal, totalpages, setTotalpages, page, tabactive, sumpopup, setSumpopup, sumdrawer, setSumdrawer, drawergst, setdrawergst, drawerquery, setdrawerquery, drawerloading, setDrawerloading, rowsPerPage, statuschange, setStatuschange, selectedGstRows, setSelectedGstRows, addnotice, setAddnotice, userdata, setNoticeQuery, setSubselectgst } = useGlobalState();
    const [secStateFilter, setSecStateFilter] = useState<any>({ state: [], arn_nos: [], case_ids: [], section: [] })
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    const [drawertotal, setDrawertotal] = useState(0)
    const [drawerpage, setDrawerpage] = useState(1)
    // ..........

    //  datas states
    const [userTradeMaster, setUserTradeMaster] = useState<any>([])
    const [userGSTMaster, setUserGSTMaster] = useState<any>([])
    const [userStateMaster, setUserStateMaster] = useState<any>([])
    const [noticeData, setNoticeData] = useState<any>([]);
    const [globalSearch, setGlobalSearch] = useState('');
    const [isSyncAllProgress, setIsSyncAllProgress] = useState(false)
    const settings = ['Sort by Case Status', 'Sort by Portal Status', 'Sort by Case Id'];
    // ..........

    // reference states
    const abortControllerRef = useRef<AbortController | null>(null);
    const { ticket, MultiNoticeUpdate, handlesync } = Utils();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [ismanage, setIsmanage] = useState(false)
    const ismanageRef = useRef(ismanage);
    const manageGstDataLoading = useRef(false)
    // ..........

    // filter and header function
    var dv = document.createElement("div");
    dv.className = 'secondary_filter'
    dv.style['height'] = '50px';
    dv.style['width'] = '60vw';
    dv.style['background'] = '#ecedf1';
    dv.style['position'] = 'absolute';
    dv.style['zIndex'] = '1000';
    dv.style['display'] = 'flex';
    dv.style['alignItems'] = 'center';
    dv.style['justifyContent'] = 'space-evenly';
    dv.style['padding'] = '0 2%';
    dv.innerHTML = '<span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">State <img class="filterIconQuery" id="StateFilter" style="margin-left:5%;cursor:pointer;" src=' + filterIcon + ' alt="filter icon" /></span><span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">Case ID <img class="filterIconQuery" id="CaseidFilter" style="margin-left:5%;cursor:pointer;" src=' + filterIcon + ' alt="filter icon" /></span><span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">Ref ID <img class="filterIconQuery" id="ArnFilter" style="margin-left:5%;cursor:pointer;" src=' + filterIcon + ' alt="filter icon" /></span><span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">Section <img class="filterIconQuery" id="SessionFilter" style="margin-left:5%;cursor:pointer;" src=' + filterIcon + ' alt="filter icon" /></span>'

    const loadGNotices = useCallback((search_data?: any) => {

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController for the new request
        const controller = new AbortController();
        abortControllerRef.current = controller;
        const signal = controller.signal;

        setLoading(true)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage,
        }
        if (searchquery) {
            query['search'] = searchquery;
        }
        if (filterQuery.gst_no) {
            query['gst_no'] = filterQuery.gst_no.join(',')
        }
        if (overviewDetails.gst_no) {
            query['gst_no'] = overviewDetails.gst_no
        }
        if (filterQuery.state) {
            query['state'] = filterQuery.state.join(',')
        }
        if (filterQuery.trade_name) {
            query['trade_name'] = filterQuery.trade_name.join(',')
        }
        if (filterQuery.user_name) {
            query['user_name'] = filterQuery.user_name.join(',')
        }
        if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
            query['tax_period'] = filterQuery.tax_period.join(',')
        }
        if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
            query['arn_nos'] = filterQuery.arn_nos.join(',')
        }
        if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
            query['case_ids'] = filterQuery.case_ids.join(',')
        }
        if (filterQuery.section && filterQuery.section.length > 0) {
            query['section'] = filterQuery.section.join(',')
        }
        if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
            query['notice_status'] = filterQuery.notice_status.join(',')
        }
        if (filterQuery.due_day && filterQuery.due_day.length > 0) {
            query['days'] = filterQuery.due_day.join(',')
        }
        if (filterQuery.due_from_date && filterQuery.due_to_date) {
            query['notice_start_due_date'] = filterQuery.due_from_date
            query['notice_end_due_date'] = filterQuery.due_to_date
        }
        if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
            query['notice_start_issue_date'] = filterQuery.notice_from_date
            query['notice_end_issue_date'] = filterQuery.notice_to_date
        }
        if (filterQuery.amount_from && filterQuery.amount_oper) {
            query['tax_amount'] = filterQuery.amount_from
            if (filterQuery.amount_oper) {
                query['tax_amount_op'] = filterQuery.amount_oper
            }
        }
        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (tabactive === 'Additional' || tabactive === 'Manual') {
            query['master_type'] = tabactive
        }
        query['sort'] = "tax_period,asc"

        serviceConfig.get("get_cases", true, query, null, signal).then((data: any) => {
            if (!signal.aborted) {
                setNoticeData(data.results || data.data)
                setTotalpages(data.count)
                setLoading(false)
            }
        }, (err: any) => {
            if (!signal.aborted) {
                console.log(err)
                setLoading(false)
                if (err.message === "Request failed with status code 401") {
                    navigate('/login')
                    localStorage.clear()
                    window.location.reload();
                }
            }
        })
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, filterQuery, sorterQuery, searchquery, tabactive])


    useEffect(() => {
        setUserRole(localStorage.getItem('userRole') || '')
        loadGNotices()
        const storedUserRole = localStorage.getItem('userRole');

        // get states list add notice manual
        const getStates = async (country: string) => {
            const url = `https://countriesnow.space/api/v0.1/countries/states/q/?country=${country}`;
            return await axios.get(url);
        };

        getStates('India').then((res: any) => {
            setStates(res.data.data.states)
        })
        if (sumdrawer) {
            handledrawerfilter()
        }
    }, [loadGNotices, filterQuery])


    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
        setIsMobile(mediaQuery.matches);

        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addListener(handleResize);

        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, []);

    // ...........

    // getsolution function	
    const createTickets = (selectedNotice?: any) => {
        if (selectedNotice) {
            ticket(selectedNotice).then((data: any) => {
                setNotifyType("success")
                setNotifyMessage("Support ticket created successfully.")
                setNotifyOpen(true)
                loadGNotices()
            }).catch((err: any) => {
                setNotifyType("error")
                setNotifyMessage(err.response.message)
                setNotifyOpen(true)
            })
        } else {
            setNotifyType("warning")
            setNotifyMessage("Please select atleast one notice to get solution!")
            setNotifyOpen(true)
        }
    }
    // ...........

    // search 
    const search = (value: any) => {
        if (summarydetails[0].notice) {
            setNoticeObj(true);
        }
        const filterTable = summarydetails.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );

        setDrawersource(filterTable);
    };

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;
        setGlobalSearch(e.target.value)


        if (userRole === 'Super Admin') {
            let query: any = {
                tradename: [value]
            };
            setFilterQuery(query);
        } else {
            search(value);
        }

    }
    // .............

    // viewticket
    const navigate = useNavigate()
    const handleviewticket = (value: any) => {
        // navigate(`/home/${value}/view-notices`)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage,
        }
        let notice_id: any = value;

        // Ensure notice_id is an array
        if (!Array.isArray(notice_id)) {
            // If notice_id is not an array, convert it to an array containing only the original value
            notice_id = [notice_id];
        }

        query['gst_case_id'] = notice_id.join(',')

        query['master_type'] = 'Additional'

        setNoticeQuery(query)
        navigate(`/home/${value}/case-summary-details`)
    }
    // ...........

    // Anchor
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    // ...........

    // handledrawerfilter
    const handledrawerfilter = useCallback((gst_no?: any, trade_name?: any, query?: any, is_over_due?: any) => {
        { isMobile ? setSumpopup(true) : setSumdrawer(true) }
        setDrawerloading(true)
        setDrawersource([])
        setSummarydetails({})
        setdrawergst(gst_no)
        setdrawerquery(query)
        let updatedQuery: any = {
            gst: gst_no || drawergst,
        };
        if (query) {
            updatedQuery['case_status'] = [query]
        } else if (drawerquery) {
            updatedQuery['case_status'] = [drawerquery]
        }

        serviceConfig.get("get_cases", true, updatedQuery, null).then((data: any) => {
            setSummarydetails(data)
            setDrawersource(data)
            setDrawerloading(false)
        }, (err: any) => {
            console.log(err)
            setDrawerloading(false)
        })
    }, [])

    // drawer pagination
    const handlePageChange = (page: number) => {
        setDrawerpage(page);
    };

    const indexOfLastItem = drawerpage * 10;
    const indexOfFirstItem = indexOfLastItem - 10;
    const currentItems = drawersource.slice(indexOfFirstItem, indexOfLastItem);
    const formatter = new Intl.NumberFormat('en-IN')

    // sync initiate function
    const syncProgress = useRef(false)
    let [syncId, setSyncId] = useState<any>(null)
    const synccallfunction = (selectedGST?: any) => {
        console.log([props.selectedgst])
        handlesync([props.selectedgst])
            .then((data: any) => {
                setIsSyncAllProgress(data.isSyncAll)
                syncId = data.data.syncId
                setSyncId(data.data.syncId)
                setGstSyncModal(true)
                setTimeout(() => setGstSyncModal(false), 40000)
                syncProgress.current = false;
                getStatusofSyncAllProgress()
                loadGNotices()
            })
            .catch((err: any) => {
                setNotifyType("error")
                console.log(err)
                setNotifyMessage(err.response.message)
                setNotifyOpen(true)
                syncProgress.current = false;
            });
    }

    const getLastSyncStatus = () => {
        serviceConfig.get("gst_sync", true, { 'last': true }, null)
            .then((data: any) => {
                if (data.length > 0) {
                    setSyncDetails(data[0])
                    !data[0].sync_processed && setIsSyncAllProgress(true)
                    // !data[0].sync_processed && setIsSyncAllProgress(data[0].is_sync_all)
                    if (!data[0].sync_processed && data[0].is_sync_all) {
                        syncId = data[0].id
                        setSyncId(data[0].id)
                        getStatusofSyncAllProgress()
                    }
                }
            }, (err: any) => {
                console.log(err)
            })
    }

    const getStatusofSyncAllProgress = () => {
        setProgres({ data: { loading: true, label: 'Sync' } })
        serviceConfig.get("gst_sync_details", true, null, { id: syncId }).then((data: any) => {
            setSyncDetails(data)
            setIsSyncAllProgress(!data.sync_processed ? true : false)
            // setIsSyncAllProgress((data.is_sync_all && !data.sync_processed) ? true : false)
            syncId = data.id
            setSyncId(data.id)
            let timerInt = !data.sync_processed ? setTimeout(() => {
                if (ismanageRef.current) {
                    loadGNotices();
                }
                // { ismanage && loadGst() }
                getStatusofSyncAllProgress();
            }, (10 * 1000)) : null;
            if (data.sync_processed) {
                timerInt = null;
                setreportModal(true)
                syncProgress.current = false;
                if (ismanageRef.current) {
                    loadGNotices();
                }
                // { ismanage && loadGst() }
                manageGstDataLoading.current = false;
                setProgres({ data: { loading: false, label: '' } })
            }
        }, (err: any) => {
            console.log(err)
            setProgres({ data: { loading: false, label: '' } })
        })
    }
    // ........

    // button details props
    const buttondetails = [
        {
            type: 'get',
            name: 'Get Solution from Zen Tax Clinic',
            theme: 'theme2',
            conditions: ['if_list'],
            function: createTickets
        },
        {
            type: 'sync',
            name: 'Sync Now',
            theme: 'theme3',
            conditions: [],
            function: synccallfunction
        },
        {
            type: 'download',
            name: 'Download',
            theme: 'theme4',
            conditions: ['if_list'],
            downloadquery: 'get_group_cases'
        },
    ]
    // ............

    // table columns and filter requirement props
    // const columns = [
    //     // {
    //     //     title: 'Company Name',
    //     //     dataIndex: 'gst', key: 'trade_name',
    //     //     sorter: true,
    //     //     sortDirection: sorterQuery.columnKey === 'trade_name' ? sorterQuery.order : 'ascend',
    //     //     filters: masterData.trades
    //     //         .filter((item: any) => item !== null && item.trim() !== "")
    //     //         .map((item: any) => {
    //     //             return ({
    //     //                 text: item,
    //     //                 value: item,
    //     //             });
    //     //         }),
    //     //     // filteredValue: loadedValues.trade || null,
    //     //     // filteredValue: ['ROOTS CAST PRIVATE LTD', 'MESSER CUTTING SYSTEMS INDIA PRIVATE LIMITED', 'R K R GOLD PRIVATE LIMITED'] || null,
    //     //     filterIcon: () => filterQuery && filterQuery.trade_name ? < img src={active_filtericon} alt='filter_active_icon' /> : < img src={filterIcon} alt='filter icon' />,
    //     //     filterSearch: true,
    //     //     maxWidth: 120,
    //     //     render(value: any, record: any, index: any) {
    //     //         return (<span style={{ whiteSpace: 'pre-wrap' }} > {value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span >)
    //     //     },
    //     //     width: 10
    //     // },
    //     // {
    //     //     title: 'GSTIN', dataIndex: 'gst', key: 'gst_no',
    //     //     sorter: true,
    //     //     sortDirection: sorterQuery.columnKey === 'gst' ? sorterQuery.order : 'ascend',
    //     //     filters: masterData.gst
    //     //         .filter((item: any) => item !== null && item.trim() !== "")
    //     //         .map((item: any) => {
    //     //             return ({
    //     //                 text: item,
    //     //                 value: item,
    //     //             })
    //     //         }),
    //     //     // filteredValue: loadedValues.gst || null,
    //     //     filterSearch: true,
    //     //     filterIcon: () => filterQuery && filterQuery.gst_no ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
    //     //     render(value: any, record: any, index: any) {
    //     //         return value.gst_no
    //     //         // let link = "/home/" + record.id + "/view-gst-details"
    //     //         // return <Link to={link} style={{ whiteSpace: 'pre-wrap' }}>{value.gst_no}</Link>
    //     //     }
    //     // },
    //     {
    //         title: 'Total Cases',
    //         dataIndex: 'total_cases',
    //         key: 'total_notices',
    //         align: 'center',
    //         sortDirection: sorterQuery.columnKey === 'total_notices' ? sorterQuery.order : 'ascend',
    //         render(value: any, record: any, index: any) {
    //             return <span onClick={() => {
    //                 if (value !== 0) {
    //                     handledrawerfilter(record.gst.gst_no, record.gst.trade_name); setToggledrawer(true); setdrawerquery('')
    //                 }
    //             }} style={{ color: '#318EFF', cursor: 'pointer' }}> {value}</span >
    //         },
    //         // width: 100
    //     },
    //     {
    //         title: 'Action Taken', dataIndex: 'action_taken', key: 'action_taken',
    //         align: 'center',
    //         sortDirection: sorterQuery.columnKey === 'action_taken' ? sorterQuery.order : 'ascend',
    //         filterSearch: true,
    //         render(value: any, record: any, index: any) {
    //             return <span onClick={() => {
    //                 if (value !== 0) { handledrawerfilter(record.gst.gst_no, record.gst.trade_name, 'Action taken') }
    //             }} style={{ color: '#318EFF', cursor: 'pointer' }}>{value}</span>
    //         },
    //         // width: 100
    //     },
    //     {
    //         title: 'Action to be taken', dataIndex: 'action_to_be_taken', key: 'action_to_be_taken',
    //         align: 'center',
    //         sortDirection: sorterQuery.columnKey === 'action_to_be_taken' ? sorterQuery.order : 'ascend',
    //         render(value: any, record: any, index: any) {
    //             return <span onClick={() => { if (value !== 0) { handledrawerfilter(record.gst.gst_no, record.gst.trade_name, 'Action to be taken') } }} style={{ color: '#318EFF', cursor: 'pointer' }}>{value}</span>
    //         },
    //     },
    //     {
    //         title: 'No Action required', dataIndex: 'no_action_required', key: 'no_action_required',
    //         align: 'center',
    //         sortDirection: sorterQuery.columnKey === 'no_action_required' ? sorterQuery.order : 'ascend',
    //         render(value: any, record: any, index: any) {
    //             return <span onClick={() => { if (value !== 0) { handledrawerfilter(record.gst.gst_no, record.gst.trade_name, 'No action required') } }} style={{ color: '#318EFF', cursor: 'pointer' }}>{value}</span>
    //         },
    //     },
    //     {
    //         title: 'Demand amount O/S', dataIndex: 'demand_amount', key: 'demand_amount',
    //         sorter: true,
    //         align: 'center',
    //         sortDirection: sorterQuery.columnKey === 'demand_amount' ? sorterQuery.order : 'ascend',

    //         render(value: any, record: any, index: any) {
    //             return (<span style={{ whiteSpace: 'pre-wrap' }}>{value && value ? '₹ ' + formatter.format(Number(value.toFixed(2))) : "--"}</span>)
    //         },
    //         width: 20
    //     },
    //     // Table.SELECTION_COLUMN,
    // ];
    const columns = [
        {
            title: 'Case Id',
            dataIndex: 'case_arn_no', key: 'case_arn_no',
            maxWidth: 160,
            render(value: any, record: any, index: any) {
                return (
                    // <Badge
                    //     anchorOrigin={{
                    //         vertical: 'top',
                    //         horizontal: 'left',
                    //     }}
                    //     variant="dot"
                    //     id='latst_dot'
                    // >
                    <span onClick={() => {
                        let query: any = {
                            gst_no: [record.gst[0].gst_no]
                        };
                        setSubselectgst(record)
                        setFilterQuery(query);
                        navigate('/home/suboverview/');
                        setBreadcrumbPath(`/home/notices/All notices - ${record.gst[0].gst_no}/subnotices`)
                    }} style={{ whiteSpace: 'pre-wrap', color: '#E96919', cursor: 'pointer', fontWeight: 'bold' }} > {value ? value : '--'}
                    </span >
                    // </Badge>
                )
            },
            width: 160
        },
        {
            title: 'Tax Period',
            dataIndex: 'tax_period', key: 'tax_period',
            maxWidth: 160,
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }} > {value ? value : 'NA in Portal'}</span >)
            },
            width: 160
        },
        {
            title: 'Case Status',
            dataIndex: 'case_status', key: 'case_status',
            maxWidth: 160,
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }} > {value ? value : '--'}</span >)
            },
            width: 160
        },
        {
            title: 'Notice Count',
            dataIndex: 'notice_count', key: 'notice_count',
            maxWidth: 120,
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }} > {value ? value : '--'}</span >)
            },
            width: 120
        },
        {
            title: 'Portal Status',
            dataIndex: 'portal_status', key: 'portal_status',
            maxWidth: 160,
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }} > {value ? value : '--'}</span >)
            },
            width: 160
        },

        {
            title: 'Demand amount O/S', dataIndex: 'demand_amount', key: 'demand_amount',
            sorter: true,
            align: 'center',
            sortDirection: sorterQuery.columnKey === 'demand_amount' ? sorterQuery.order : 'ascend',

            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }}>{value && value ? '₹ ' + formatter.format(Number(value.toFixed(2))) : "--"}</span>)
            },
            width: 20
        },
        Table.SELECTION_COLUMN,
    ];
    // .............

    return (
        <>
            <Modal
                title={"Summary View"}
                onCancel={() => { setSumpopup(false); setSummarydetails({}) }}
                width={'60%'}
                className='summary-view-popup'
                centered
                style={{ color: "#031742" }}
                open={sumpopup}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className='sum-popup' >
                    <div className="drawer-header">
                        <div className='drawer-first-line'>
                            <EastIcon className='drawer-close-btn' onClick={() => { setSumdrawer(false); setSummarydetails({}) }} />
                            <h4 className="summary-heading">
                                {drawersource.length > 0 && drawersource[0].gst.length > 0 ? drawersource[0].gst[0].trade_name : '--'}
                            </h4>
                        </div>
                        <div className='drawer-second-line'>
                            <Input
                                className='drawer_global_search'
                                value={globalSearch}
                                onChange={handleGlobalSearchChange}
                                placeholder='Type here to search...'
                                suffix={<img
                                    src={searchIcon}
                                    style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                    alt='password icon' />}
                            />
                            {/* <span onClick={handleOpenUserMenu} >
                                <FilterListIcon fontSize='medium' className='drawer-filter' />
                            </span> */}
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                            <h4 className='notice-cunt'>
                                Total Notices :&nbsp;
                                <span>
                                    {summarydetails && summarydetails ? summarydetails.length
                                        : '--'}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div className='drawer-content'>
                        {drawerloading ? (
                            <p style={{ textAlign: 'center' }}>Loading....</p>
                        ) : (
                            Array.isArray(currentItems) && currentItems.length > 0 ? (
                                currentItems.map((detail: any) => (
                                    <div className="drawer-card" key={detail.id}>
                                        <div className='card-first-split' >
                                            <VisibilityIcon className='view-icon' onClick={() => { if (detail.notice_count !== 0) { handleviewticket(detail.id); } }} />
                                            <span className='content-group' >
                                                <h4 className='main-line'>{detail.tax_period ? detail.tax_period : 'NA in Portal'}</h4>
                                                <p className='sub-line'>Tax period</p>
                                            </span>
                                            <span className='content-group' >
                                                <h4 className='main-line'>{detail.case_status}</h4>
                                                <p className='sub-line'>Case Status</p>
                                            </span>
                                            <span className='content-group' >
                                                <h4 className='main-line'>{detail.demand_amount ? '₹ ' + formatter.format(Number(detail.demand_amount.toFixed(2))) : '--'}</h4>
                                                <p className='sub-line'>Demand Amount</p>
                                            </span>
                                            <span className='content-group' >
                                                <h4 className='main-line'>{detail.notice_count}</h4>
                                                <p className='sub-line'>Notice Count</p>
                                            </span>
                                        </div>
                                        <div className='card-second-split'>
                                            <div className='sub-grid'>
                                                <p className='header-line'>Portal status</p>
                                                <div className='desc-cover'>
                                                    <p className='desc-sub-line'>{detail.portal_status}</p>
                                                </div>
                                            </div>
                                            <div className='sub-grid'>
                                                <p className='header-line'>Description</p>
                                                <div className='desc-cover'>
                                                    <p className='desc-sub-line'>{detail.case_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>))) : (
                                <p style={{ textAlign: 'center' }}>No Data</p>
                            )
                        )}
                    </div>
                    <div className="drawer-footer">
                        <Pagination
                            current={drawerpage}
                            pageSize={10}
                            total={drawersource.length}
                            onChange={handlePageChange}
                            prevIcon={<span>Previous</span>}
                            nextIcon={<span>Next</span>}
                        />
                    </div>
                </div>
            </Modal >
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
            <div id='case-summary-container' className="case-container">
                <div className='case-table-wrap' style={{ width: '100%' }}>
                    <ZTable2
                        label={'Case Summary'}
                        button={buttondetails}
                        columns={columns}
                        expand={false}
                        ref={gstTableElement}
                        tabledata={noticeData}
                        loading={loading}
                        hideSelect={false}
                        totalpages={totalpages} />
                </div>
                {sumdrawer && <div className='sum-drawer'>
                    <div className="drawer-header">
                        <div className='drawer-first-line'>
                            <EastIcon className='drawer-close-btn' onClick={() => { setSumdrawer(false); setSummarydetails({}) }} />
                            <h4 className="summary-heading">
                                {drawersource.length > 0 && drawersource[0].gst.length > 0 ? drawersource[0].gst[0].trade_name : '--'}
                            </h4>
                        </div>
                        <div className='drawer-second-line'>
                            <Input
                                className='drawer_global_search'
                                value={globalSearch}
                                onChange={handleGlobalSearchChange}
                                placeholder='Type here to search...'
                                suffix={<img
                                    src={searchIcon}
                                    style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                    alt='password icon' />}
                            />
                            {/* <span onClick={handleOpenUserMenu} >
                                <FilterListIcon fontSize='medium' className='drawer-filter' />
                            </span> */}
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                            <h4 className='notice-cunt'>
                                Total Cases :&nbsp;
                                <span>
                                    {summarydetails && summarydetails.length > 0 ? summarydetails.length : '--'}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div className='drawer-content'>
                        {drawerloading ? (
                            <p style={{ textAlign: 'center' }}>Loading....</p>
                        ) : (
                            Array.isArray(currentItems) && currentItems.length > 0 ? (
                                currentItems.map((detail: any) => (
                                    <div className="drawer-card" key={detail.id}>
                                        <div className='card-first-split' >
                                            <VisibilityIcon className='view-icon' onClick={() => { if (detail.notice_count !== 0) handleviewticket(detail.id); }} />
                                            <span className='content-group' >
                                                <h4 className='main-line'>{detail.tax_period ? detail.tax_period : 'NA in Portal'}</h4>
                                                <p className='sub-line'>Tax period</p>
                                            </span>
                                            <span className='content-group' >
                                                <h4 className='main-line'>{detail.case_status}</h4>
                                                <p className='sub-line'>Case Status</p>
                                            </span>
                                            <span className='content-group' >
                                                <h4 className='main-line'>{detail.demand_amount ? '₹ ' + formatter.format(Number(detail.demand_amount.toFixed(2))) : '--'}</h4>
                                                <p className='sub-line'>Demand Amount</p>
                                            </span>
                                            <span className='content-group' >
                                                <h4 className='main-line'>{detail.notice_count}</h4>
                                                <p className='sub-line'>Notice Count</p>
                                            </span>
                                        </div>
                                        <div className='card-second-split'>
                                            <div className='sub-grid'>
                                                <p className='header-line'>Portal status</p>
                                                <div className='desc-cover'>
                                                    <p className='desc-sub-line'>{detail.portal_status}</p>
                                                </div>
                                            </div>
                                            <div className='sub-grid'>
                                                <p className='header-line'>Description</p>
                                                <div className='desc-cover'>
                                                    <p className='desc-sub-line'>{detail.case_description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>))) : (
                                <p style={{ textAlign: 'center' }}>No Data</p>
                            )
                        )}
                    </div>
                    <div className="drawer-footer">
                        <Pagination
                            current={drawerpage}
                            pageSize={10}
                            total={drawersource.length}
                            onChange={handlePageChange}
                            prevIcon={<span>Previous</span>}
                            nextIcon={<span>Next</span>}
                        />
                    </div>
                </div>}
            </div >
        </>
    )
}
export const serviceUrl: any[string] = {
  // Terminal
  register_user: 'user/register/',
  register_verify: 'user/register/verify/',
  check_user: 'user/check/username/',
  check_mail: 'user/check/mail/',
  resend_mail: 'user/resend/mail/',
  set_password: 'user/password/set/',
  forget_password: 'user/forgot/',
  login_user: 'user/auth/',
  profile_user: 'user/',
  checkpin: 'user/checkpin/',
  ph_register: 'user_requests/',
  ph_approval: 'user_requests/:id/',
  login_otp_request: 'user/auth/otp/',
  login_otp_verify: 'user/auth/otp/verify/',
  mail_verify: 'register/verify/mail/',
  check_otp: 'user/check/otp/',
  verify_otp: 'user/check/otp/verify/',

  //manage gst
  manageGst: "gst/",
  get_group_notices: 'gst/group/',
  get_group_cases: 'gst/group_cases/',
  manageGstotp: 'gst/otp/',
  get_sync_counts: 'gst/counts/sync/',
  master_gst: 'gst/gst_list/',
  master_states: 'gst/state_list/',
  master_trades: 'gst/trade_list/',
  master_usernames: 'gst/user_list/',
  gst_sync: 'gst/sync/',
  gst_sync_count_data: 'gst/sync/days_count/',
  gst_sync_details: 'gst/sync/:id/',
  compliance_data: 'gst/sync/compliance_data/',

  // GST DASHBOARD
  get_gst_counts: 'gst/counts/',
  dashboard_ticket_counts: 'notice/get_ticket_dashboard_counts/',
  get_notices: 'notice/',
  get_latest_notices: 'notice/get_latest_notices/',
  get_notices_orders: 'notice/orders_list/',
  get_cases: 'notice/gst_cases/',
  multi_status_update: 'notice/status/update/',
  get_notice_data: 'notice/:id/',
  get_loan_details : 'partnership/get_dashboard/',

  raise_ticket: 'notice/raise_ticket/',
  create_ticket: 'notice/create_ticket/',
  ticket_list: 'notice/ticket_list/',
  open_ticket: 'notice/open_ticket/',
  update_ticket: 'notice/update_ticket/',
  overall_ticket: 'notice/over_all_ticket/',

  download_doc: 'notice/download_doc/',
  get_notices_status_count: 'notice/status/count/',

  // GST loan
  gst_loan_add: 'partnership/add/',
  gst_loan_update: 'partnership/update/:id/',
  gst_loan: 'partnership/',

  // PH Dashboard
  ph_ticket: 'notice/ph_ticket/',
  ph_counts: 'notice/ph_dashboard/',

  // chat Window
  get_conversations: 'notice/task_conversations/',
  task_reply: 'notice/task_reply/',

  // Secondary Filter
  get_case_id: 'notice/caseid_list/',
  get_arn: 'notice/arn_list/',
  get_session: 'notice/session_list/',
  get_notice_type: 'notice/case_type/',

  // Upload file
  upload_file: 'gst/uploads/',
  manual_notice_add: 'notice/manual/add/',

  //get file status
  gst_upload_file_status: 'gst/uploads/',

  // captcha count
  captcha_count: 'user/capcha/balance/',

  // Comments section 
  comment: 'user/feedback/',

  // change password
  changepassword: 'user/change_password/old/',

  // latest updates
  latest_updates: 'latest_updates/'
};
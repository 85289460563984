// react 
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
// ..........

// scss file
import './overview.scss';
// ..........

// package components
import { Form, Input, Modal, Tooltip, Button as Buttons, Spin } from 'antd';
import { Badge, Button } from '@mui/material';
// ..........

// child components
import { NotifyAlert } from '../../components/notify_alert';
import { Utils } from '../../core/utils/utils';
import { Subnotice } from '../../modules/Subnotice';
import { useGlobalState } from '../../Statecontext';
import { AppContext } from '../../core/utils/Context';
// ..........

// icons
import EditIcon from '@mui/icons-material/Edit';
import arrowRightIcon from '../../assets/images/chevron-right.svg';
import { serviceConfig } from '../../core/services';
import { Casessumary } from '../../modules/casesummary';
import { DueDashboard } from '../../modules/dueDashboard';
import { useNavigate } from 'react-router-dom';
import WestIcon from '@mui/icons-material/West';
import searchIcon from '../../assets/images/search.svg';
import plusIcon from '../../assets/images/plus.svg';
import syncWhite from '../../assets/images/sync_now.svg';
import usernameIcon from '../../assets/images/username.svg';
import passwordIcon from '../../assets/images/password.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import deleteConfirmIcon from '../../assets/images/deleteConfirm.svg'
import { Casenotice } from '../../modules/casenotice';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Breadcrumbs from '../Breadcrumb';
// .........

interface Menu {
    name: string;
}

export const Suboverview = () => {

    // formvalue state

    const [form] = Form.useForm();
    const { formValues, handleChange, handleInitial } = useContext(AppContext)
    // ..........

    //  datas states
    const [gstList, setGstList] = useState<any>([])
    const [filterList, setFilterList] = useState<any>([])
    const [noticeData, setNoticeData] = useState<any>([])
    const [noticeObj, setNoticeObj] = useState(false);
    const [localsearch, setLocalsearch] = useState('')
    const { setBreadcrumbPath, rowsPerPage, page, setTotalpages, setNoticeQuery, selectgst, setSelectgst, subselectgst, setSubselectgst, setPage, searchquery, setSearchquery, sorterQuery, filterQuery, setFilterQuery, overviewDetails } = useGlobalState();
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false);
    // ..........

    // limit & filter states
    // ..........

    // reference states
    const abortControllerRef = useRef<AbortController | null>(null);
    const { } = Utils();
    const navigate = useNavigate();
    // ..........

    // search function
    const search = (value: any) => {
        if (gstList[0].notice) {
            setNoticeObj(true);
        }
        const filterTable = gstList.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );

        setFilterList(filterTable);
    };

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;

        if (!value) {
            setSearchquery(value)
            setLocalsearch(value)
            search(value);
        } else {
            setLocalsearch(value)
        }

    }

    const searchclick = (value: any) => {
        search(value);
        setPage(1)
        // setSearchquery(value)
    }
    // .............

    // get call 
    const loadGNotices = useCallback((search_data?: any) => {

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController for the new request
        const controller = new AbortController();
        abortControllerRef.current = controller;
        const signal = controller.signal;

        setLoading(true)
        let query: any = {
        }

        if (filterQuery.gst_no) {
            query['gst_no'] = filterQuery.gst_no.join(',')
        }
        if (overviewDetails.gst_no) {
            query['gst_no'] = overviewDetails.gst_no
        }
        if (filterQuery.state) {
            query['state'] = filterQuery.state.join(',')
        }
        if (filterQuery.trade_name) {
            query['trade_name'] = filterQuery.trade_name.join(',')
        }
        if (filterQuery.user_name) {
            query['user_name'] = filterQuery.user_name.join(',')
        }
        if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
            query['tax_period'] = filterQuery.tax_period.join(',')
        }
        if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
            query['arn_nos'] = filterQuery.arn_nos.join(',')
        }
        if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
            query['case_ids'] = filterQuery.case_ids.join(',')
        }
        if (filterQuery.section && filterQuery.section.length > 0) {
            query['section'] = filterQuery.section.join(',')
        }
        if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
            query['notice_status'] = filterQuery.notice_status.join(',')
        }
        if (filterQuery.due_day && filterQuery.due_day.length > 0) {
            query['days'] = filterQuery.due_day.join(',')
        }
        if (filterQuery.due_from_date && filterQuery.due_to_date) {
            query['notice_start_due_date'] = filterQuery.due_from_date
            query['notice_end_due_date'] = filterQuery.due_to_date
        }
        if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
            query['notice_start_issue_date'] = filterQuery.notice_from_date
            query['notice_end_issue_date'] = filterQuery.notice_to_date
        }
        if (filterQuery.amount_from && filterQuery.amount_oper) {
            query['tax_amount'] = filterQuery.amount_from
            if (filterQuery.amount_oper) {
                query['tax_amount_op'] = filterQuery.amount_oper
            }
        }
        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        query['sort'] = "tax_period,asc"
        setBreadcrumbPath(`/home/notices/All notices/subnotices`)

        setTotalpages(0)

        serviceConfig.get("get_cases", true, query, null, signal).then((data: any) => {
            if (!signal.aborted) {
                setLoading(false)
                setGstList(data);
                setFilterList(data)
                setTotalpages(0)
                setSubselectgst(subselectgst || data[0])
                let query: any = {
                }
                let notice_id: any = subselectgst && subselectgst.id || data[0].id;

                // Ensure notice_id is an array
                if (!Array.isArray(notice_id)) {
                    // If notice_id is not an array, convert it to an array containing only the original value
                    notice_id = [notice_id];
                }

                query['gst_case_id'] = notice_id.join(',')

                query['master_type'] = 'Additional'

                setBreadcrumbPath(`/home/notices/All notices - ${data[0].gst[0].gst_no}/subnotices - ${data[0].case_arn_no}`)

                setNoticeQuery(query)
            }
        }, (err: any) => {
            if (!signal.aborted) {
                console.log(err)
                setLoading(false)
                if (err.message === "Request failed with status code 401") {
                    navigate('/login')
                    localStorage.clear()
                    window.location.reload();
                }
            }
        })
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        loadGNotices()
    }, [])
    // ........

    return (
        <>
            <div id="overview-details-container" className='view-gst-info'>
                <div className='overview-menu-wrap'>
                    <div className='list_wrap'>
                        <div className='search_cover'>
                            <ArrowBackIosNewIcon onClick={() => {
                                navigate(-1);
                                setSubselectgst([])
                                setBreadcrumbPath('home/notices/all notices');
                                setTotalpages(0);
                            }} fontSize='small' className='icon' />
                            <Input
                                className='z-table_global_search'
                                value={localsearch}
                                onChange={handleGlobalSearchChange}
                                placeholder='Type here to search...'
                                suffix={<img
                                    src={searchIcon}
                                    onClick={() => searchclick(localsearch)}
                                    style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                    alt='password icon' />}
                            />
                        </div>
                        <div className='list_item_wrap'>
                            {!loading && filterList ? filterList.length === 0 ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <span style={{ color: '#000', }}>No Data</span>
                                </div> :
                                filterList.map((detail: any, index: any) => (
                                    <div
                                        className={
                                            subselectgst
                                                && subselectgst
                                                && subselectgst.id === detail.id
                                                ? 'list_item mark-border' : 'list_item'
                                        }
                                        onClick={() => {
                                            setSubselectgst(detail);
                                            let query: any = {
                                            }
                                            let notice_id: any = detail.id;

                                            // Ensure notice_id is an array
                                            if (!Array.isArray(notice_id)) {
                                                // If notice_id is not an array, convert it to an array containing only the original value
                                                notice_id = [notice_id];
                                            }

                                            query['gst_case_id'] = notice_id.join(',')

                                            query['master_type'] = 'Additional'
                                            setBreadcrumbPath(`/home/notices/All notices - ${detail.gst[0].gst_no}/subnotices - ${detail.case_arn_no}`)
                                            setNoticeQuery(query)
                                        }}
                                        key={index}>
                                        <Badge badgeContent={detail.notice_count} overlap="circular" className='cnt_badge'>
                                        </Badge>
                                        <div className='head_wrap'>
                                            <span className='gst_value'>{detail.case_arn_no || '_'}</span>
                                        </div>
                                        <div className='content_wrap' style={{ paddingBottom: '0%' }}>
                                            <span className='name_value'>{detail.case_status || '_'}</span>
                                        </div>
                                        <div className='content_wrap' style={{ paddingBottom: '5%' }}>
                                            <span className='name_value'>{detail.tax_period || '_'}</span>
                                        </div>

                                    </div>
                                )) :
                                <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginTop: '15%', }}>
                                </Spin>
                            }
                        </div>
                    </div>
                </div>
                <div className="overview-upper-container" style={{ height: "100%" }}>
                    <div className="overview-notice-main">
                        <div className='overview_menu_wrap'>
                            <Breadcrumbs />
                        </div>
                        <Casenotice gstin={selectgst && selectgst.gst_no} />
                    </div>
                </div>
            </div>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}


// react
// .........

// scss file
import './index.scss';
// .........

// package components
import { useEffect, useRef, useState } from 'react';
// .........

// child components 
import { useGlobalState } from '../../Statecontext';
import { Button, Modal, Spin } from 'antd';
import { serviceConfig } from '../../core/services';
import { NotifyAlert } from '../../components/notify_alert';
// .......

// icons 
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Avatar } from '@mui/material';
// .....

export const Notification = ({ anchorEl, setAnchorEl }: any) => {

    // alert and modal
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    // .....

    // reference states
    const optionsRef = useRef<any>(null);
    const { setIsManagegst, setOverviewDetails, setProgres, progres, masterData, setMasterData, setFilterQuery, setSorterQuery, setSearchquery, syncedCount, setPage, setTotalpages, setTabactive, setManageactive, tabview, setTabview, setSumpopup, setSumdrawer, setRowsPerPage, setreportModal, reportmodal, syncDetails, setChangeview, setSyncDetails, setChartquery } = useGlobalState();
    // .........

    // loading state
    const [syncloading, setSyncloading] = useState(false)
    // .......

    // form value state
    let [syncId, setSyncId] = useState<any>(null)
    // ......

    // modal  function
    const popupFunc = (label: any) => {
        if (label === 'Sync') {
            setreportModal(true)
        }
    }
    // .........

    // logout function

    // .........


    // sync last status function
    const getLastSyncStatus = () => {
        setSyncloading(true)
        serviceConfig.get("gst_sync", true, { 'last': true }, null).then((data: any) => {
            setSyncloading(false)
            if (data.length > 0) {
                setSyncDetails(data[0])
                // if (!data[0].sync_processed && data[0].is_sync_all) {
                if (!data[0].sync_processed) {
                    syncId = data[0].id
                    setSyncId(data[0].id)
                    getStatusofSyncAllProgress()
                }
            }
        }, (err: any) => {
            setSyncloading(false)
            setreportModal(false)
            setNotifyType("error")
            setNotifyMessage('Something Went Wrong!')
            setNotifyOpen(true)
        })
    }
    const getStatusofSyncAllProgress = () => {
        setSyncloading(true)
        setProgres({ data: { loading: true, label: 'Sync' } })

        serviceConfig.get("gst_sync_details", true, null, { id: syncId }).then((data: any) => {
            setSyncDetails(data)
            setSyncloading(false)
            syncId = data.id
            setSyncId(data.id)
            let timerInt = !data.sync_processed ? setTimeout(() => {
                getStatusofSyncAllProgress();
            }, (10 * 1000)) : null;
            if (data.sync_processed) {
                timerInt = null;
                setreportModal(true)
                setProgres({ data: { loading: false, label: '' } })
            }
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage('Something Went Wrong!')
            setNotifyOpen(true)
            setSyncloading(false)
            setreportModal(false)
            setProgres({ data: { loading: false, label: '' } })
        })
    }
    // ........

    const [isFadingOut, setIsFadingOut] = useState(false);


    useEffect(() => {
        function handleClickOutside(event: any) {
            if (optionsRef.current && !optionsRef.current?.contains(event.target)) {
                setIsFadingOut(true);
                setTimeout(() => {
                }, 500);
                setAnchorEl(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsRef]);

    useEffect(() => {
        getLastSyncStatus()
    }, [])

    return (
        <>
            <Modal
                title={"Sync Report"}
                onCancel={() => setreportModal(false)}
                width={'40%'}
                className='sync-report-popup'
                centered
                style={{ color: "#031742" }}
                open={reportmodal}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div className='report-wrap'>
                    {syncDetails ?
                        <>
                            <div className='report-title'>
                                <h3>GSTIN Processed count</h3>
                                <h3>First time sync</h3>
                                <h3>Total notices fetched</h3>
                                <h3>Successfully synced</h3>
                                <h3>Sync failed</h3>
                                <h3>Sync start time</h3>
                                {syncDetails.sync_end && <h3>Sync end time</h3>}
                            </div>
                            <div className='report-cnt'>
                                <h3>: {syncDetails.gst_list.length}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.is_first_time).length}</h3>
                                <h3>: {syncDetails.gst_list.map((obj: any) => obj.notice_count).reduce((accumulator: any, current: any) => accumulator + current, 0)}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && !sd.is_sync_failed).length}</h3>
                                <h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && sd.is_sync_failed).length}</h3>
                                <h3>: {new Date(syncDetails.created_at).toDateString() + ' ' + new Date(syncDetails.created_at).toLocaleTimeString()}</h3>
                                {syncDetails.sync_end && <h3>: {syncDetails.sync_end ? new Date(syncDetails.sync_end).toDateString() + ' ' + new Date(syncDetails.sync_end).toLocaleTimeString() : '--'}</h3>}
                            </div>
                        </> :
                        <Spin spinning={syncloading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute' }}>
                        </Spin>
                    }
                </div>

                <p><b>Note:</b> Incorrect password is not at all considered.</p>
            </Modal>
            {anchorEl && <div className='Notification_cover fade-out' ref={optionsRef}>
                <div className='head_box'>
                    <span className='head_line'>Notifications</span>
                    <div className='sub-header-wrap'>
                        {/* <div className='sync-box' onClick={() => popupFunc(progres.data.label)}>
                            {progres.data.loading &&
                                <Button className="Progres-btn" style={{ marginRight: '10px' }}>
                                    <AutorenewIcon className='Progress-icon' />
                                    <b>{progres.data.label} Progressing</b>
                                </Button>
                            }
                        </div> */}
                        <span className='link-item'
                            onClick={() => { getLastSyncStatus(); popupFunc('Sync'); }}
                        >
                            Last Sync Report
                        </span>
                    </div>
                </div>
                <div className='notify_content_cover'>
                    <span style={{ color: '#000', textAlign: 'center', marginTop:'3%' }}>No notifications !</span>

                    {/* <div className='content_block'>
                        <Avatar className='avatar'>ZC</Avatar>
                        <div className='content-msg-cover'>
                            <span className='content_msg'>You have one login, from another windows device .</span>
                            <span className='content_stamp'>3 weeks ago</span>
                        </div>
                    </div> */}
                </div>
            </div >}
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}
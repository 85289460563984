import { Select, Radio } from 'antd';
import { useState } from 'react';

interface SelectWithRadioGroupProps {
  title: string;
  options: { value: string; label: string }[];
  selectedValue: string;
  onChange: (value: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const SelectWithRadioGroup: React.FC<SelectWithRadioGroupProps> = ({
  title,
  options,
  selectedValue,
  onChange,
  open,
  setOpen,
}) => {
  return (
    <div className="select-container">
      <span className="heading">{title}</span>
      <Select
        className="dropdown-btn"
        defaultValue="Select Action"
        onChange={onChange}
        value={selectedValue}
        open={open}
        onDropdownVisibleChange={(open) => setOpen(open)}
        dropdownRender={(menu) => (
          <div>
            <Radio.Group
              onChange={(e) => onChange(e.target.value)}
              value={selectedValue}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {options.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        )}
        options={options}
      />
    </div>
  );
};

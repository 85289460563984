// react 
import { useCallback, useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './zviewGstInfo.scss';
// ..........

// package components
import { Button, Radio, RadioChangeEvent, Select, Spin } from 'antd';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Document, Page, pdfjs } from 'react-pdf';
// ..........

// child components
import { NotifyAlert } from '../../components/notify_alert';
import { serviceConfig } from '../../core/services';
import { Utils } from '../../core/utils/utils';
import { ChatWindow } from './chat';
import { useGlobalState } from '../../Statecontext'
// ..........

// icons
import attachment_icon from '../../assets/images/attachment_icon.png';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Dialog, InputAdornment, TextField } from '@mui/material';
// ..........

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export const ZviewGstInfo = () => {

    //  datas states
    const formatter = new Intl.NumberFormat('en-IN', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    const [selected, setSelected] = useState('Select Action')
    const [noticeData, setNoticeData] = useState<any>(null)
    const [noticeDatalist, setNoticeDatalist] = useState<any>([])
    const [fileDownloading, setFileDownloading] = useState(false)
    const [taskcollapse, setTaskcollapse] = useState(true)
    const [subtab, setSubtab] = useState('Details')
    const [totalPages, setTotalPages] = useState<number>(0);
    const { noticeQuery, setPage, } = useGlobalState();
    const [numPages, setNumPages] = useState<number | null>(null);
    const [demandamnt, setDemandamnt] = useState<any>('');
    const [unformattedDemandamnt, setUnformattedDemandamnt] = useState<string>('');
    const [amntchange, setAmntchange] = useState<any>(false);
    const [selectchange, setSelectchange] = useState<any>(false);
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [noticeStatus, setNoticeStatus] = useState('Action to be taken');
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    // ..........

    // reference states
    const { ticket, updateNotice } = Utils();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    let location = useLocation();
    const navigate = useNavigate()

    const observer = useRef<IntersectionObserver | null>(null);
    const targetRef = useRef<HTMLDivElement | null>(null);

    const noticedataString = localStorage.getItem('noticedata');
    const Noticedata = noticedataString ? JSON.parse(noticedataString) : null;
    const TKT_ID = Noticedata ? Noticedata.id : null;
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    const [detailloading, setDetailloading] = useState(false)
    const [noticeloading, setNoticeloading] = useState(false)
    // ..........

    // data get function
    const getNoticeData = useCallback((id?: any) => {
        if (id) {
            setDetailloading(true)
            serviceConfig.get("get_notice_data", true, null, { id: id }).then((data: any) => {
                setDetailloading(false)
                setNoticeData(data)
                setUnformattedDemandamnt(data && data.tax_amount ? data.tax_amount : 0)
                setDemandamnt(data && data.tax_amount ? formatter.format(data.tax_amount) : 0);
                setNoticeStatus(data.notice_status)
            }, (err: any) => {
                setDetailloading(false)
                console.log(err)
                if (err.message === "Request failed with status code 401" || "Request failed with status code 404") {
                    navigate('-1')
                    setNotifyOpen(true)
                    setNotifyType('Error')
                    setNotifyMessage('Something Went Wrong!')
                }
            })
            noticeData && noticeData.ticket_created && noticeData.ticket_id && noticeData.ticket_id !== 'None' && setSubtab('Comments')
        }
    }, [])


    const handledrawerfilter = (gst_no?: any, query?: any, is_over_due?: any) => {
        setNoticeloading(true)
        setDetailloading(true)

        let gst = location.pathname.split('/')[2];

        let updatedQuery: any = {
        };
        if (noticeQuery && noticeQuery.gst) {
            updatedQuery['gst'] = gst
            if (noticeQuery && noticeQuery.notice_status) {
                updatedQuery['notice_status'] = [noticeQuery.notice_status]
            }
        }

        if (noticeQuery && noticeQuery.is_over_due) {
            updatedQuery['gst'] = gst
            updatedQuery['is_over_due'] = true
        }
        if (noticeQuery && noticeQuery.gst_case_id) {
            updatedQuery['master_type'] = noticeQuery.master_type
            updatedQuery['gst_case_id'] = noticeQuery.gst_case_id
        }
        if (noticeQuery && noticeQuery.is_due) {
            updatedQuery['is_due'] = true
            updatedQuery['notice_start_due_date'] = noticeQuery.notice_start_due_date
            updatedQuery['notice_end_due_date'] = noticeQuery.notice_end_due_date
        }
        updatedQuery['sort'] = `issue_date,desc`

        serviceConfig.get("get_notices", true, updatedQuery, null).then((data: any) => {
            setNoticeDatalist(data.results || data)
            getNoticeData(data[0] && data[0].id || data.results && data.results[0].id || gst)
            setNoticeloading(false)
        }, (err: any) => {
            console.log(err)
            setNoticeloading(false)
            setDetailloading(false)
            setNotifyOpen(true)
            setNotifyType('Error')
            setNotifyMessage('Something Went Wrong!')
            navigate(-1)
        })
    }

    useEffect(() => {
        // Initialize Intersection Observer
        observer.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !loading && noticeDatalist && noticeDatalist.length < totalPages) {
                    setPage((prevPage: any) => prevPage + 1);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            }
        );

        // Observe the target element
        if (targetRef.current) {
            observer.current.observe(targetRef.current);
        }

        // Cleanup Observer when component unmounts
        return () => {
            if (observer.current && targetRef.current) {
                observer.current.unobserve(targetRef.current);
            }
        };
    }, [loading, noticeDatalist && noticeDatalist.length, totalPages]);

    useEffect(() => {
        handledrawerfilter()
    }, [])


    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
        setIsMobile(mediaQuery.matches);

        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addListener(handleResize);

        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, []);

    // ..........

    // getsolution function
    const createTicket = () => {
        ticket(null, noticeData.id).then((data: any) => {
            setNotifyType("success")
            setNotifyMessage("Support ticket created successfully.")
            setNotifyOpen(true)
            getNoticeData(noticeData.id)
        }).catch((err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response.message)
            setNotifyOpen(true)
        })
    }
    // ..........

    // update solution notice function
    const update = () => {
        updateNotice(noticeData.id, selectchange ? noticeStatus : null, amntchange ? unformattedDemandamnt : null).then((data: any) => {
            setNotifyType("success")
            setNotifyMessage("Notice updated successfully.")
            setNotifyOpen(true)
            setSelectchange(false);
            setAmntchange(false)
        }, (err: any) => {
            setNotifyType("error")
            console.log(err.response)
            setNotifyMessage(err.response.data)
            setNotifyOpen(true)
        })
    }
    // ..........

    // radio selection 
    const handleChange = (value: string) => {
        setSelected(value);
        setDropdownOpen(false);
        setSelectchange(true);
    };
    const onChange = (e: RadioChangeEvent) => {
        setNoticeStatus(e.target.value);
        setSelected(e.target.value);
        setDropdownOpen(false);
        setSelectchange(true);
    };
    //..... 

    const task_details_list = [
        {
            name: 'GSTIN',
            value: noticeData && noticeData.gst && noticeData.gst.gst_no ? noticeData.gst.gst_no : '--'
        },
        {
            name: 'Tax Period',
            value: noticeData && noticeData.tax_period ? noticeData.tax_period : '--'
        },
        {
            name: 'Demand Amount',
            value: noticeData && noticeData.tax_amount ? formatter.format(noticeData.tax_amount) : '--'
        },
        {
            name: 'Type',
            value: noticeData && noticeData.sub_type ? noticeData.sub_type : '--'
        },
        // {
        //     name: 'Description',
        //     value: noticeData && noticeData.case_data ? noticeData.case_data.case_description.join(', ') : '--'
        // },
        {
            name: 'State',
            value: noticeData && noticeData.gst ? noticeData.gst.state : '--'
        },
        {
            name: 'Case ID',
            value: noticeData && noticeData.case_data ? noticeData.case_data.case_arn_no : '--'
        },
        // {
        //     name: 'Due Date',
        //     value: noticeData && noticeData.due_date ? noticeData.due_date : '--'
        // },
        {
            name: 'Section',
            value: noticeData ? noticeData.section : '--'
        },
        {
            name: 'Case Status',
            value: noticeData && noticeData.case_data ? noticeData.case_data.portal_status : '--'
        },
    ]

    const sub_tab_list = [
        {
            name: 'Details',
        }
    ]

    noticeData && noticeData.ticket_created && noticeData.ticket_id && noticeData.ticket_id !== 'None' && sub_tab_list.push(
        {
            name: 'Comments',
        }
    )
    interface Attachment {
        docName: string;
        id: string;
        ct: string;
        s3_link: string;
    }

    interface ReplyField {
        name: string;
        value: string | Attachment[];
    }

    const reply_fields_list: ReplyField[] = [
        {
            name: 'Reply filled',
            value: noticeData && noticeData.reply_details && noticeData.reply_details.reason
        },
        {
            name: 'Reply filled date',
            value: noticeData && noticeData.reply_details && noticeData.reply_details.other_details.update_date
        },
        {
            name: 'Attachments',
            value: noticeData && noticeData.reply_details && noticeData.reply_details.attachments
        },
    ]

    // document viewer
    const openPdfInNewTab = (pdfUrl: any) => {
        const url = `https://docs.google.com/viewerng/viewer?url=${pdfUrl}`;
        window.open(url, '_pdf');
    }
    // .......

    // amount value handle
    const formatNumberWithIndianCommas = (value: string) => {
        // Split the value into integer and fractional parts (if any)
        const parts = value.split(".");
        let integerPart = parts[0];
        const fractionalPart = parts.length > 1 ? "." + parts[1] : "";

        // Add commas for the Indian numbering system
        const lastThreeDigits = integerPart.slice(-3);
        const otherDigits = integerPart.slice(0, -3);

        if (otherDigits !== "") {
            integerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits;
        }

        return integerPart + fractionalPart;
    };

    const handleamntChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        let value = evt.target.value.replace(/,/g, ''); // Remove existing commas
        console.log(value)
        if (!isNaN(Number(value))) {
            setDemandamnt(formatNumberWithIndianCommas(value));
            setUnformattedDemandamnt(value)
            setAmntchange(true);
        }
    };

    return (
        <>
            <div id="gst-details-container" className='view-gst-info'>
                <div className='z-gst-info-wrap'>
                    <div className='info-list-wrap'>
                        {!noticeloading && noticeDatalist ? noticeDatalist.length === 0 ?
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <span style={{ color: '#000', }}>No Data</span>
                            </div> :
                            noticeDatalist.map((detail: any, index: any) => (
                                <div
                                    className={
                                        noticeData
                                            && noticeData
                                            && noticeData.id === detail.id
                                            ? 'info-card mark-border' : 'info-card'
                                    }
                                    key={index}
                                    onClick={() => { getNoticeData(detail.id); setAmntchange(false); setSelectchange(false) }}>
                                    <div className='type_cover'>
                                        <div className='type_wrap'>{detail && detail.notice_type ? detail.sub_type != 'REMINDER' ? (detail.sub_type && (detail.sub_type.includes("Rem1:") || detail.sub_type.includes("Rem2:") || detail.sub_type.includes("Rem3:"))) ? 'REMINDER' : detail.notice_type : detail.sub_type : '--'}</div>
                                        <div className='tax_wrap'>{detail && detail.tax_period ? detail.tax_period : '--'}</div>
                                    </div>
                                    <div className='date-cover'>
                                        <div className='date-cell'>
                                            <span className='date_label'>Due date</span>
                                            <span className='date_value'>{detail && detail.due_date ? detail.due_date : '--'}</span>
                                        </div>
                                        <div className='date-cell'>
                                            <span className='date_label'>Issue date</span>
                                            <span className='date_value'>{detail && detail.issue_date ? detail.issue_date : '--'}</span>
                                        </div>
                                    </div>
                                    <span className='info-title'>{detail && detail.case_data ? detail.case_data.case_description.join(', ') : '--'}</span>
                                </div>
                            )) :
                            <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginLeft: '10%', marginTop: '15%', }}>
                            </Spin>
                        }
                        <div ref={targetRef}></div>
                    </div>
                    <div className='info-details-wrap'>
                        {!detailloading && task_details_list ?
                            <div className='info-details-wrap-cover'>
                                <div className='info-header'>
                                    <h3 className='info-title'>{noticeData && noticeData.gst ? noticeData.gst.trade_name : '--'}</h3>
                                    {noticeQuery && location.pathname.split('/').pop() === 'view-gst-details' &&
                                        <div className='query_wrap'>
                                            {noticeQuery.notice_status || noticeQuery.is_over_due ? (
                                                <>
                                                    {noticeQuery.notice_status}
                                                    {noticeQuery.is_over_due && 'Over Due'}
                                                </>
                                            ) : (
                                                'All Notices'
                                            )}
                                        </div>
                                    }
                                    {noticeQuery && location.pathname.split('/').pop() === 'case-summary-details' &&
                                        <div className='query_wrap'>
                                            {noticeData && noticeData.case_data ? noticeData.case_data.case_arn_no : '--'}
                                        </div>
                                    }
                                </div>
                                <div className='task-info-wrap'>
                                    <div className='task-info-header'>
                                        <ArrowBackIosNewIcon onClick={() => setTaskcollapse(!taskcollapse)} fontSize='small' className='icon' style={{ transform: `rotate(${taskcollapse ? '270deg' : '180deg'})` }} />
                                        <h4 onClick={() => setTaskcollapse(!taskcollapse)} className='task-info-title'>Task Information</h4>
                                        <div className="view-container">
                                            <Select className="dropdown-btn"
                                                defaultValue="Select Action"
                                                onChange={handleChange}
                                                value={noticeStatus}
                                                open={dropdownOpen}
                                                onDropdownVisibleChange={(open) => setDropdownOpen(open)}
                                                dropdownRender={menu => {

                                                    return (
                                                        <div>

                                                            <Radio.Group onChange={onChange} value={noticeStatus} style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <Radio value={'No action required'}>No action required</Radio>
                                                                <Radio value={'Action to be taken'}>Action to be taken</Radio>
                                                                <Radio value={'Action taken'}>Action taken</Radio>

                                                            </Radio.Group>

                                                        </div>
                                                    )
                                                }}
                                                options={[

                                                    { value: 'No action required' },
                                                    { value: 'Action to be taken', },
                                                    { value: 'Action taken' },
                                                ]}
                                            />
                                            <Button className='save-btn' disabled={!amntchange && !selectchange} onClick={update}>SAVE</Button>
                                            {noticeData && !noticeData.ticket_created && <Button className='get-btn' onClick={createTicket}>Get Solution from Zen Tax Clinic</Button>}

                                        </div>
                                    </div>
                                    {taskcollapse &&
                                        <div className='task-info-content-wrap'>
                                            {task_details_list.map((task: any) => (
                                                <div className='task-details-tile' key={task.name}>
                                                    <span className='details-tile-label'
                                                        style={{ fontWeight: task.name === 'Case Status' ? 'bold' : '' }}>
                                                        {task.name}
                                                    </span>

                                                    {task.name === 'Demand Amount' ?

                                                        <TextField
                                                            id="outlined-start-adornment"
                                                            sx={{
                                                                width: '40%',
                                                                '& input[type=number]': {
                                                                    '-moz-appearance': 'textfield',
                                                                    '-webkit-appearance': 'none',
                                                                    'appearance': 'textfield',
                                                                },
                                                                '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
                                                                    '-webkit-appearance': 'none',
                                                                    'margin': 0,
                                                                },
                                                            }}
                                                            value={demandamnt}
                                                            onChange={handleamntChange}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                                            }}
                                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                            autoComplete="off"
                                                        /> :
                                                        <span className='details-tile-value'
                                                            style={{ fontWeight: task.name === 'Case Status' ? 'bold' : '' }}>
                                                            {task.value}
                                                        </span>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                {
                                    <div className='sub-tabs-cover-wrap'>
                                        <div className='sub-tabs-cover-header'>
                                            {
                                                sub_tab_list.map((tab: any) => (
                                                    <span key={tab.name} className={tab.name === subtab ? 'sub-tab mark' : 'sub-tab'} onClick={() => setSubtab(tab.name)}>{tab.name}</span>
                                                ))
                                            }
                                        </div>
                                        <div className='sub-tabs-content'>
                                            {
                                                subtab === 'Comments' &&
                                                <>
                                                    <ChatWindow id={noticeData && noticeData.ticket_id}></ChatWindow>
                                                </>
                                            }
                                            {
                                                subtab === 'Details' &&
                                                <div className='details-tab-wrap'>
                                                    <div className="attachment">
                                                        <span className='sub-tab-title'>Attachments</span>
                                                        {noticeData && noticeData.attachments && noticeData.attachments.length !== 0 &&
                                                            <ul className='attach_ul'>
                                                                {noticeData.attachments.map((doc: any, index: any) =>
                                                                    <li>
                                                                        <div className='list_no'>{(index < 10 ? "0" : "") + (index + 1)}</div>
                                                                        <a className="click-me"
                                                                            onClick={() => {
                                                                                openPdfInNewTab(doc.s3_link);
                                                                            }}
                                                                        >
                                                                            {doc.docName ? doc.docName : "Attachment"}
                                                                        </a>
                                                                    </li>

                                                                )
                                                                }
                                                            </ul>
                                                        }
                                                        {noticeData && noticeData.attachments && noticeData.attachments.length === 0 && <p style={{ color: 'black', textAlign: 'center', fontSize: '2.2vmin', margin: 'auto' }}>No Attachments found!</p>}

                                                        {fileDownloading && <div style={{ position: 'absolute', top: 0, height: '100%', width: '100%', background: '#000', opacity: 0.7, color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span>File download inprogress..</span></div>}
                                                    </div>
                                                    <div className='divider'></div>
                                                    <div className="attachment">
                                                        <span className='sub-tab-title reply-title'>Reply</span>
                                                        <div className='reply_cover'>

                                                            {noticeData && noticeData.reply_details && reply_fields_list.map((task: any) => (
                                                                <div className='task-details-tile' key={task.name}>
                                                                    <span className='details-tile-label'>
                                                                        {task.name}
                                                                    </span>

                                                                    {task.name === 'Attachments' ?
                                                                        ((task.value as Attachment[]).map((attachment, idx) => (
                                                                            <div
                                                                                className='details-attach-value link'
                                                                                key={idx}>
                                                                                <li>
                                                                                    <div className='list_no'>{(idx < 10 ? "0" : "") + (idx + 1)}</div>
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            openPdfInNewTab(attachment.s3_link);
                                                                                        }}
                                                                                    >
                                                                                        {attachment.docName}
                                                                                    </a><br></br>
                                                                                </li>
                                                                            </div>
                                                                        )))
                                                                        : (
                                                                            <span className='details-tile-value'>
                                                                                {task.value as string}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            ))}

                                                            {noticeData && !noticeData.reply_details && <p className='notice-reply-sub'
                                                                style={{ color: 'black', margin: 'auto', width: '100%', textAlign: 'center', fontSize: '2.2vmin' }}>
                                                                No Reply found!
                                                            </p>}

                                                        </div>

                                                        {/* {noticeData && 'reply_details' in noticeData ?
                                                    <ul className='attach_ul' style={{}}>
                                                        <li>Reply filled : <b>{noticeData.reply_details.reason}</b></li>
                                                        <li>Reply filled date : <b>{noticeData.reply_details.other_details.update_date}</b></li>
                                                        <li>Attachments : </li>
                                                        <ol style={{ marginTop: '0px !important' }}>
                                                            {noticeData.reply_details.attachments.map((doc: any) => <li><a className="click-me" href={doc.s3_link} target='_blank'><div className="attach-icon"><img src={attachment_icon} alt='attach' /></div> {doc.docName}</a></li>)}
                                                        </ol>
                                                    </ul> : <p className='notice-reply-sub' style={{ color: 'black', margin: 'auto', width: '100%', textAlign: 'center', fontSize: '2.2vmin' }}>No Reply found!</p>} */}
                                                    </div>
                                                </div>
                                            }
                                            {
                                                subtab === 'Reply' &&
                                                <div className="attachment">
                                                    {'reply_details' in noticeData ?
                                                        <ul style={{}}>
                                                            <li>Reply filled : <b>{noticeData.reply_details.reason}</b></li>
                                                            <li>Reply filled date : <b>{noticeData.reply_details.other_details.update_date}</b></li>
                                                            <li>Attachments : </li>
                                                            <ol style={{ marginTop: '0px !important' }}>
                                                                {noticeData.reply_details.attachments.map((doc: any) =>
                                                                    <li><a
                                                                        // onClick={() => { setDocviewurl(doc.s3_link); setDocopen(true) }}
                                                                        className="click-me" href={doc.s3_link} target='_blank'><div className="attach-icon"><img src={attachment_icon} alt='attach' /></div> {doc.docName}</a></li>)}
                                                            </ol>
                                                        </ul> : <p className='notice-reply-sub' style={{ color: 'black', margin: 'auto', width: '100%', textAlign: 'center', fontSize: '2.2vmin' }}>No Reply found!</p>}
                                                </div>
                                            }
                                        </div>
                                    </div>}
                            </div> :
                            <Spin spinning={true} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute', marginLeft: '40%', marginTop: '15%', }}>
                            </Spin>
                        }
                    </div>
                </div>
                <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
            </div >
        </>

    )
}


// react 
import React from "react";
// ..........

// package components
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
// ..........

export const NotifyAlert = ({ notifyOpen, changeStatus, notifyMessage, notifyType }: any) => {

    // Child alert component
    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    // ..........

    // alert in and out transition effects functions
    function TransitionBottom(props: any) {
        return <Slide {...props} direction="down" />;
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        changeStatus(false);
    };
    // ..........

    return (
        <>
            <Snackbar
                open={notifyOpen}
                autoHideDuration={4000}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                onClose={handleClose}
                TransitionComponent={TransitionBottom}
                message={notifyMessage}
            >
                <Alert
                    onClose={handleClose}
                    severity={notifyType}
                    sx={{ width: "100%" }}
                >
                    {notifyMessage}
                </Alert>
            </Snackbar>
        </>
    )
}
// react 
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './mail-verify.scss';
// ..........

// package components
import { serviceConfig } from "../../../core/services";
import { NotifyAlert } from '../../../components/notify_alert';
// ..........

// child components
import { useGlobalState } from "../../../Statecontext";
// ..........

// icons
import mailSent from '../../../assets/register/mail-sent.png';
import LoopIcon from '@mui/icons-material/Loop';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// .......

export const MailVerify = () => {

    const navigate = useNavigate();

    // data state
    const { setIsManagegst, setVideopopup, setUserdata, masterData, setMasterData, setRowsPerPage, setTotalpages, setPage } = useGlobalState()
    // .........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>();
    const [notifyMessage, setNotifyMessage] = useState('');
    const [link_active, setLink_active] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........
    
    useEffect(() => {
        // Get the current URL
        const url = window.location.href;

        // Check if the URL contains the token parameter
        const tokenParam = url.split('?token=')[1];

        if (tokenParam) {
            // Split the tokenParam to remove any additional query parameters after the token
            const extractedToken = tokenParam.split('&')[0];

            // Set the extracted token in the state
            verifycall(extractedToken);
        }
    }, []);

    const verifycall = (id: any) => {
        let query: any = {
            'id': id
        }
        serviceConfig.get("mail_verify", true, query, null).then((data: any) => {
            setLink_active('success')
            console.log(data)
        }, (err: any) => {
            console.log(err)
            setLink_active('error')
            setNotifyType("error")
            setNotifyMessage('Something Went Wrong !')
            setNotifyOpen(true)
        })
    }

    return (
        <>
            <div className="mail-verify-container">
                {link_active === 'success' ?
                    <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <VerifiedUserIcon color="success" className="success" />
                        </div>
                        <h4 className='terminal-window-mainline heading'>Verification Completed</h4>
                        <p className="terminal-window-subline">Your account has been successfully verified. Thank you for your patience.</p>
                    </> : <>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SafetyCheckIcon className="load_icon" />
                        </div>
                        <h4 className='terminal-window-mainline heading'>Account Verification</h4>
                        <p className="terminal-window-subline">Your account verification is in progress. Please wait.</p>
                    </>
                }
                {(link_active === 'success' || link_active === 'error') && <div className="col-sm-12  link">
                    <Link className="terminal-link" to='/login'>Back to Login</Link>
                </div>}
            </div>

            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}
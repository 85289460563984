// react 
import { useCallback, useContext, useEffect, useState } from 'react';
// ..........

// scss file
import './latestupdates.scss';
// ..........

// package components
import { Button, Form, Modal } from 'antd';
// ..........

// child components
import { AppContext } from '../../core/utils/Context';
import { useGlobalState } from '../../Statecontext'
import { ZTable2 } from '../../components/table2';
import { serviceConfig } from '../../core/services';
// ..........

// icons
import SubjectIcon from '@mui/icons-material/Subject';
import usernameIcon from '../../assets/images/username.svg'
import { NotifyAlert } from '../../components/notify_alert';
import deleteIcon from '../../assets/images/deleteIcon.svg';
import editIcon from '../../assets/images/editIcon.svg'
import deleteConfirmIcon from '../../assets/images/deleteConfirm.svg'
// ..........

export const Latestupdates = () => {

    // loading state
    const [loading, setLoading] = useState(false)
    const [formloading, setFormloading] = useState(false);
    // ..........

    // data states
    const [updatesList, setUpdateslist] = useState<any>([])
    const [sel_update, setSelupdate] = useState<any>('')
    const [selectedupdate, setSelectedupdate] = useState<any>()
    // ..........

    // form values states
    const { formValues, handleChange, handleInitial } = useContext(AppContext)
    const { update_subject, update_desc, update_link } = formValues;
    const { totalpages, setTotalpages, page, rowsPerPage, searchquery } = useGlobalState();
    const [actionType, setActionType] = useState("")
    const [editId, setEditId] = useState('')
    // ..........

    // alerts and modal states
    const [latestmodal, setLatestmodal] = useState(false);
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [deleteact, setDeleteact] = useState(false)
    const [multideleteact, setMultideleteact] = useState(false);
    // ..........

    // reference states
    const [form] = Form.useForm();
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    // ..........

    // get call for details
    const get_latest_updates = useCallback(() => {
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }

        if (searchquery) {
            query['search'] = searchquery;
        }

        setLoading(true)
        serviceConfig.get("latest_updates", true, query, null).then((data: any) => {
            setLoading(false)
            setUpdateslist(data.results)
            setTotalpages(data.count)
        }, (err: any) => {
            setLoading(false)
            setNotifyType("error")
            setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
            setNotifyOpen(true)
        })
    }, [page, rowsPerPage, searchquery])

    useEffect(() => {
        get_latest_updates()
    }, [get_latest_updates])


    // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
            Object.keys({ update_subject, update_desc, update_link }).some(
                (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
            ),
        [formValues, update_subject, update_desc, update_link]
    )
    // .......

    // add update fuction
    const apiToFormFieldMapping: { [key: string]: string } = {
        subject: 'update_subject',
        description: 'update_desc',
        link: 'update_link'
    }
    const handleAction = (type?: string, data?: any) => {
        setActionType(type || 'add')
        if (type === 'Edit') {
            setSelupdate(data.gst_no)
            setEditId(data.id)
            handleInitial();
            // Object.keys(data).forEach((key) => {
            //     if (formValues[key]) {
            //         handleChange({
            //             target: { type: 'text', name: key, value: data[key] },
            //         } as React.ChangeEvent<HTMLInputElement>);
            //     }
            // });
            Object.keys(data).forEach((apiKey) => {
                const formKey = apiToFormFieldMapping[apiKey]; // Get the mapped form field name
                if (formKey && formValues[formKey] !== undefined) {
                    handleChange({
                        target: { type: 'text', name: formKey, value: data[apiKey] },
                    } as React.ChangeEvent<HTMLInputElement>);
                }
            });
        }
        setLatestmodal(true);
    }

    const Addupdates = () => {
        let req_body = {
            'subject': update_subject.value,
            'description': update_desc.value,
            'link': update_link.value,
            'user': userData.id
        }
        setFormloading(true)
        serviceConfig[actionType === "Edit" ? "put" : "post"]('latest_updates', true, req_body, null).then((res: any) => {
            setFormloading(false)
            handleInitial();
            setLatestmodal(false);
            setNotifyType("success")
            setNotifyMessage(`Latest changes ${actionType === "Edit" ? "updated" : "submitted"} successfully !`)
            setNotifyOpen(true)
            get_latest_updates()
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
            setNotifyOpen(true)
            setFormloading(false)
        })
    }
    // .........

    // delete data function
    const handleClickdelete = (id: any) => {
        setSelupdate(id.gst_no)
        setEditId(id.id)
        setDeleteact(true);
    }

    const handlemultipledelete = (data?: any) => {
        const idsArray: string | null = Array.isArray(data) ? data.map((item: any) => item.id).join(',') : null;
        setSelectedupdate(idsArray)
        setMultideleteact(true);
    }

    const handleDelete = (data?: any) => {

        const idToDelete = editId;

        setFormloading(true)
        serviceConfig.delete("latest_updates", true, { id: idToDelete }, false).then((res: any) => {
            setNotifyType("success")
            setNotifyMessage("Latest changes detail deleted successfully.")
            setNotifyOpen(true)
            setFormloading(false)
            setDeleteact(false)
            setMultideleteact(false)
            get_latest_updates()
            setSelectedupdate('')
        }).catch((err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response.data)
            setNotifyOpen(true)
            setFormloading(false)
        })
    }
    // ............

    // button details props
    const buttondetails: any[] = [
        {
            type: 'latest',
            name: 'Add changes',
            theme: 'theme1',
            conditions: [],
            function: handleAction
        },
    ]

    // table columns 
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'serial',
            key: 'serial',
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap', color: '#424B70' }} > {value ? value : '--'}</span >)
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap', color: '#E96919', cursor: record.link ? 'pointer' : 'default' }} onClick={() => { record.link && window.open(record.link, '_blank'); }} > {value ? value : '--'}</span >)
            },
        },
        {
            title: "Action",
            dataIndex: "sync_status",
            render(value: any, record: any, index: any) {
                return (
                    <div style={{ display: "flex", gap: "8%", fontSize: "20px", color: "#00000045", cursor: "default" }} >
                        <img src={editIcon} alt='password icon' style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleAction("Edit", record)
                                console.log(record)
                            }} /> |
                        <img src={deleteIcon} alt='password icon' style={{ cursor: "pointer" }} onClick={() => handleClickdelete(record)} />
                    </div>
                )
            }
        },
    ];

    return (
        <>
            <Modal
                title={`${actionType === 'add' ? 'Add' : 'Edit'} Latest Changes`}
                onCancel={() => { setLatestmodal(false); handleInitial() }}
                width={'27%'}
                className='action-popup status-chng-popup'
                centered
                style={{ color: "#031742" }}
                open={latestmodal}
                onOk={Addupdates}
                okText={`${actionType === 'add' ? 'Submit' : 'Update'}`}
                okButtonProps={{ disabled: isError() || formloading }}
            >
                <div style={{ height: '45vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="col-sm-12 input-group" style={{ marginTop: '0rem' }}>
                        <label className="label">Update subject</label>
                        <div className="input-box" >
                            <img className="icons" src={usernameIcon} alt='usernameIcon'></img>
                            <input
                                autoComplete='off'
                                type="text"
                                name='update_subject'
                                value={update_subject.value}
                                onBlur={handleChange}
                                onChange={handleChange}
                                placeholder="Enter update subject"></input>
                            {update_subject.error && <p className="gst-err-msg" >{update_subject.error}</p>}
                        </div>
                    </div>
                    <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                        <label className="label">Update description</label>
                        <div className="input-box" >
                            <img className="icons" src={usernameIcon} alt='usernameIcon'></img>
                            <input
                                autoComplete='off'
                                type="text"
                                name='update_desc'
                                value={update_desc.value}
                                onBlur={handleChange}
                                onChange={handleChange}
                                placeholder="Enter update description"></input>
                            {update_desc.error && <p className="gst-err-msg" >{update_desc.error}</p>}
                        </div>
                    </div>
                    <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                        <label className="label">Update link</label>
                        <div className="input-box" >
                            <img className="icons" src={usernameIcon} alt='usernameIcon'></img>
                            <input
                                autoComplete='off'
                                type="text"
                                name='update_link'
                                value={update_link.value}
                                onBlur={handleChange}
                                onChange={handleChange}
                                placeholder="Enter update link"></input>
                            {update_link.error && <p className="gst-err-msg" >{update_link.error}</p>}
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                onCancel={() => setDeleteact(false)}
                centered
                closeIcon={false}
                className='delete-gst'
                style={{ textAlign: "center", color: "#031742", top: '-14%' }}
                open={deleteact}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                    <img src={deleteConfirmIcon} alt='action' />
                    <h2 className="del-header">Are you sure want to delete this update</h2>
                    <Button htmlType="submit" style={{ background: "none", color: "#ed7d31", border: "0.5px solid #ed7d31", width: "30%", height: "5vh", fontFamily: 'Inter' }} onClick={() => {
                        setDeleteact(false)
                    }}>CANCEL</Button>
                    <Button htmlType="submit" onClick={handleDelete} loading={formloading} style={{ background: "#424B70", color: "white", border: "none", width: "30%", height: "5vh", marginLeft: "10%", fontFamily: 'Inter' }}  >DELETE</Button>
                </Form>
            </Modal>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
            <ZTable2 label={'Latest Changes'} button={buttondetails} columns={columns} expand={false} hideSelect={true} tabledata={updatesList} loading={loading} totalpages={totalpages} />
        </>
    )
}
// react 
import React, { useCallback, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './otp-verify.scss';
// ..........

// package components
import { serviceConfig } from "../../../core/services";
import { NotifyAlert } from '../../../components/notify_alert';
// ..........

// child components
import mailSent from '../../../assets/register/mail-sent.png';
import { createTheme, FormHelperText, OutlinedInput, ThemeProvider } from "@mui/material";
import { useGlobalState } from "../../../Statecontext";
// ..........

export const Otpverify = () => {

    // form values states
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const [err_otp, setErr_otp] = useState('');
    const Userid = localStorage.getItem('user_id');
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>();
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // reference state
    const navigate = useNavigate()
    const { setIsManagegst, setVideopopup, setUserdata, masterData, setMasterData, setRowsPerPage, setTotalpages, setPage } = useGlobalState()
    // ..........

    // otp field
    const handleChange = (value: string, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);


        // Move focus to the next input if a digit is entered
        if (value && index < otp.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            nextInput?.focus();
        }

        setErr_otp('')
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            prevInput?.focus();
        }
    };

    const validateOtp = (): boolean => {
        // Check if any of the OTP fields are empty
        return otp.every((digit) => digit !== '');
    };
    // ..........


    // master Data for filter
    const loadMasterData = useCallback(() => {
        const fetchData = async () => {
            try {
                const gstData = await serviceConfig.get("master_gst", true, null, null);
                const statesData = await serviceConfig.get("master_states", true, null, null);
                const tradesData = await serviceConfig.get("master_trades", true, null, null);
                const caseIdData = await serviceConfig.get("get_case_id", true, null, null);
                const arnData = await serviceConfig.get("get_arn", true, null, null);
                const sessionData = await serviceConfig.get("get_session", true, null, null);
                const caseTypeData = await serviceConfig.get("get_notice_type", true, null, null);
                const usernamesData = await serviceConfig.get("master_usernames", true, null, null);

                setMasterData({
                    gst: gstData.data,
                    states: statesData.data,
                    trades: tradesData.data,
                    caseIdFilter: caseIdData.data,
                    arnFilter: arnData.data,
                    sessionFilter: sessionData.data,
                    caseType: caseTypeData.data.map((item: any) => item.toUpperCase()),
                    userNames: usernamesData.data
                });
                localStorage.setItem('masterData', JSON.stringify({
                    gst: gstData.data,
                    states: statesData.data,
                    trades: tradesData.data,
                    caseIdFilter: caseIdData.data,
                    arnFilter: arnData.data,
                    sessionFilter: sessionData.data,
                    caseType: caseTypeData.data.map((item: any) => item.toUpperCase()),
                    userNames: usernamesData.data
                }));

            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [])
    // ..........


    // otp verification function
    const verification = () => {
        if (!loading) {
            if (!validateOtp()) {
                if (!validateOtp()) {
                    setErr_otp('*Please enter all 4 digits of the OTP');
                    return;
                }
            } else {
                setLoading(true)

                let req_body = {
                    'user_id': Userid,
                    'otp': otp.join('')
                }
                serviceConfig.post('register_verify', true, req_body, null).then((res: any) => {
                    console.log(res, 'cheking')
                    navigate("/mail-success");
                    setPage(1);
                    setTotalpages(0);
                    setRowsPerPage(10);
                    localStorage.setItem('ph_login', JSON.stringify(false))
                    localStorage.setItem('authKey', res.data.auth_key)
                    setUserdata(res.data.user_data)
                    localStorage.setItem('userData', JSON.stringify(res.data.user_data))
                    localStorage.setItem('is_cf', JSON.stringify(res.data.user_data.is_cf))
                    localStorage.setItem('cf_code', JSON.stringify(res.data.user_data.cf_code))
                    localStorage.setItem('userRole', res.data.user_data.role.role_name)
                    localStorage.setItem('syncedCount', JSON.stringify(res.data.user_data.sync_count))
                    localStorage.setItem('ph_request', JSON.stringify(res.data.user_data.ph_request))
                    setIsManagegst(true)
                    setVideopopup(true)
                    loadMasterData()
                    setLoading(false)
                },
                    (err: any) => {
                        console.log(err, 'cheking')
                        setNotifyType("error")
                        setNotifyMessage(err && err.response && err.response.data.error || 'Something Went Wrong !')
                        setNotifyOpen(true)
                        setLoading(false)
                    }
                )
            }
        }
    }
    // ..........

    // custom theming function
    const inputtheme = createTheme({
        palette: {
            primary: {
                main: '#9a9999',
            }
        },
    });
    // ..........
    return (
        <>
            <div className="otp-verify-container">
                <div>
                    <img className="icons" src={mailSent} alt='mail_icon'></img>
                </div>
                <h4 className='terminal-window-mainline heading'>OTP verification</h4>
                <p className="terminal-window-subline">Otp sended to your registered Mobile number for verfication and accessing the Portal.</p>
                <div className="col-sm-12 otp-group" style={{ paddingBottom: err_otp ? '0%' : '' }}>
                    <div className='otp-cover'>
                        {otp.map((digit, index) => (
                            <ThemeProvider theme={inputtheme}>
                                <OutlinedInput
                                    className='otp_input'
                                    key={index}
                                    id={`otp-input-${index}`}
                                    autoComplete='off'
                                    value={digit}
                                    onChange={(e: any) => handleChange(e.target.value, index)}
                                    onKeyDown={(e: any) => handleKeyDown(e, index)}
                                    inputProps={{
                                        maxLength: 1,
                                        style: { textAlign: 'center' }
                                    }}
                                />
                            </ThemeProvider>
                        ))}

                    </div>
                    <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_otp}</FormHelperText>
                </div>
                <div className="col-sm-12  forgot-link">
                    <Link className="terminal-link" to='/register'>Back to Register</Link>
                </div>
                <div className="col-sm-12 input-group">
                    <button className="register teminal-primary-btn" onClick={verification}>{loading ? 'Loading...' : 'Submit'}</button>
                </div>
            </div>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}
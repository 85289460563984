// react 
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// .........

// scss file
import './users.scss'
// .........

// package components
import { FormHelperText, OutlinedInput, ThemeProvider, createTheme } from '@mui/material'
import Switch from '@mui/material/Switch'
import { pink } from '@mui/material/colors'
import { alpha, styled } from '@mui/material/styles'
import { Button, Form, Modal, Table } from 'antd'
import moment from 'moment'
// .........

// child components
import { NotifyAlert } from '../../components/notify_alert'
import { serviceConfig } from '../../core/services'
// .........

// icons
import { useGlobalState } from '../../Statecontext'
import filterIcon from '../../assets/images/filter.svg'
import passwordDotIcon from '../../assets/images/passwordDot.svg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import mailIcon from "../../assets/register/mail.png"
import phoneIcon from "../../assets/register/phone.png"
import userIcon from "../../assets/register/user.png"
import { ZTable2 } from '../../components/table2'
import Breadcrumbs from '../../shared/Breadcrumb'
// .........

export const Users = () => {

	// form values states
	const [form] = Form.useForm();
	const [userGSTMaster, setUserGSTMaster] = useState<any>([])
	const [userTradeMaster, setUserTradeMaster] = useState<any>([])
	const [userStateMaster, setUserStateMaster] = useState<any>([])
	const [selectedname, setSelectedname] = useState('')
	const [selectedid, setSelectedid] = useState('')
	const [selectedstatus, setSelectedstatus] = useState(false)
	const [user_name, setUsername] = useState('')
	const [user_email, setUserEmail] = useState('')
	const [user_mobile, setUserMobile] = useState('')
	const [err_user_name, setErrUserName] = useState('')
	const [err_user_mobile, setErrUserMobile] = useState('')
	const [err_user_email, setErruserEmail] = useState('')
	const [detailSource, setDetailsource] = useState<any>([])
	const [userData, setUserData] = useState<any>()
	// .........

	// alerts and modal states
	const [adminmodalOpen, setAdminModalOpen] = useState(false);
	const [syncmonitoring, setSyncmonitoring] = useState(false);
	const [notifyOpen, setNotifyOpen] = useState(false)
	const [notifyType, setNotifyType] = useState<any>()
	const [notifyMessage, setNotifyMessage] = useState('')
	// .........

	// loading state
	const [loading, setLoading] = useState(false)
	// .........

	// limit & filter states
	const { filterQuery, searchquery, page, totalpages, setTotalpages, rowsPerPage, userdata, setUserdata, sorterQuery } = useGlobalState();
	const [usersList, setUsersList] = useState<any>([])
	const [switchmodal, setSwitchmodal] = useState(false)
	// .........

	// reference states
	const navigate = useNavigate()
	// .........

	// custom theming function
	const inputtheme = createTheme({
		palette: {
			primary: {
				main: '#9a9999',
			}
		},
	});

	const GreenSwitch = styled(Switch)(({ theme }) => ({
		'& .MuiSwitch-switchBase.Mui-checked': {
			color: '#50B865',
			'&:hover': {
				backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
			},
		},
		'& .MuiSwitch-switchBase + .MuiSwitch-track': {
			backgroundColor: '#B0B4AF',
		},
		'&.MuiSwitch-track': {
			backgroundColor: '#B0B4AF',
		},
		'& .MuiSwitch-switchBase': {
			color: '#B0B4AF'
		},
		'& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
			backgroundColor: '#B0B4AF',
		}
	}));

	const label = { inputProps: { 'aria-label': 'Color switch demo' } };
	// .........

	// data get function
	const loadUser = useCallback((search_data?: any) => {

		setLoading(true)
		let query: any = {
			by_page: true,
			page: page,
			page_size: rowsPerPage
		}
		if (sorterQuery && sorterQuery.columnKey) {
			query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
		}
		if (searchquery) {
			query['search'] = searchquery;
		}

		let cf_code: any = localStorage.getItem('cf_code')

		if (localStorage.getItem('is_cf') === 'true') {
			const parsed_cf_code: string = JSON.parse(cf_code);
			query['cf_code'] = parsed_cf_code
		}

		serviceConfig.get("profile_user", true, query, null).then((data: any) => {
			setTotalpages(data.count)
			setUsersList(data.results || data.data)
			setLoading(false)
		}, (err: any) => {
			console.log(err)
			setLoading(false)
			if (err.message === "Request failed with status code 401") {
				navigate('/login')
				localStorage.clear()
				window.location.reload();
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, filterQuery, userData, searchquery])

	useEffect(() => {
		loadUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterQuery, sorterQuery, searchquery])

	useEffect(() => {
		setUserdata(JSON.parse(localStorage.getItem('userData') || ''));
		setUserData(JSON.parse(localStorage.getItem('userData') || ''));
	}, [])

	// .........

	// form value validation function
	const validateMobileNumber = (number: any) => {
		const pattern = /^[0-9]{10}$/;
		return pattern.test(number);
	};

	const validateEmail = (email: any) => {
		const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return pattern.test(email);
	}

	const handleUsernameChange = (e: any) => {
		const newval = e.target.value;
		setUsername(newval);
		if (newval === '') {
			setErrUserName("*Please enter User name")
		} else {
			setErrUserName("")
		}
	}

	const handleMobileChange = (e: any) => {
		const newval = e.target.value;
		setUserMobile(newval);
		if (newval !== '') {
			setErrUserMobile(validateMobileNumber(newval) ? '' : '*Invalid Mobile number');
		} else {
			setErrUserMobile("*Please enter mobile number")
		}
	}

	const handleEmailChange = (e: any) => {
		const newval = e.target.value;
		setUserEmail(newval);
		if (newval !== '') {
			setErruserEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
		} else {
			setErruserEmail("*Please enter mail id")
		}
	}

	const closeadminModal = () => {
		setAdminModalOpen(false)
		setErruserEmail('')
		setErrUserName('')
		setErrUserMobile('')
		setUsername('')
		setUserEmail('')
		setUserMobile('')
	}
	// ........................

	// Add user function.............
	const registerUser = (e?: any) => {
		// e.preventDefault()
		if (!loading) {
			if (!user_name || !user_email || !user_mobile) {

				!user_name && setErrUserName("*User name required");
				!user_mobile && setErrUserMobile("*Mobile number required");
				!user_email && setErruserEmail("*E-Mail id required")

			} else if (!err_user_email && !err_user_mobile && !err_user_name) {
				setLoading(true)
				let req_body = {
					"user_name": user_name,
					"email": user_email,
					"contact_number": user_mobile,
				}

				serviceConfig.post("register_user", true, req_body, null).then((data: any) => {
					localStorage.setItem('userId', data.data.user_id)
					setUsername('')
					setUserEmail('')
					setUserMobile('')
					setAdminModalOpen(false)
					setLoading(false)
					setErruserEmail('')
					setErrUserName('')
					setErrUserMobile('')
					loadUser()
				}, (err: any) => {
					setLoading(false)
					console.log(err)
					setNotifyType("error");
					setNotifyMessage("Mail already exists!");
					setNotifyOpen(true);
				})
			}
		}
	}
	// ........................

	// reset/forget password function
	const userresetpassword = (email: any) => {
		setLoading(true)
		serviceConfig.post('forget_password', true, { email: email }, null).then((data: any) => {
			setLoading(false)
			setNotifyType("success");
			setNotifyMessage("Password Reset mail has been sent successufully.");
			setNotifyOpen(true);
		},
			(err: any) => {
				console.log("res err", err.response);
				setNotifyType("error");
				setNotifyMessage(err.message);
				setNotifyOpen(true);
				setLoading(false)
			})
	}
	// .........

	// status change function
	const changeuserstatus = () => {
		setLoading(true)
		let body: any = {
			id: selectedid,
			user_status: !selectedstatus
		}

		serviceConfig.put('profile_user', true, body, null).then((res: any) => {
			console.log("response", res);
			// localStorage.setItem('userData', JSON.stringify(res))
			// setUserdata(res)
			setSwitchmodal(false)
			setLoading(false)
			loadUser()
		},
			(err: any) => {
				setNotifyType("error")
				setNotifyMessage(err)
				setNotifyOpen(true)
				setLoading(false)
			}
		)
	}

	const handleswitchchange = (id: any, name: string, status: boolean) => {
		setSwitchmodal(true)
		setSelectedid(id);
		setSelectedname(name)
		setSelectedstatus(status)
	}
	// .........

	// syncmonitoriing
	const syncmonitoriing = () => {
		setSyncmonitoring(true)
		setDetailsource([])
	}

	const closesyncmonitoriing = () => {
		setSyncmonitoring(false)
	}
	// .........

	// button details props
	const buttondetails: any = [
		{
			type: 'download',
			name: 'Download',
			theme: 'theme4',
			conditions: ['if_list'],
			downloadquery: 'user_list'
		}
	]

	if (userData && userData.is_cf === false) {
		buttondetails.push(
			{
				type: 'add',
				name: 'Add New User',
				theme: 'theme1',
				conditions: [],
				function: () => setAdminModalOpen(true)
			}
		);
	}
	// .........

	// table columns and filter requirement props
	const columns: any = [
		// {
		// 	title: 'S No.',
		// 	dataIndex: '',
		// 	render(value: any, record: any, index: any) {
		// 		return (
		// 			<span>{index + 1}</span>
		// 		)
		// 	}
		// },
		{
			title: 'User Name',
			dataIndex: 'user_name',
			// filters: userGSTMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},
		{
			title: 'E-mail ID',
			dataIndex: 'email',
			// filters: userStateMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},
		{
			title: 'Mob.Number',
			dataIndex: 'contact_number',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},
		{
			title: "Passsword",
			dataIndex: "password",
			render(value: any, record: any, index: any) {
				return (
					<>
						<img src={passwordDotIcon} alt='password icon' />
					</>
				)
			}
		},
		{
			title: "Reset password",
			// dataIndex:"last_sync",
			dataIndex: 'password',
			render(value: any, record: any, index: any) {
				return (
					<>
						<p onClick={() => userresetpassword(record.email)} style={{ color: '#ED7D31', fontSize: '2vmin', padding: '2% 4%', 'border': '1px solid #ED7D31', borderRadius: '8px', width: '97%', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>Reset password</p>
					</>
				)
			},
		},
		{
			title: 'Created at',
			dataIndex: 'created_at',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			render(value: any, record: any, index: any) {
				const formattedDate = moment(value).format('DD-MM-YYYY , HH:mm');
				return value ? formattedDate : '--'
			},
		},
		{
			title: 'Last login',
			dataIndex: 'last_login',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			render(value: any, record: any, index: any) {
				const formattedDate = moment(value).format('DD-MM-YYYY , HH:mm');
				return value ? formattedDate : '--'
			},
		},
		{
			title: "Enable/Disable",
			dataIndex: 'user_status',
			render(value: any, record: any, index: any) {
				return (
					// <p>{value}</p>
					<span style={{ marginLeft: '18%' }}>
						<GreenSwitch {...label} checked={record.user_status} onClick={() => handleswitchchange(record.id, record.user_name, record.user_status)} defaultChecked />
					</span>
				)
			},
		},
		{
			title: 'GstIn Managed',
			dataIndex: 'user_counts',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			// filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value.total_gst : '--'
			},
		},
		{
			title: 'Incorrect Password',
			dataIndex: 'user_counts',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			// filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value.wrong_gst : '--'
			},
		},
		{
			title: 'Total Notice',
			dataIndex: 'user_counts',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			// filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value.total_notices : '--'
			},
		},
		{
			title: 'No action required',
			dataIndex: 'user_counts',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			// filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value.no_action_required : '--'
			},
		},
		{
			title: 'Action to be taken',
			dataIndex: 'user_counts',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			// filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value.action_to_be_taken : '--'
			},
		},
		{
			title: 'Action taken',
			dataIndex: 'user_counts',
			// filters: userTradeMaster.map((item: any) => {
			// 	return {
			// 		text: item,
			// 		value: item,
			// 	};
			// }),
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			// filterSearch: true,
			render(value: any, record: any, index: any) {
				return value ? value.action_taken : '--'
			},
		},
		{
			title: 'Last sync',
			dataIndex: 'last_sync',
			// sorter: true,
			// sortDirection: sorterQuery.columnKey === 'last_sync' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				function join(date: any, options: any, separator: any) {
					function format(option: any) {
						let formatter = new Intl.DateTimeFormat('en', option);
						return formatter.format(date);
					}
					let joined = options.map(format).join(separator);
					return joined + ', ' + new Date(value).toLocaleTimeString();
				}

				let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
				return value ? join(new Date(value), options, ' ') : '--';
			},
		},
		{
			title: 'Sync count',
			dataIndex: 'sync_count',
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},

	];

	if (userData && userData.is_cf === false) {
		columns.push(
			{
				title: "Captcha used count",
				dataIndex: "captcha_count",
				render(value: any, record: any, index: any) {
					return value ? value : "--";
				},
			},
			{
				title: "Sync Monitoring",
				dataIndex: "captcha_count",
				render(value: any, record: any, index: any) {
					return (
						record.last_sync ?
							< RemoveRedEyeIcon fontSize='medium' className='syncview-icon' onClick={() => syncmonitoriing()} /> : '--'

					)
				},
			}
		);
	}


	const detailcolumns = [
		{
			title: 'S.No',
			dataIndex: 'serial',
			key: 'serial',
			render: (text: any, record: any, index: number) => index + 1,
		},
		{
			title: 'User',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Total No.of GSTIN Synced',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: '1st time GSTIN Sync',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Sync Start Time',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Sync End Time',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Total Sync Time',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Avg. Sync Time per GSTIN',
			dataIndex: 'user_name',
			key: 'user_name',
		},

	];
	// .........

	return (
		<div className="manage-gst-container">
			<div style={{ marginTop: '1%' }}>
				<Breadcrumbs />
			</div>
			<ZTable2
				label={'Users List'}
				button={buttondetails}
				columns={columns}
				expand={false}
				hideSelect={true}
				loading={loading}
				tabledata={usersList}
				totalpages={totalpages} />

			<Modal
				onCancel={() => setSwitchmodal(false)}
				width={'25%'}
				centered
				closeIcon={false}
				className='delete-gst'
				style={{ textAlign: "center", color: "#031742", top: '-14%' }}
				open={switchmodal}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<h2 className="del-header">Are you sure you want to {!selectedstatus ? 'Active' : 'Deactive'} this user {selectedname}</h2>
					<span style={{ textAlign: "right", display: "none" }}>This field is required</span>
					<Button htmlType="submit" style={{ background: "none", color: "#ed7d31", border: "0.5px solid #ed7d31", width: "30%", height: "6vh", fontFamily: 'Inter' }} onClick={() => {
						setSwitchmodal(false)
					}}>No</Button>
					<Button htmlType="submit" onClick={changeuserstatus} loading={loading} style={{ background: "#424B70", color: "white", border: "none", width: "30%", height: "6vh", marginLeft: "10%", fontFamily: 'Inter' }}  >Yes</Button>
				</Form>
			</Modal>
			<Modal
				title={'ADD USER'}
				onCancel={closeadminModal}
				width={'30%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={adminmodalOpen}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div id="admin-adduser-container">
					<div className="fields">
						<div className="row">
							<div className="col-sm-12 input-group ">
								<label className="input-label">User name</label>
								<ThemeProvider theme={inputtheme}>
									<OutlinedInput
										className='inputfield'
										id="outlined-adornment-weight"
										placeholder='Enter your user name'
										value={user_name}
										error={!!err_user_name}
										onChange={handleUsernameChange}
										startAdornment={<img className="input-icon" src={userIcon} alt="user_icon" />}
										aria-describedby="outlined-weight-helper-text"
									/>
									<FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} id='error-line'>{err_user_name}</FormHelperText>
								</ThemeProvider>
							</div>

							<div className={err_user_name ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_user_name ? '0' : '' }}>
								<label className="input-label">Email</label>
								<ThemeProvider theme={inputtheme}>
									<OutlinedInput
										className='inputfield'
										id="outlined-adornment-weight"
										placeholder='Enter your registered mail id'
										value={user_email}
										error={!!err_user_email}
										onChange={handleEmailChange}
										startAdornment={<img className="input-icon" src={mailIcon} alt="email_icon" />}
										aria-describedby="outlined-weight-helper-text"
									/>
									<FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right', position: 'relative' }} id='error-line'>{err_user_email}</FormHelperText>
								</ThemeProvider>
							</div>

							<div className={err_user_email ? "col-sm-12 input-group error-margin" : "col-sm-12 input-group"} style={{ marginTop: err_user_email ? '0' : '' }}>
								<label className="input-label">Mobile Number</label>
								<ThemeProvider theme={inputtheme}>
									<OutlinedInput
										className='inputfield'
										id="outlined-adornment-weight"
										placeholder='Enter your mobile number'
										value={user_mobile}
										error={!!err_user_mobile}
										onChange={handleMobileChange}
										startAdornment={<img className="input-icon" src={phoneIcon} alt="mobile_icon" />}
										aria-describedby="outlined-weight-helper-text"
									/>
									<FormHelperText style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right', position: 'relative' }} id='error-line'>{err_user_mobile}</FormHelperText>
								</ThemeProvider>
							</div>

							<div className="col-sm-12 input-group register_btn">
								<button className="teminal-primary-btn register" onClick={registerUser}>{!loading ? 'Add user' : 'Loading...'}</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				title={'Sync Details'}
				onCancel={closesyncmonitoriing}
				width={'70%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={syncmonitoring}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div id="admin-syncmonitor-container">
					<Table
						style={{ minWidth: '35vw', marginTop: '3%' }}
						columns={detailcolumns}
						dataSource={detailSource.length > 0 ? detailSource[0]?.gst_list.filter((it: any) => it.is_error) : []}
					// Other props for Table component
					/>
				</div>
			</Modal>
			<NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
		</div>
	)
}
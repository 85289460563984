// react 
import { Outlet } from "react-router-dom"
// ..........

// scss file
import './home.scss';
// ..........

// child components
import Header from "../../shared/Header"
// ..........

export const Home = () => {
    return (
        <div id='home-wrap'>
            <Header />
            <div className="outlet-cover-wrap">
                <Outlet />
            </div>
        </div>
    )
}
import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalState } from '../../Statecontext';
import './breadcrumb.scss';

const Breadcrumbs: React.FC = () => {
    const { breadcrumbPath } = useGlobalState();

    // Split the breadcrumb path into segments and generate breadcrumb links
    const breadcrumbSegments = breadcrumbPath.split('/').filter((segment: any) => segment);

    return (
        <nav className="breadcrumb">
            <ul>
                {breadcrumbSegments.map((segment: any, index: any) => {
                    const path = `/${breadcrumbSegments.slice(0, index + 1).join('/')}`;
                    return (
                        <li key={path}>
                            {index < breadcrumbSegments.length - 1 ? (
                                <span>{segment}</span>
                            ) : (
                                <span className='last'>{segment}</span>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Breadcrumbs;

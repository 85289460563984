// react 
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './header.scss';
// ..........

// package components
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Avatar, Badge, Box, Dialog, DialogContent, DialogTitle, Divider, Drawer, FormHelperText, List, ListItem, ListItemButton, ListItemIcon, ListItemText, OutlinedInput, TextField } from '@mui/material';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { DatePicker as AntDatePicker, Button, Modal, Spin, Tooltip, Menu, Form, Input } from 'antd';
import copy from 'clipboard-copy';
import TextArea from 'antd/es/input/TextArea'
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import dayjs, { Dayjs } from 'dayjs'
import IconButton from '@mui/material/IconButton';
// ..........

// child components
import { useGlobalState } from '../../Statecontext';
import { NotifyAlert } from '../../components/notify_alert';
import { serviceConfig } from '../../core/services';
import { Utils } from '../../core/utils/utils';
import { UserLogout } from '../logout';
import { UserProfile } from '../profile';
import { Notification } from '../Notification';
import { AppContext } from '../../core/utils/Context';
// ..........

// icons & logo
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp';
import NotificationsSharpIcon from '@mui/icons-material/NotificationsSharp';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MenuIcon from '@mui/icons-material/Menu';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import casesummaryicon from '../../assets/GST-dash/Casesummary.png';
import casesummaryiconWht from '../../assets/GST-dash/Casesummary_wht.png';
import Complianceicon from '../../assets/GST-dash/Compliance.png';
import Compliancewhticon from '../../assets/GST-dash/Compliance_wht.png';
import ManageGstInBlk from '../../assets/GST-dash/Manage.png';
import PHDashboard from '../../assets/PH_Register/PH_dash_icon-wht.svg';
import PHDashboardBlk from '../../assets/PH_Register/ph_dashboard.svg';
import GstDashWht from '../../assets/images/Gst_dash-White.svg';
import ManageGstIn from '../../assets/images/ManageGst.svg';
import changepassIcon from '../../assets/images/change password.svg';
import gstDashboardIcon from '../../assets/images/gstDashboard.svg';
import LogoutIcon from '../../assets/images/logout.svg';
import ProfileIcon from '../../assets/images/profile.svg';
import SoluDashWht from '../../assets/images/solu_dash_wht.svg';
import SolutionDashboard from '../../assets/images/solutionDashboard.svg';
import SyncGreen from '../../assets/images/syncGreen.svg';
import UsersIcon from '../../assets/images/users.svg';
import Logo from '../../assets/images/zentaxLogo.svg';
import closeIcon from '../../assets/register/close.png';
import gifIcon from '../../assets/images/upload.gif';
import plusIcon from '../../assets/images/plus.svg';
import passwordIcon from '../../assets/images/password.svg'
import usernameIcon from '../../assets/images/username.svg'
import attachIcon from '../../assets/images/attach_icon.svg'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import axios from 'axios';
// ..........

export default function Header() {

	// form values states
	const phlogin = JSON.parse(localStorage.getItem('ph_login') || '{}')

	const [captchacnt, setCaptchacnt] = useState('');
	const [usertype, setUsertype] = useState<string>(phlogin ? 'Ph Partner' : 'Certified Partner');
	const [tempUsertype, setTempUsertype] = useState<string>(phlogin ? 'Ph Partner' : 'Certified Partner');

	const [Password, setPassword] = useState({
		current_password: '',
		new_password: '',
		confirm_password: '',
	});

	const [passwordtype, setPasswordType] = useState('password')

	const [password_type, setPasswordtype] = useState({
		currentPasswordType: 'password',
		newPasswordType: 'password',
		confirmPasswordType: 'password',
	});

	const [showEyeIcon, setShowEyeIcon] = useState({
		showCurrentEyeIcon: true,
		showNewEyeIcon: true,
		showConfirmEyeIcon: true,
	});

	const [min_char, setMinChar] = useState(false)
	const [upper_lower, setUpperLower] = useState(false)
	const [number_symbol, setNumberSymbol] = useState(false)
	const [new_min_char, setNewMinChar] = useState(false)
	const [new_upper_lower, setNewUpperLower] = useState(false)
	const [new_number_symbol, setNewNumberSymbol] = useState(false)
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const [OTP, setOtp] = useState<string[]>(['', '', '', '']);
	const [err_otp, setErr_otp] = useState('');

	const [isSyncAllProgress, setIsSyncAllProgress] = useState(false)

	const [form] = Form.useForm();
	const { formValues, handleChange, handleInitial } = useContext(AppContext)
	const { typeOfSolution, ticDescription, ticContactNumber, user_name, password, otp, casestatus, state, trade_name, gstin, gstin_input, tax_period, refid, caseid, casetype, section, taxamount } = formValues;

	const [duedate, setDuedate] = useState<any>(null)
	const [noticedate, setNoticedate] = useState<any>(null)
	const [Trade_name, setTradename] = useState('')
	const [selectedstate, setSelectedState] = useState('')
	const [gstid, setGstid] = useState('')
	const [Gst_in, setGstin] = useState([])
	const [noticeid, setNoticeid] = useState('')
	const [States, setStates] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState<any>([])
	const Tax_period = [
		{
			id: 1,
			value: 'Jul 2017 - Mar 2018',

		},
		{
			id: 2,
			value: 'Apr 2018 - Mar 2019',

		},
		{
			id: 3,
			value: 'Apr 2019 - Mar 2020',

		},
		{
			id: 4,
			value: 'Apr 2020 - Mar 2021',

		},
		{
			id: 5,
			value: 'Apr 2021 - Mar 2022',

		},
		{
			id: 6,
			value: 'Apr 2022 - Mar 2023',

		}
	];
	const TypeOfSolution = [
		{ id: 1, value: 'No action required' },
		{ id: 2, value: 'Action to be taken' },
		{ id: 3, value: 'Action taken' },
	]
	const [managegstdetails, setManagegstdetails] = useState([])
	// ...........

	// alerts and modal states
	const [optOpen, setOptOpen] = useState(false)
	const [notifopen, setNotifopen] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [profileOpen, setProfileOpen] = useState(false)
	const [logoutOpen, setLogoutOpen] = useState(false)
	const [passwordOpen, setPasswordopen] = useState(false)
	const [State, setState] = React.useState({ right: false });
	const [notifyOpen, setNotifyOpen] = useState(false)
	const [notifyType, setNotifyType] = useState<any>()
	const [notifyMessage, setNotifyMessage] = useState('')
	const [modal2Open, setModal2Open] = useState(false);
	const [modal3Open, setModal3Open] = useState(false);
	const [addGstLoadModal, setAddGstLoadModal] = useState(false)
	// ...........

	//  datas states
	const [userRole, setUserRole] = useState<any>()
	const [userData, setUserData] = useState<any>()
	const [overDueNotices, setOverDueNotices] = useState<any>()
	const [pendingNotices, setPendingNotices] = useState<any>()
	let [syncId, setSyncId] = useState<any>(null)
	const [hoveredButton, setHoveredButton] = useState<number | null>(null);
	// ...........

	// loading states
	const [syncloading, setSyncloading] = useState(false)
	const [formloading, setFormloading] = useState(false);
	const [loading, setLoading] = useState(false)
	// ..........

	// reference states
	const abortControllerRef = useRef<AbortController | null>(null);
	const inputFileElement = useRef<any>();
	const manageGstTable = useRef<any>();
	const navigate = useNavigate()
	const locations = useLocation()
	const location = useLocation()
	const optionsRef = useRef<any>(null);
	const notifRef = useRef<any>(null);
	const headerMasterLoading = useRef(false)
	const textRef = useRef<HTMLDivElement>(null);
	const [viewback, setViewback] = useState(false)
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const { addnotice, setAddnotice, setBreadcrumbPath, setSelectgst, setGstSyncModal, setIsManagegst, setOverviewDetails, setProgres, progres, masterData, setMasterData, setFilterQuery, setSorterQuery, setSearchquery, syncedCount, setPage, setTotalpages, setTabactive, setManageactive, tabview, setTabview, setSumpopup, setSumdrawer, setRowsPerPage, setreportModal, reportmodal, syncDetails, setChangeview, setSyncDetails, setChartquery } = useGlobalState();
	const { getSyncCount, handlesync } = Utils();
	// ...........

	// data get function
	const captchacountfunc = () => {
		serviceConfig.get("captcha_count", true, null, null).then((data: any) => {
			setCaptchacnt(data.data.balance || 0)
		}).then((err: any) => {
		})
	}

	// data get function
	const getStatusCount = () => {
		serviceConfig.get("get_notices_status_count", true, null, null).then((data: any) => {
			setOverDueNotices(data.data.overdue_notices)
			setPendingNotices(data.data.pending_notices)
		}).then((err: any) => {
		})
	}

	const refreshUserData = () => {
		setUserData(JSON.parse(localStorage.getItem('userData') || ''))
	}

	// prevent filter values

	const localStorageKeys: any = {
		gst: 'masterData_gst',
		states: 'masterData_states',
		trades: 'masterData_trades',
		caseIdFilter: 'masterData_caseIdFilter',
		arnFilter: 'masterData_arnFilter',
		sessionFilter: 'masterData_sessionFilter',
		caseType: 'masterData_caseType',
		userNames: 'masterData_userNames'
	};

	// Effect to load data from localStorage when the component mounts
	useEffect(() => {
		const loadData = () => {
			Object.keys(localStorageKeys).forEach(key => {
				const savedData = localStorage.getItem(localStorageKeys[key]);
				if (savedData) {
					setMasterData((prevState: any) => ({
						...prevState,
						[key]: JSON.parse(savedData)
					}));
				}
			});
		};

		loadData();
	}, []);
	// ...........

	useEffect(() => {
		getStatusCount()
		loadmanagegstdata()
		captchacountfunc()
		setUserRole(localStorage.getItem('userRole') || '')
		setUserData(JSON.parse(localStorage.getItem('userData') || ''))
		!headerMasterLoading.current && getSyncCount()
		document.addEventListener('storage', event => {
			// if(syncedCount < parseInt(localStorage.getItem('syncedCount') || '0')){
			// setSyncedCount(parseInt(localStorage.getItem('syncedCount') || '0'))
			// }
		});

		function handleClickOutside(event: any) {
			if (optionsRef.current && !optionsRef.current?.contains(event.target)) {
				setOptOpen(false)
				setNotifopen(false)
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [optionsRef]);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
		setIsMobile(mediaQuery.matches);

		const handleResize = () => setIsMobile(mediaQuery.matches);

		mediaQuery.addListener(handleResize);

		return () => {
			mediaQuery.removeListener(handleResize);
		};

	}, []);
	// ...........

	useEffect(() => {

		const module: any = location.pathname.split('/').pop();
		const module1: any = location.pathname.split('/')[3];

		if (module === 'view-notices') {
			setViewback(true);
		}
		else if (module1 === 'view-solution-details') {
			setViewback(true);
		}
		else if (module === 'view-gst-details') {
			setViewback(true);
		}
		else if (module === 'case-summary-details') {
			setViewback(true);
		}
		else {
			setViewback(false);
		}

	}, [location])

	useEffect(() => {
		getFileUploadStatus()
		getLastSyncStatus()
		// get states list add notice manual
		const getStates = async (country: string) => {
			const url = `https://countriesnow.space/api/v0.1/countries/states/q/?country=${country}`;
			return await axios.get(url);
		};

		getStates('India').then((res: any) => {
			setStates(res.data.data.states)
		})

		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	}, [])

	useEffect(() => {
		const selectedTrade: any = managegstdetails.find((item: any) => item.gst_no === gstin.value);
		if (selectedTrade) {
			setSelectedState(selectedTrade.state || ''); // Set the state value or empty string if not found
			setTradename(selectedTrade.trade_name || '');
			setGstid(selectedTrade.id || '')
		}
	}, [formValues.gstin])

	// upload status function
	const getFileUploadStatus = () => {
		var start_autoload: any = setInterval(() => {
			serviceConfig.get('gst_upload_file_status', true, null, null)
				.then((data: any) => {
					if (data && data.length > 0 && data[0].is_completed === false) {
						setProgres({ data: { loading: true, label: 'Upload' } })
					}
					if (data && data.length > 0 && data[0].is_completed || data.length === 0) {
						clearInterval(start_autoload)
						start_autoload = null;
						setProgres({ data: { loading: false, label: '' } })
					}

				}, (err: any) => {
					clearInterval(start_autoload)
					start_autoload = null;
					setProgres({ data: { loading: false, label: '' } })
				})
		}, 10000);
	}
	// ...........

	// sync last status function

	const getLastSyncStatus = () => {
		setSyncloading(true)
		serviceConfig.get("gst_sync", true, { 'last': true }, null).then((data: any) => {
			setSyncloading(false)
			if (data.length > 0) {
				setSyncDetails(data[0])
				// if (!data[0].sync_processed && data[0].is_sync_all) {
				if (!data[0].sync_processed) {
					syncId = data[0].id
					setSyncId(data[0].id)
					getStatusofSyncAllProgress()
				}
			}
		}, (err: any) => {
			setSyncloading(false)
			setreportModal(false)
			setNotifyType("error")
			setNotifyMessage('Something Went Wrong!')
			setNotifyOpen(true)
		})
	}
	const getStatusofSyncAllProgress = () => {
		setSyncloading(true)
		setProgres({ data: { loading: true, label: 'Sync' } })

		serviceConfig.get("gst_sync_details", true, null, { id: syncId }).then((data: any) => {
			setSyncDetails(data)
			setSyncloading(false)
			syncId = data.id
			setSyncId(data.id)
			let timerInt = !data.sync_processed ? setTimeout(() => {
				getStatusofSyncAllProgress();
			}, (10 * 1000)) : null;
			if (data.sync_processed) {
				timerInt = null;
				setreportModal(true)
				setProgres({ data: { loading: false, label: '' } })
			}
		}, (err: any) => {
			setNotifyType("error")
			setNotifyMessage('Something Went Wrong!')
			setNotifyOpen(true)
			setSyncloading(false)
			setreportModal(false)
			setProgres({ data: { loading: false, label: '' } })
		})
	}
	// ...........

	// drawer and modal functions
	const toggleDrawer =
		(anchor: any, open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (
					event &&
					event.type === 'keydown' &&
					((event as React.KeyboardEvent).key === 'Tab' ||
						(event as React.KeyboardEvent).key === 'Shift')
				) {
					return;
				}

				setState({ ...State, [anchor]: open });
			};

	const openPopup = (type: any) => {
		setOptOpen(false)
		type === 'profile' && setProfileOpen(true);
		type === 'logout' && setLogoutOpen(true);
		type === 'password' && setPasswordopen(true);
	}
	// ...........

	// Copy to clipboard option function
	const handleCopyClick = (code: any) => {
		const content = userData.cf_code
		copy(code);
		setNotifyType("success")
		setNotifyMessage("Refer Code copied successfully.")
		setNotifyOpen(true)
	};
	// ..........

	// sub component
	const welcometext = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}>
				<b>{userData && userData.user_name
					? userData.user_name.length > 18
						? `${userData.user_name.substring(0, 18)}...`
						: userData.user_name
					: ''}</b>
				<Avatar style={{ textTransform: 'uppercase', marginLeft: '1vw' }} className='avatar' onClick={() => setOptOpen(true)} > {userData ? userData.user_name.charAt(0) : ''}</Avatar>
			</div>
		)
	}
	// ...........

	// sub component
	const list = (anchor: any) => (
		<Box
			className="drawer"
			sx={{ width: 250, height: '100vh', display: 'flex', flexDirection: 'column' }}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List >
				<ListItem disablePadding>
					<ListItemButton>
						<ListItemText primary={welcometext()} />
					</ListItemButton>
				</ListItem>
			</List>
			<Divider />
			<List className='drawer-list'>
				{/* {userRole !== 'Super Admin' && userRole !== 'PH Partner' && <Button id="sync-btn" > <img src={SyncGreen} alt='' />GSTIN sync count - <b>{syncedCount}</b> </Button>} */}
				{nav_btns.map((button: any) => (
					((userRole !== 'Super Admin') || (userRole === 'Super Admin' && button.name !== 'SOLUTION DASHBOARD')) &&
					<Button
						//  onClick={() => menuclick(button)}
						className={button.path.includes(locations.pathname) === false ? 'menu_btn' : 'menu_btn menu_btn_active'}> {button.path.includes(locations.pathname) === false ? <img src={button.icon} style={{ height: 18 }} alt='' /> : <img src={button.activeicon} style={{ height: 18 }} alt='' />}{button.name}</Button>
				))}

			</List>
			<Divider style={{ marginTop: 'auto' }} />
			<List >
				{userRole === 'Super Admin' && <ListItem disablePadding>
					<ListItemButton onClick={() => openPopup('logout')}>
						<ListItemText primary={`TC-Balance - $  ${captchacnt}`} />
					</ListItemButton>
				</ListItem>}
				{userData && userData.is_cf === true && !phlogin &&
					<ListItem disablePadding>
						<ListItemButton>
							<ListItemText primary={`Refer Code ${userData.cf_code ? userData.cf_code : '-'}`} />
							<ContentCopyIcon fontSize='small' className='clipboard-icon' onClick={() => { handleCopyClick(userData.cf_code) }} />
						</ListItemButton>
					</ListItem>
				}
				{userData && userData.ph_code && phlogin &&
					<ListItem disablePadding>
						<ListItemButton >
							<ListItemText primary={`Ph Partner Code ${userData.ph_code ? userData.ph_code : '-'}`} />
							<ContentCopyIcon fontSize='small' className='clipboard-icon' onClick={() => { handleCopyClick(userData.ph_code) }} />
						</ListItemButton>
					</ListItem>
				}

				{(userData && userData.is_cf === true && !phlogin || userRole === 'Super Admin') && <ListItem disablePadding>
					<ListItemButton
						onClick={() => {
							navigate('/home/users');
							setBreadcrumbPath('/home/users')
							setOptOpen(!optOpen);
							setFilterQuery({});
							setPage(1);
							setTotalpages(0);
							setIsManagegst(false);
							setSumdrawer(false);
							setRowsPerPage(10)
						}}>
						<ListItemIcon>
							<img src={UsersIcon} alt='UsersIcon' />
						</ListItemIcon>
						<ListItemText primary={'Users'} />
					</ListItemButton>
				</ListItem>}
				{userRole !== 'Super Admin' && <ListItem disablePadding>
					<ListItemButton onClick={() => {
						navigate('/home/syncmonitoring')
						setBreadcrumbPath('/home/syncmonitoring')
						setFilterQuery({});
						setSorterQuery({})
						setSearchquery('')
						setPage(1);
						setTotalpages(0);
						setIsManagegst(false)
						setRowsPerPage(10);
						setTabview(false)
						setTabactive('GSTIN Summary')
						setChartquery(false)
						setSumdrawer(false);
						setSumpopup(false)
					}}>
						<ListItemText primary={'Sync Monitoring'} />
					</ListItemButton>
				</ListItem>}
				{userRole !== 'Super Admin' && <ListItem disablePadding>
					<ListItemButton onClick={() => openPopup('profile')}>
						<ListItemIcon >
							<img src={ProfileIcon} alt='ProfileIcon' />
						</ListItemIcon>
						<ListItemText primary={'Profile'} />
					</ListItemButton>
				</ListItem>}
				{userRole !== 'Super Admin' && <ListItem disablePadding>
					<ListItemButton onClick={() => { userData && userData.password_status ? openPopup('password') : handlerequestotp() }}>
						<ListItemIcon >
							<img src={changepassIcon} alt='ProfileIcon' />
						</ListItemIcon>
						<ListItemText primary={'Change Password'} />
					</ListItemButton>
				</ListItem>}
				<ListItem disablePadding>
					<ListItemButton onClick={() => openPopup('logout')}>
						<ListItemIcon>
							<img src={LogoutIcon} alt='LogoutIcon' />
						</ListItemIcon>
						<ListItemText primary={'Logout'} />
					</ListItemButton>
				</ListItem>
			</List>
		</Box >
	);
	// ...........

	// button details props
	const nav_btns: any = [
		{
			name: 'NOTICES',
			path: ['/home/overview', 'overview'],
			icon: gstDashboardIcon,
			activeicon: GstDashWht,
			submenu: [
				{
					groupName: 'Notice wise details',
					items: [
						{ name: 'All Notices', path: '/home/overview' },
						{ name: 'Latest Notices', path: '/latest-notices' },
						{ name: 'Action taken', path: '/action-taken' },
						{ name: 'Action to be taken', path: '/action-to-be-taken' },
						{ name: 'Overdue', path: '/overdue' },
					],
				},
				{
					groupName: 'GSTIN wise details',
					items: [
						{ name: 'Manage GSTIN', path: '/home/overview' },
						{ name: 'Incorrect password', path: '/home/overview' },
					],
				},
			],
		},
		{
			name: 'SERVICES',
			path: ['/home/gst-dashboard', 'gst-dashboard', '', 'home', 'view-gst-details'],
			icon: ManageGstInBlk,
			activeicon: ManageGstIn,
			submenu: [
				{
					items: [
						{ name: 'Solutions', path: '/home/solution-dashboard' },
						{ name: 'GST Loans', path: '/home/gst-loan' },
						{ name: 'Knowledge Bank', path: '/home/overview' }
					]
				}
			],
		},
		// {
		// 	name: 'GST DASHBOARD',
		// 	path: ['/home/gst-dashboard', 'gst-dashboard', '', 'home', 'view-gst-details'],
		// 	icon: gstDashboardIcon,
		// 	activeicon: GstDashWht
		// },
	]
	if (userRole === 'PH Partner' && phlogin) {
		nav_btns.push(
			// {
			// 	name: 'PH PARTNER DASHBOARD',
			// 	path: ['/home/phdashboard', 'phdashboard'],
			// 	icon: PHDashboardBlk,
			// 	activeicon: PHDashboard
			// }
		);
	} else if (userRole !== 'Super Admin') {
		nav_btns.push(
			{
				name: 'COMPLIANCE',
				path: ['/home/compliance', 'compliance'],
				icon: Complianceicon,
				activeicon: Compliancewhticon,
				submenu: [
					{
						items: [
							{ name: 'Compliance Monitor', path: '/home/compliance' },
						]
					}
				]
			},
		)
	} else {
		nav_btns.push(
			{
				name: 'USERS APPROVAL',
				path: ['/home/userapproval', 'userapproval'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			},
			{
				name: 'PH PARTNERS',
				path: ['/home/phpartners', 'phpartners'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			},
			{
				name: 'SYNC MONITORING',
				path: ['/home/syncmonitoring', 'syncmonitoring'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			},
			{
				name: 'LATEST UPDATES',
				path: ['/home/latestupdates', 'latestupdates'],
				icon: ManageGstInBlk,
				activeicon: ManageGstIn
			}
		)
	}

	const menuClickHandler = (item: any) => {
		navigate(item.path);
		setSelectgst(null);
		setBreadcrumbPath(item.path);
		if (item.name === 'Incorrect password') {
			setManageactive('Incorrect')
		} else {
			setManageactive('Active')
		}
		setFilterQuery({});
		setSorterQuery({})
		setSearchquery('')
		setPage(1);
		setTotalpages(0);
		setRowsPerPage(10);
		if (item.name === 'Manage GSTIN') {
			setBreadcrumbPath(`home/notices/manage gstin`);
		} else if (item.name === 'Incorrect password') {
			setBreadcrumbPath(`home/notices/incorrect password`);
		} else if (item.name === 'All Notices') {
			setBreadcrumbPath(`home/notices/all notices`);
		}

	};

	const renderMenu = (submenu: any[] | undefined, index: number) => (
		<Menu
			style={{ display: submenu ? '' : 'none' }}
			className="horizontal-grouped-menu"
			onMouseEnter={() => setHoveredButton(index)}
			onMouseLeave={() => setHoveredButton(null)}
		>
			<div className='menu-groups'>
				{submenu?.map((group: any) => (
					<div className='menu-group'>
						<Menu.ItemGroup key={group.groupName} title={group.groupName}>
							{group.items.map((item: any) => (
								<Menu.Item key={item.name} onClick={() => {
									menuClickHandler(item);
								}}>
									{item.name}
								</Menu.Item>
							))}
						</Menu.ItemGroup>
					</div>
				))}
			</div>
		</Menu>
	);

	const add_opt_submenu = [
		{
			items: [
				{ name: 'Add GST', openstate: setModal2Open },
				{ name: 'Add Notice', openstate: setAddnotice },
				{ name: 'Get Expert Solution', openstate: setModal3Open },
			]
		}
	]
	const renderAddMenu = (submenu: any[] | undefined, index: number) => (
		<Menu
			style={{ display: submenu ? '' : 'none' }}
			className="horizontal-grouped-menu"
			onMouseEnter={() => setHoveredButton(index)}
			onMouseLeave={() => setHoveredButton(null)}
		>
			{submenu?.[0]?.items.map((item: any) => (
				<Menu.Item key={item.name} onClick={() => submenuClick(item)}>
					{item.name}
				</Menu.Item>
			))}
		</Menu>
	);

	const submenuClick = (item: any) => {
		item.openstate(true)
	}
	// ...........

	const popupFunc = (label: any) => {
		if (label === 'Sync') {
			setreportModal(true)
		}
	}

	const menuclick = (button?: any) => {
		// if (button.name === 'MANAGE GSTIN') {
		// 	setIsManagegst(true)
		// } else {
		setIsManagegst(false)
		// }
		setManageactive('Active')
		if (button && button.name) {
			navigate(`${button.path[0]}`);
			setBreadcrumbPath(`${button.path[0]}`)
		}
		setFilterQuery({});
		setSorterQuery({})
		setSearchquery('')
		setPage(1);
		setTotalpages(0);
		setRowsPerPage(10);
		setTabview(false)
		// setTabactive('GSTIN Summary')
		setOverviewDetails({ gst_no: '', trade_name: '' });
		setChartquery(false)
		setSumdrawer(false);
		setSumpopup(false)
		// if (button.name === 'DUE CALENDAR') {
		// 	setChangeview(true)
		// } else {
		setChangeview(false)
		// }
	}
	// .........

	// modal close function
	const handleClose = () => {
		setPasswordopen(false)
		setPassword({
			current_password: '',
			new_password: '',
			confirm_password: '',
		})
		setMinChar(false)
		setNumberSymbol(false)
		setUpperLower(false);
		setOtp(['', '', '', ''])
	};
	// ..........

	// password confirmation check function    
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

	useEffect(() => {
		setIsSubmitDisabled(Password.new_password !== Password.confirm_password);
	}, [Password.new_password, Password.confirm_password]);
	// .......

	// password field value handle function

	const HandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setPassword((prevState) => ({
			...prevState,
			[name]: value,
		}));

		if (name === 'current_password') {
			validatePassword(value)
		} else if (name === 'new_password') {
			validateConfirmPassword(value)
		}
	};
	// ........

	// current password validation
	const validatePassword = (pwd: string) => {
		setMinChar(/^.{7,}$/.test(pwd));
		setUpperLower(/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(pwd));
		setNumberSymbol(/^(?=.*\d)(?=.*[@#$%^&!*]).+$/.test(pwd));
	};

	const validateConfirmPassword = (confirmPwd: string) => {
		setNewMinChar(/^.{7,}$/.test(confirmPwd));
		setNewUpperLower(/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(confirmPwd));
		setNewNumberSymbol(/^(?=.*\d)(?=.*[@#$%^&!*]).+$/.test(confirmPwd));
	}
	// .....

	const togglePasswordVisibility = (field: 'currentPasswordType' | 'newPasswordType' | 'confirmPasswordType') => {
		setPasswordtype((prevState: any) => ({
			...prevState,
			[field]: prevState[field] === 'password' ? 'text' : 'password',
		}));
	};
	// .........

	// updation funtion
	const updateUser = () => {
		setLoading(true)
		let body: any = {
			'old_password': Password.current_password,
			'new_password': Password.new_password,
			'confirm_password': Password.confirm_password,
			// id: user_data.id,
		}

		serviceConfig.post('changepassword', true, body, null).then((res: any) => {
			handleClose()
			setNotifyType("success")
			setNotifyMessage('Password Changed Successfully !')
			setNotifyOpen(true)
			setLoading(false)
		},
			(err: any) => {
				console.log(err)
				setNotifyType("error")
				setNotifyMessage(err.response.data[0])
				setNotifyOpen(true)
				setLoading(false)
			}
		)
	}

	const passwordverify = () => {
		if (!validateOtp()) {
			setErr_otp('*Please enter all 4 digits of the OTP');
			return;
		} else {
			setLoading(true)
			let body: any = {
				'otp': OTP.join(''),
				'password': Password.new_password
			}

			serviceConfig.post('verify_otp', true, body, null).then((res: any) => {
				handleClose()
				setNotifyType("success")
				setNotifyMessage('Password Verified Successfully !')
				setNotifyOpen(true)
				setLoading(false)
				setOtp(['', '', '', ''])
				if (userData) {
					const userdata = userData;
					userdata.password_status = true;
					const updatedData = JSON.stringify(userdata);
					localStorage.setItem("userData", updatedData);
				}
			},
				(err: any) => {
					console.log(err)
					setNotifyType("error")
					setNotifyMessage(err.response.data[0])
					setNotifyOpen(true)
					setLoading(false)
				}
			)
		}
	}
	// .........

	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		'& .MuiDialogContent-root': {
			padding: theme.spacing(2),
		},
		'& .MuiDialogActions-root': {
			padding: theme.spacing(1),
		},
	}));

	// type change handle function 
	const userType: any = [
		'Certified Partner',
		'Ph Partner',
	]

	const handlechange = (event: any) => {
		const { value } = event.target;
		setTempUsertype(value);
		setOpenDialog(true);
	};

	const handlechangetype = () => {
		setUsertype(tempUsertype);
		if (tempUsertype === 'Certified Partner') {
			setOpenDialog(false);
			localStorage.setItem('ph_login', JSON.stringify(false));
			navigate('/home/gst-dashboard');
		} else if (tempUsertype === 'Ph Partner') {
			setOpenDialog(false);
			localStorage.setItem('ph_login', JSON.stringify(true));
			navigate('/home/phdashboard');
		}
	}

	const handlecanceltype = () => {
		setOpenDialog(false);
		setTempUsertype('Certified Partner')
	}
	// .....

	// otp field
	const handleChangeotp = (value: string, index: number) => {
		const newOtp = [...OTP];
		newOtp[index] = value;
		setOtp(newOtp);


		// Move focus to the next input if a digit is entered
		if (value && index < OTP.length - 1) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`);
			nextInput?.focus();
		}

		setErr_otp('')
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (e.key === 'Backspace' && !OTP[index] && index > 0) {
			const prevInput = document.getElementById(`otp-input-${index - 1}`);
			prevInput?.focus();
		}
	};

	const validateOtp = (): boolean => {
		// Check if any of the OTP fields are empty
		return OTP.every((digit) => digit !== '');
	};
	// ..........

	// mobile number submission
	const handlerequestotp = () => {
		let query: any = {}
		serviceConfig.get("check_otp", true, query, null).then((data: any) => {
			setNotifyType("success")
			setNotifyMessage('OTP sended to your registered mobile number.')
			setNotifyOpen(true)
			openPopup('password')
			setMinChar(true)
			setUpperLower(true)
			setNumberSymbol(true)
		}, (err: any) => {
			console.log(err)
			setNotifyType("error")
			setNotifyMessage(err && err.response.data || 'Something Went Wrong !')
			setNotifyOpen(true)
		})
	}
	// ..........

	// sync initiate function
	const syncProgress = useRef(false)
	const synccallfunction = (selectedGST?: any) => {
		console.log(selectedGST)
		handlesync(selectedGST)
			.then((data: any) => {
				setIsSyncAllProgress(data.isSyncAll)
				syncId = data.data.syncId
				setSyncId(data.data.syncId)
				setGstSyncModal(true)
				setTimeout(() => setGstSyncModal(false), 40000)
				syncProgress.current = false;
				getStatusofSyncAllProgress()
			})
			.catch((err: any) => {
				setNotifyType("error")
				console.log(err)
				setNotifyMessage(err.response.message)
				setNotifyOpen(true)
				syncProgress.current = false;
			});

	}

	// ........

	// custom theming function
	const inputtheme = createTheme({
		palette: {
			primary: {
				main: '#9a9999',
			}
		},
	});
	// ..........

	// add gst option
	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({ user_name, password }).some(
				(name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
			),
		[formValues, user_name, password]
	)

	const actionGst = () => {
		setFormloading(true)
		setModal2Open(false)
		setAddGstLoadModal(true)

		setProgres({ data: { loading: true, label: 'Upload' } })

		const addformdata = { user_name: formValues.user_name.value, password: formValues.password.value }

		serviceConfig.post("manageGst", true, { ...addformdata, id: '' }, false, false, true)
			.then((res: any) => {
				console.log(res, 'respones')
				if (res.id) {
					setProgres({ data: { loading: false, label: '' } })
					// getFileUploadStatus()
					setFormloading(false)
					setNotifyType("success")
					setNotifyMessage("GST details added successfully.")
					setNotifyOpen(true)
					manageGstTable.current.setTableQuery({})
					window.location.reload()
					handleInitial()
					setAddGstLoadModal(false)

					setPasswordType('password')
				} else {
					if (res.message) {
						setProgres({ data: { loading: false, label: '' } })
						const { msg, ...rest } = res.data;
						setFormloading(false)
						setNotifyType("warning")
						setNotifyMessage("Enter your OTP.")
						setNotifyOpen(true)
						setAddGstLoadModal(false)
					}
				}
			}).catch((err: any) => {
				setProgres({ data: { loading: false, label: '' } })
				console.log(err, 'error accured')
				setNotifyType("error")
				if (err.response && err.response.data) {
					setNotifyMessage(err.response.data);
				} else {
					setNotifyMessage("An error occurred");
				}
				setNotifyOpen(true)
				setFormloading(false)
				setAddGstLoadModal(false)
				setPasswordType('password')
			})
		handleInitial()
	}
	// ........

	// Add notice function

	const loadmanagegstdata = () => {
		serviceConfig.get("manageGst", true, null, null).then((data: any) => {
			setManagegstdetails(data)
			// const names =
			// 	data.filter((item: any) => item.trade_name !== null)
			// 		.map((item: any) => item.trade_name as string);
			// setTradename(names);
			const gsts = data
				.filter((item: any) => item.gst_no !== null)
				.map((item: any) => item.gst_no);
			setGstin(gsts);
		})
			.catch((error: any) => { console.log(error) })
	}

	// Date format change funcions for tax-period value

	const handleDateChange = (date: dayjs.ConfigType | null) => { // Use dayjs.ConfigType instead of Moment
		if (date) {
			// Convert Moment to Dayjs
			const validDate = dayjs(date);
			if (validDate.isValid()) {
				// Format date using Dayjs
				const formattedDate = validDate.format('YYYY-MM-DD');
				setDuedate(formattedDate);
			} else {
				console.error('Invalid date');
			}
		} else {
			setDuedate(null);
		}
	};

	const handlenoticeDateChange = (date: dayjs.ConfigType | null) => { // Use dayjs.ConfigType instead of Moment
		if (date) {
			// Convert Moment to Dayjs
			const validDate = dayjs(date);
			if (validDate.isValid()) {
				// Format date using Dayjs
				const formattedDate = validDate.format('YYYY-MM-DD');
				setNoticedate(formattedDate);
			} else {
				console.error('Invalid date');
			}
		} else {
			setNoticedate(null);
		}
	};

	// ................. Add notice fileupload
	const handleselectedFile = (event: any) => {

		let oldFiles = [...selectedFiles]
		Array.from(event.target.files).forEach(fi => {
			oldFiles.push(fi)
		});
		setSelectedFiles(oldFiles)

	};

	const removeFile = (ind: any) => {
		let oldFiles = [...selectedFiles]
		oldFiles.splice(ind, 1)
		setSelectedFiles(oldFiles)
	}

	const handlemanualfileupload = () => {

		setLoading(true)
		let req_body: any;
		req_body = {
			// 'file_count': selectedFiles.length,
			'master_type': 'Manual',
			"notice_status": casestatus.value,
			'section': section.value,
			'tax_amount': taxamount.value,
			'due_date': duedate,
			'issue_date': noticedate,
			'tax_period': tax_period.value,
			'gst': gstin.value && gstin.value === 'others' ? {
				"gst_no": gstin_input.value,
				"trade_name": trade_name.value,
				"state": state.value,
			} : gstid,
			'case_data': JSON.stringify({
				"id": null,
				"case_type_name": casetype.value,
				"case_ref_id": [
					refid.value
				],
				"case_arn_no": caseid.value,
				"case_description": [
					casetype.value
				],
				"portal_status": casestatus.value,
				"case_id": null,
				"case_task_id": [
					null
				]
			})
		}

		serviceConfig.upload("manual_notice_add", null, selectedFiles, req_body)
			.then((data: any) => {
				setAddnotice(false)
				window.location.reload()
				AddnoticeInitial()
				setLoading(false)
				setNotifyType("success");
				setNotifyMessage("Manual Notice Created Successfully!");
				setNotifyOpen(true);
				handleInitial()
			}, (err: any) => {
				console.log(err)
				setLoading(false)
				setNotifyType("error");
				setNotifyMessage("Something went wrong!");
				setNotifyOpen(true);
				AddnoticeInitial();
				setSelectedFiles([])
				setSelectedState('');
				setTradename('')
			})
	}

	const AddnoticeInitial = () => {

		handleInitial()
		setDuedate('')
		setNoticedate('')
	}
	// ......

	const createTaskManually = () => {
		if (!loading) {

			setLoading(true)
			let req_body: any;
			if (selectedFiles.length > 0) {
				req_body = new FormData()
				selectedFiles.forEach((file: any, index: any) => {
					req_body.append('file' + (index + 1), file)
				});
				req_body.append('file_count', selectedFiles.length)
				req_body.append('type', typeOfSolution.value)
				req_body.append('description', ticDescription.value)
				req_body.append('contact_no', ticContactNumber.value)
			} else {
				req_body = {
					'file_count': 0,
					"type": typeOfSolution.value,
					"description": ticDescription.value,
					"contact_no": ticContactNumber.value,
				}
			}

			serviceConfig.post("create_ticket", true, req_body, null, null, selectedFiles.length > 0 ? false : true).then((data: any) => {
				setModal3Open(false)
				setLoading(false)
				window.location.reload()
				handleInitial()
				setSelectedFiles([])
				setNotifyType("success");
				setNotifyMessage("Ticket Created Successfully!");
				setNotifyOpen(true);
			}, (err: any) => {
				setLoading(false)
				console.log(err)
				setNotifyType("error");
				setNotifyMessage("Something went wrong!");
				setNotifyOpen(true);
			})

		}

	}
	// .........

	return (
		<>
			<Modal
				title={""}
				onCancel={() => setAddGstLoadModal(false)}
				width={'35%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={addGstLoadModal}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div>
					<b>Adding GSTIN in progress</b>
					<p>Adding GSTIN may take 30 seconds. Please wait. You may close this dialog box.</p>
				</div>
			</Modal>
			<Modal
				title="ADD GSTIN"
				onCancel={() => { setModal2Open(false); setFormloading(false); handleInitial(); setPasswordType('password') }}
				width={'30%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={modal2Open}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>

				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
						<label className="label">User id</label>
						<div className="input-box" >
							<img className="icons" src={usernameIcon} alt='usernameIcon'></img>
							<input
								type="text"
								name='user_name'
								value={user_name.value}
								onBlur={handleChange}
								onChange={handleChange}
								placeholder="Enter your portal user id"></input>
							{user_name.error && <p className="gst-err-msg" >{user_name.error}</p>}
						</div>
					</div>
					<div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
						<label className="label">Password</label>
						<div className="input-box" >
							<img className="icons" src={passwordIcon} alt='password_icon'></img>
							<input
								type={passwordtype}
								name='password'
								value={password.value}
								onBlur={handleChange}
								onChange={handleChange}
								placeholder="Enter your password"></input>
							{passwordtype === 'password' ? <VisibilityOutlinedIcon onClick={() => setPasswordType('text')} className='eye_icon' /> : <VisibilityOffOutlinedIcon onClick={() => setPasswordType('password')} className='eye_icon' />}
							{password.error && <p className="gst-err-msg">{password.error}</p>}
						</div>
					</div>

					<Button
						htmlType="submit"
						className='popup-button'
						loading={formloading}
						disabled={isError()}
						style={{ background: "#424B70", color: "white", border: "none", width: "80%", height: "6vh", marginTop: password.error ? '' : '1.5rem', left: '10%' }}
						onClick={() => {
							actionGst()
						}}>
						{!formloading && <>Add & GET DETAILS FROM GSTIN</>}
						{formloading && <>Loading...</>}
					</Button>

				</Form>
			</Modal>

			<Modal
				title="Add notice"
				onCancel={() => { setAddnotice(false); AddnoticeInitial();handleInitial(); setSelectedFiles([]); setSelectedState(''); setTradename(''); setNoticeid('') }}
				width={'60%'}
				className='action-popup status-chng-popup'
				centered
				style={{ color: "#031742" }}
				open={addnotice}
				onOk={handlemanualfileupload}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div className="notice-form-container">
					<div className='left-grid'>
						<span style={{ fontSize: '2vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> GSTIN</span>
						{gstin_input.error && <span className="gst-err-msg">{gstin_input.error}</span>}
						{gstin.value !== 'others' ? <TextField
							fullWidth
							focused
							required
							select
							SelectProps={{ native: true, }}
							variant='outlined'
							name="gstin"
							value={gstin.value}
							onBlur={handleChange}
							onChange={handleChange}
							autoComplete='off'
							style={{ margin: '2% 0' }}
						>
							{Gst_in.map((type: any, index: any) =>
								<option key={index} value={type}>{type}</option>
							)}
						</TextField> :
							<Input
								type='text'
								readOnly
								name='gstin_input'
								value={gstin_input.value}
								onChange={handleChange}
								onBlur={handleChange}
								style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Gstin' />
						}
						{gstin.value === 'others' && <Input
							type='text'
							name='gstin_input'
							value={gstin_input.value}
							onChange={handleChange}
							onBlur={handleChange}
							style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Kindly Input Gstin' />
						}
						<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Trade Name</span>
						{trade_name.error && <span className="gst-err-msg">{trade_name.error}</span>}

						{gstin.value === 'others' && <Input
							type='text'
							name='trade_name'
							onChange={handleChange}
							onBlur={handleChange}
							value={trade_name.value}
							style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Kindly Input Trade name' />
						}
						{gstin.value !== 'others' && <Input
							type='text'
							readOnly
							defaultValue={Trade_name}
							value={Trade_name}
							style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Kindly Select Gstin' />
						}
						<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> State</span>
						{state.error && <span className="gst-err-msg">{state.error}</span>}

						{gstin.value === 'others' &&
							<TextField
								fullWidth
								focused
								required
								select
								SelectProps={{ native: true, }}
								variant='outlined'
								name="state"
								value={state.value}
								onBlur={handleChange}
								onChange={handleChange}
								autoComplete='off'
								style={{ margin: '2% 0' }}
							>
								<option value='' disabled>...</option>
								{States.map((type: any, index: any) =>
									<option key={index} value={type.name}>{type.name}</option>
								)}
							</TextField>
						}
						{gstin.value !== 'others' && <Input
							type='text'
							defaultValue={selectedstate}
							value={selectedstate}
							readOnly
							style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Kindly Select Gstin' />
						}
						<span style={{ fontSize: '2vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Tax Period</span>
						<TextField
							fullWidth
							focused
							required
							select
							SelectProps={{ native: true, }}
							variant='outlined'
							name="tax_period"
							value={tax_period.value}
							onBlur={handleChange}
							onChange={handleChange}
							autoComplete='off'
							style={{ margin: '2% 0' }}
						>
							<option value='' disabled>...</option>
							{Tax_period.map((type: any) =>
								<option key={type.id} value={type.value}>{type.value}</option>
							)}
						</TextField>

						<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">

							<Form.Item
								name="Notice Date"
								messageVariables={{ name: 'Case Date' }}
								className='form_item two'
							>
								<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Notice Date</span>

								<AntDatePicker value={noticedate ? dayjs(noticedate, 'YYYY-MM-DD') : null} onChange={(date, dateString: any) => handlenoticeDateChange(dateString)} style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: "2%" }} placeholder='Case Date' />
							</Form.Item>

							<Form.Item
								name="Due Date"
								messageVariables={{ name: 'Due Date' }}
								className='form_item two'
							>
								<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Due Date</span>

								<AntDatePicker value={duedate ? dayjs(duedate, 'YYYY-MM-DD') : null} onChange={(date, dateString: any) => handleDateChange(dateString)} style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: "2%" }} placeholder='Due Date' />
							</Form.Item>

						</Form>

					</div>
					<div className='right-grid'>

						<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
							<Form.Item
								name="taxamount"
								messageVariables={{ name: 'taxamount' }}
								className='form_item two'
								rules={[{ required: true }]}
							>
								{taxamount.error && <span className="gst-err-msg">{taxamount.error}</span>}
								<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Tax Amount</span>
								<Input type='text'
									name="taxamount"
									value={taxamount.value}
									onChange={handleChange}
									onBlur={handleChange}
									style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%' }} />
							</Form.Item>

							<Form.Item
								name="refid"
								messageVariables={{ name: 'refid' }}
								label="Ref Id"
								className='form_item two'
							>
								{refid.error && <span className="gst-err-msg">{refid.error}</span>}
								<Input type='text'
									name="refid"
									value={refid.value}
									onChange={handleChange}
									onBlur={handleChange}
									style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
							</Form.Item>

							<Form.Item
								name="caseid"
								messageVariables={{ name: 'caseid' }}
								label="Case Id"
								className='form_item two'
							>
								{caseid.error && <span className="gst-err-msg">{caseid.error}</span>}
								<Input type='text'
									name="caseid"
									value={caseid.value}
									onChange={handleChange}
									onBlur={handleChange}
									style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
							</Form.Item>
							<Form.Item
								name="section"
								messageVariables={{ name: 'section' }}
								label="Section"
								className='form_item two'
							>
								{section.error && <span className="gst-err-msg">{section.error}</span>}
								<Input type='text'
									name="section"
									value={section.value}
									onChange={handleChange}
									onBlur={handleChange}
									style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
							</Form.Item>

							{<div style={{ paddingTop: 10 }}>
								<input
									ref={inputFileElement}
									onChange={handleselectedFile}
									multiple
									type="file"
									style={{ display: 'none' }} />
								<span
									onClick={() => inputFileElement.current.click()}
									className='attach_file_line'
									style={{
										color: '#4472C4',
										fontSize: '1.8vmin',
										fontWeight: 400,
										textDecoration: 'underline',
										cursor: 'pointer'
									}}>
									<img
										src={attachIcon}
										className='attach_file_img'
										style={{
											marginRight: '5px'
										}}
										height={'13vmin'}
										alt='attach' />
									Attachments
								</span>
								<div
									style={{
										display: 'flex',
										// marginTop: '10px',
										marginBottom: '10px',
										width: '100%',
										flexWrap: 'wrap'
									}}>
									{selectedFiles.map((file: any,
										index: any) =>
										<span
											key={file.name}
											style={{
												width: '50%',
												padding: '1% 2%',
												backgroundColor: '#FCE7D9',
												border: '0.5px solid #E96919',
												marginRight: '1%',
												marginBottom: '1%',
												justifyContent: 'space-between',
												wordBreak: 'break-all',
												borderRadius: '5px',
												display: 'flex',
												alignItems: 'center'
											}}>{file.docName || file.name}
											{file.name && <img
												alt="close-icon"
												onClick={() => removeFile(index)}
												src={closeIcon}
												style={{
													marginLeft: '5px',
													cursor: 'pointer'
												}}
												height={'10vmin'} />}
										</span>)
									}
								</div>
							</div>}

						</Form>
						<span style={{ fontSize: '2vmin ', color: '#000' }}>Case Type</span>
						<TextField
							fullWidth
							focused
							select
							SelectProps={{ native: true, }}
							variant='outlined'
							name="casetype"
							value={casetype.value}
							onBlur={handleChange}
							onChange={handleChange}
							autoComplete='off'
							style={{ margin: '2% 0' }}
						>
							<option value='' disabled>...</option>
							{masterData.caseType && masterData.caseType.map((type: any) =>
								<option key={type} value={type}>{type}</option>
							)}
						</TextField>
						<span style={{ fontSize: '2vmin ', color: '#000' }}>Status</span>
						<TextField
							fullWidth
							focused
							select
							SelectProps={{ native: true, }}
							variant='outlined'
							name="casestatus"
							// value={typeOfSolution.value}
							onBlur={handleChange}
							onChange={handleChange}
							defaultValue='Action to be taken'
							autoComplete='off'
							style={{ margin: '2% 0' }}
						>
							<option value='' disabled>...</option>
							{TypeOfSolution.map((type: any) =>
								<option key={type.id} value={type.value}>{type.value}</option>
							)}
						</TextField>



					</div>
				</div>
				<Form.Item className='form_item' style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '16px' }} >
					<Button
						htmlType="submit"
						id='popup-submit-btn'
						className='create-popup-btn'
						// disabled={!gstin.value || !tax_period.value || !taxamount.value || !duedate || !noticedate || isError()}
						loading={loading}
						style={{
							background: "#424B70",
							color: "white",
							border: "none",
							width: "20vw",
							height: "6vh",
							fontSize: '2.3vmin'
						}}
						onClick={() => { handlemanualfileupload() }}>
						{!loading && <>Submit</>}
						{loading && <>Loading...</>}
					</Button>
				</Form.Item>
			</Modal >

			<Modal
				title="Create task manually"
				onCancel={() => { setModal3Open(false); handleInitial(); setSelectedFiles([]) }}
				width={'35%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={modal3Open}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<span className='type-field-lable' style={{ fontSize: '2vmin ' }}>Type of Solution Sought</span>
				<TextField
					fullWidth
					focused
					select
					SelectProps={{ native: true, }}
					variant='outlined'
					name="typeOfSolution"
					value={typeOfSolution.value}
					onBlur={handleChange}
					onChange={handleChange}
					defaultValue='Reply to Notice'
					autoComplete='off'
					style={{ margin: '2% 0' }}
				>
					<option value='' disabled>...</option>
					{TypeOfSolution.map((type: any) =>
						<option key={type.id} value={type.value}>{type.value}</option>
					)}
				</TextField>
				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<Form.Item
						name="ticDescription"
						label="Description"
						style={{ minHeight: 200 }}
						className='form_item one'
					>
						{ticDescription.error && <span className="gst-err-msg">{ticDescription.error}</span>}
						<div style={{ border: "1px solid #00000021", padding: 10 }}>
							<TextArea
								maxLength={100}
								style={{
									height: 120,
									resize: 'none',
									padding: 0,
									border: "none"
								}}
								bordered={false}
								name="ticDescription"
								value={ticDescription.value}
								onChange={handleChange}
								onBlur={handleChange}
							/>
							<hr />
							<input
								ref={inputFileElement}
								onChange={handleselectedFile}
								multiple
								type="file"
								style={{ display: 'none' }} />
							<span
								onClick={() => inputFileElement.current.click()}
								className='attach_file_line'
								style={{
									color: '#4472C4',
									fontSize: '1.8vmin',
									fontWeight: 400,
									textDecoration: 'underline',
									cursor: 'pointer'
								}}>
								<img
									src={attachIcon}
									className='attach_file_img'
									style={{
										marginRight: '5px'
									}}
									height={'13vmin'}
									alt='attach' />
								Attach files (Optional)
							</span>
							<div
								style={{
									display: 'flex',
									marginTop: '10px',
									width: '100%',
									flexWrap: 'wrap'
								}}>
								{selectedFiles.map((file: any,
									index: any) =>
									<span
										key={file.name}
										style={{
											width: '27.9%',
											padding: '1% 2%',
											backgroundColor: '#FCE7D9',
											border: '0.5px solid #E96919',
											marginRight: '1%',
											marginBottom: '1%',
											justifyContent: 'space-between',
											wordBreak: 'break-all',
											borderRadius: '5px',
											display: 'flex',
											alignItems: 'center'
										}}>{file.name}
										<img
											alt="close-icon"
											onClick={() => removeFile(index)}
											src={closeIcon}
											style={{
												marginLeft: '5px',
												cursor: 'pointer'
											}}
											height={'10vmin'} />
									</span>)}
							</div>
						</div>
					</Form.Item>
					<Form.Item
						name="ticContactNumber"
						messageVariables={{ name: 'name' }}
						label="Contact mobile num"
						className='form_item two'
					>
						{ticContactNumber.error && <span className="gst-err-msg">{ticContactNumber.error}</span>}
						<Input type='text'
							name="ticContactNumber"
							value={ticContactNumber.value}
							onChange={handleChange}
							onBlur={handleChange}
							style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
					</Form.Item>
					<Form.Item className='form_item'>
						<Button
							htmlType="submit"
							id='popup-submit-btn'
							className='create-popup-btn'
							disabled={isError()}
							loading={loading}
							style={{
								background: "#424B70",
								color: "white",
								border: "none",
								width: "70%",
								height: "6vh",
								marginLeft: '15%',
								fontSize: '2.3vmin'
							}}
							onClick={() => createTaskManually()}>
							{!loading && <>Create Task</>}
							{loading && <>Loading...</>}
						</Button>
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				title={"Sync Report"}
				onCancel={() => setreportModal(false)}
				width={'40%'}
				className='sync-report-popup'
				centered
				style={{ color: "#031742" }}
				open={reportmodal}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div className='report-wrap'>
					{syncDetails ?
						<>
							<div className='report-title'>
								<h3>GSTIN Processed count</h3>
								<h3>First time sync</h3>
								<h3>Total notices fetched</h3>
								<h3>Successfully synced</h3>
								<h3>Sync failed</h3>
								<h3>Sync start time</h3>
								{syncDetails.sync_end && <h3>Sync end time</h3>}
							</div>
							<div className='report-cnt'>
								<h3>: {syncDetails.gst_list.length}</h3>
								<h3>: {syncDetails.gst_list.filter((sd: any) => sd.is_first_time).length}</h3>
								<h3>: {syncDetails.gst_list.map((obj: any) => obj.notice_count).reduce((accumulator: any, current: any) => accumulator + current, 0)}</h3>
								<h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && !sd.is_sync_failed).length}</h3>
								<h3>: {syncDetails.gst_list.filter((sd: any) => sd.sync_end != null && sd.is_sync_failed).length}</h3>
								<h3>: {new Date(syncDetails.created_at).toDateString() + ' ' + new Date(syncDetails.created_at).toLocaleTimeString()}</h3>
								{syncDetails.sync_end && <h3>: {syncDetails.sync_end ? new Date(syncDetails.sync_end).toDateString() + ' ' + new Date(syncDetails.sync_end).toLocaleTimeString() : '--'}</h3>}
							</div>
						</> :
						<Spin spinning={syncloading} size='default' style={{ maxWidth: '100%', display: 'flex', position: 'absolute' }}>
						</Spin>
					}
				</div>

				<p><b>Note:</b> Incorrect password is not at all considered.</p>
			</Modal>
			<Modal
				title={"Change Password"}
				onCancel={handleClose}
				width={'33%'}
				className='change_pass_container'
				centered
				style={{ color: "#031742" }}
				open={passwordOpen}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}>
				<div>
					<Box sx={{ width: '100%' }}>
						<div>
							<React.Fragment>
								<div>
									<div className="fields">
										<div className="row">
											{userData && userData.password_status ?
												<div className="col-sm-12 input-group" style={{ marginTop: '1.2rem' }}>
													<label className="label">Current Password</label>
													<div className="input-box" >
														<input
															value={Password.current_password}
															name="current_password"
															onChange={HandleChange}
															type={password_type.currentPasswordType}
															placeholder="Enter current password"
														/>
														{password_type.currentPasswordType === 'password' ?
															<VisibilityOutlinedIcon
																onClick={() => togglePasswordVisibility('currentPasswordType')}
																className='eye_icon'
															/>
															:
															showEyeIcon.showCurrentEyeIcon ?
																<VisibilityOffOutlinedIcon
																	onClick={() => togglePasswordVisibility('currentPasswordType')}
																	className='eye_icon'
																/>
																: null
														}
													</div>
												</div> :
												<>
													<label className="label">OTP</label>
													<div className="col-sm-12 otp-group" style={{ paddingBottom: err_otp ? '0%' : '' }}>
														<div className='otp-cover'>
															{OTP.map((digit, index) => (
																<ThemeProvider theme={inputtheme}>
																	<OutlinedInput
																		className='otp_input'
																		key={index}
																		id={`otp-input-${index}`}
																		autoComplete='off'
																		value={digit}
																		onChange={(e: any) => handleChangeotp(e.target.value, index)}
																		onKeyDown={(e: any) => handleKeyDown(e, index)}
																		inputProps={{
																			maxLength: 1,
																			style: { textAlign: 'center' }
																		}}
																	/>
																</ThemeProvider>
															))}

														</div>
														<FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_otp}</FormHelperText>
													</div>
												</>
											}

											<div className="col-sm-12 input-group" style={{ marginTop: userData && userData.password_status ? '1.2rem' : '0' }}>
												<label className="label">New Password</label>
												<div className="input-box" >
													<input
														value={Password.new_password}
														name="new_password"
														onChange={HandleChange}
														type={password_type.newPasswordType}
														placeholder="Enter new password"
													/>
													{password_type.newPasswordType === 'password' ?
														<VisibilityOutlinedIcon
															onClick={() => togglePasswordVisibility('newPasswordType')}
															className='eye_icon'
														/>
														:
														showEyeIcon.showNewEyeIcon ?
															<VisibilityOffOutlinedIcon
																onClick={() => togglePasswordVisibility('newPasswordType')}
																className='eye_icon'
															/>
															: null
													}
												</div>
											</div>

											<div className="col-sm-12 input-group" style={{ marginTop: '1.2rem' }}>
												<label className="label">Confirm Password</label>
												<div className="input-box">
													<input
														value={Password.confirm_password}
														name="confirm_password"
														onChange={HandleChange}
														type={password_type.confirmPasswordType}
														placeholder="Confirm new password"
													/>
												</div>
											</div>

											<div className='err-msg'>
												{userData && userData.password_status && Password.current_password === Password.new_password &&
													<div>
														<><span><img src={closeIcon} alt='info'></img></span></>
														<span style={{ color: '#E10000', marginLeft: '1%' }}>
															Current password and New password should not be same
														</span>
													</div>
												}
												{isSubmitDisabled &&
													<div>
														<><span><img src={closeIcon} alt='info'></img></span></>
														<span style={{ color: '#E10000', marginLeft: '1%' }}>
															New password and Confirm password does not match
														</span>
													</div>
												}
												{(!min_char || !new_min_char) &&
													<div>
														<span><img src={closeIcon} alt='info'></img></span>
														<span style={{ color: '#E10000', marginLeft: '1%' }}>
															Minimum of 7 character
														</span>
													</div>
												}
												{(!upper_lower || !new_upper_lower) &&
													<div>
														<span><img src={closeIcon} alt='info'></img></span>
														<span style={{ color: '#E10000', marginLeft: '1%' }}>Must contain 1 uppercase and 1 lowercase letter</span>
													</div>
												}
												{(!number_symbol || !new_number_symbol) &&
													<div>
														<span><img src={closeIcon} alt='info'></img></span>
														<span style={{ color: '#E10000', marginLeft: '1%' }}>Must contain 1 number and 1 symbol</span>
													</div>
												}
											</div>

											<div className="col-sm-12 input-group " id="last-one" >
												<button
													className="register"
													disabled={(userData && userData.password_status && !Password.current_password) || !Password.new_password || isSubmitDisabled || !number_symbol || !upper_lower || !min_char}
													onClick={userData && userData.password_status ? updateUser : passwordverify}>
													{!loading ? 'Update' : 'Loading...'}
												</button>
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>

						</div>
					</Box>
				</div>
			</Modal>
			<Modal
				title={"Confirmation"}
				onCancel={() => setOpenDialog(false)}
				width={'27%'}
				className='confirm_modal'
				centered
				style={{ color: "#031742" }}
				open={openDialog}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div className='confirm_cover_wrap'>
					<p className="confirm-msg">Are you sure want to change role ?</p>
					<div>
						<button
							className='confirm-btn no-btn'
							onClick={handlechangetype}>Yes</button>
						<button
							className="confirm-btn"
							onClick={handlecanceltype}>No</button>
					</div>
				</div>
			</Modal>

			<div id="gst-header">
				<div className="head-top">
					<div className="nav-button-box">
						<img src={Logo} alt='logo' style={{ height: "70%", margin: "0%", marginRight: '3%', cursor: 'pointer' }} onClick={() => { navigate(userRole === 'PH Partner' && phlogin ? '/home/phdashboard' : '/home/gst-dashboard'); menuclick(); setSelectgst(null) }} />
						{nav_btns
							.filter((button: any) => {
								// Check if userRole is 'PH Partner' and phlogin is true
								if (userRole === 'PH Partner' && phlogin) {
									// Exclude 'GST DASHBOARD' and 'TICKETS' buttons
									return button.name !== 'NOTICES' && button.name !== 'SERVICES';
								}
								// If the user is not a 'PH Partner' or phlogin is false, include all buttons
								return true;
							})
							.map((button: any, index: number) => (
								<Dropdown overlay={renderMenu(button.submenu, index)} key={button.name}>
									<Button
										// key={button.name}
										onClick={(e) => { button.submenu ? e.preventDefault() : menuclick(button) }}
										// onClick={() => menuclick(button)}
										className={`menu_btn ${hoveredButton === index ? 'hovered' : ''}`} // Apply hover class only to the current hovered button
										onMouseEnter={() => setHoveredButton(index)} // Set hover state when button is hovered
										onMouseLeave={() => setHoveredButton(null)} // Remove hover state when button is not hovered
									>
										<img src={button.icon} style={{ height: 18 }} alt='' />
										{button.name}
										{button.submenu && <KeyboardArrowDownRoundedIcon />}
									</Button>
								</Dropdown>
							))}

					</div>
					<div className='profile_box'>
						<div className="profile-content">
							<Dropdown overlay={renderAddMenu(add_opt_submenu, 0)} >
								<Button className='gst_add_btn'>
									<img src={plusIcon} alt='plus icon' />
								</Button>
							</Dropdown>
							{progres.data.label === 'Upload' && progres.data.loading && < img src={gifIcon} alt="GIF" style={{ height: '25px', marginRight: '6%' }} />}
							<Tooltip placement="bottom" title={progres.data.loading ? 'Sync Progressing' : 'Sync all'} arrow={true} color={'#52c41a'} >
								<AutorenewIcon style={{ color: '#52c41a', cursor: 'pointer' }} className={progres.data.label !== 'Upload' && progres.data.loading ? 'Progress-icon' : ''} onClick={() => progres.data.loading ? popupFunc(progres.data.label) : synccallfunction([])} />
							</Tooltip>
							{anchorEl ?
								<Badge badgeContent={0} color="success" overlap="circular" className='notif_badge'>
									<NotificationsSharpIcon className='notif_icon' onClick={(event: any) => { setAnchorEl(event.currentTarget) }} />
								</Badge> :
								<Badge badgeContent={0} color="success" overlap="circular" className='notif_badge'>
									<NotificationsNoneSharpIcon className='notif_icon' onClick={(event: any) => { setAnchorEl(event.currentTarget) }} />
								</Badge>
							}
							<Avatar className='avatar' onClick={() => setOptOpen(true)} > {userData ? userData.user_name.charAt(0) : ''}</Avatar>
						</div>

						{optOpen && <div className='drop_options' ref={optionsRef}>
							{userRole === 'Super Admin' && <div className='optionss' >
								<span>TC-Balance <b>${captchacnt}</b></span>
							</div>}
							{userData.is_cf === true && !phlogin &&
								<div className='optionss code' >
									<span className='refer-line'>Refer Code <b>{userData.cf_code ? userData.cf_code : '-'}</b></span>
									<ContentCopyIcon fontSize='small' className='clipboard-icon' onClick={() => { handleCopyClick(userData.cf_code) }} />
								</div>
							}
							{userData.ph_code && phlogin &&
								<div className='optionss code' >
									<span className='refer-line'>Ph Partner Code <b>{userData.ph_code ? userData.ph_code : '-'}</b></span>
									<ContentCopyIcon fontSize='small' className='clipboard-icon' onClick={() => { handleCopyClick(userData.ph_code) }} />
								</div>
							}
							{(userRole === 'PH Partner' && userData.is_cf === true) && <div className='type_option' onClick={() => { }}>
								<TextField
									className='type_selection'
									fullWidth
									focused
									select
									SelectProps={{ native: true, }}
									variant='standard'
									name="usertype"
									value={usertype}
									onChange={handlechange}
									autoComplete='off'
									InputProps={{
										disableUnderline: true, // Disables the underline
									}}
								>
									{userType.map((type: any) =>
										<option style={{ textAlign: 'center' }} key={type} value={type}>{type}</option>
									)}
								</TextField>
							</div>}
							{(userData.is_cf === true && !phlogin || userRole === 'Super Admin') && <div className='optionss' onClick={() => { navigate('/home/users'); setBreadcrumbPath('/home/users'); setOptOpen(!optOpen); setFilterQuery({}); setPage(1); setTotalpages(0); setIsManagegst(false); setSumdrawer(false); setRowsPerPage(10) }}>
								<span><img src={UsersIcon} alt='userIcon' /> Users</span>
							</div>}
							{userRole !== 'Super Admin' && userRole !== 'PH Partner' && <div className='optionss' onClick={() => { navigate('/home/syncmonitoring'); setBreadcrumbPath('/home/syncmonitoring'); setOptOpen(!optOpen); setFilterQuery({}); setPage(1); setTotalpages(0); setIsManagegst(false); setSumdrawer(false); setRowsPerPage(10) }}>
								<span>Sync Monitoring</span>
							</div>}
							{userRole !== 'Super Admin' && <div className='optionss' onClick={() => { openPopup('profile'); setOptOpen(!optOpen); setSumdrawer(false); setRowsPerPage(10) }}>
								<span><img src={ProfileIcon} alt='ProfileIcon' /> Profile</span>
							</div>}
							{userRole !== 'Super Admin' && <div className='optionss' onClick={() => { { userData && userData.password_status ? openPopup('password') : handlerequestotp() } setOptOpen(!optOpen); setSumdrawer(false); setRowsPerPage(10) }}>
								<span><img src={changepassIcon} alt='changepassIcon' /> Change Password</span>
							</div>}

							<div className='optionss' onClick={() => { openPopup('logout'); setOptOpen(!optOpen) }}>
								<span><img src={LogoutIcon} alt='LogoutIcon' /> Logout</span>
							</div>
						</div>}
					</div>
				</div >
			</div >
			<div id='mob-gst-header'>
				<div className='logo_box'>
					<img src={Logo} alt='logo' style={{ height: "70%", margin: "1% 0 0 3%", cursor: 'pointer' }} onClick={() => navigate('/home/gst-dashboard')} />
				</div>
				<MenuIcon className='header-menu' onClick={toggleDrawer('right', true)} />
			</div>
			<Drawer
				anchor={'right'}
				open={State['right']}
				onClose={toggleDrawer('right', false)}
			>
				{list('right')}
			</Drawer>
			<UserProfile open={profileOpen} setOpen={setProfileOpen} refresh={refreshUserData}></UserProfile>
			<UserLogout openLogout={logoutOpen} setLogoutOpen={setLogoutOpen}></UserLogout>
			<Notification anchorEl={anchorEl} setAnchorEl={setAnchorEl}></Notification>
			<NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
		</>

	)
}

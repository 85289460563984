// react 
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
// ..........

// scss file
import './set-password.scss'
// ..........

// package components
import { IconButton, InputAdornment, OutlinedInput, ThemeProvider, createTheme } from '@mui/material';
// ..........

// child components
import { serviceConfig } from "../../../core/services";
import { NotifyAlert } from '../../../components/notify_alert';
// ..........

// icons
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import password from '../../../assets/register/password.png';
import trueIcon from '../../../assets/register/true.png';
import closeIcon from '../../../assets/register/close.png';
// ..........

export const SetPassword = () => {

    // form values states
    const [new_password, setPassword] = useState('')
    const [confirm_password, setConfirmPassword] = useState('')
    const [err_password, setErrPassword] = useState('')
    const [err_confirm_password, setErrConfirmPassword] = useState('')
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>();
    const [notifyMessage, setNotifyMessage] = useState('')
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // data states
    const [min_char, setMinChar] = useState(false)
    const [upper_lower, setUpperLower] = useState(false)
    const [number_symbol, setNumberSymbol] = useState(false)
    const [password_match, setPasswordMatch] = useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    // ..........

    // reference states
    const navigate = useNavigate();
    const location = useLocation();
    // ..........

    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                setNewPassword()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [new_password, confirm_password]);

    // password field typechange icon function
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    // ..........

    // form value validation function
    const setNewPassword = (e?: any) => {
        if (!new_password || !confirm_password) {
            !new_password && setErrPassword('Please enter password!')
            !confirm_password && setErrConfirmPassword('Please enter confirm password!')
        } else if (min_char && upper_lower && number_symbol && password_match) {
            if (!loading) {
                setLoading(true)
                const user_token = new URLSearchParams(location.search).get('token');
                if (new_password === confirm_password) {
                    serviceConfig.post('set_password', true, { token: user_token, password: confirm_password }, null).then((data: any) => {
                        setPassword('')
                        setConfirmPassword('')
                        setLoading(false)
                        navigate('/pwd-success')
                    },
                        (err: any) => {
                            console.log("res err", err.response);
                            setNotifyType("error");
                            setNotifyMessage(err.message);
                            setNotifyOpen(true);
                            setLoading(false)
                        })
                }
            }
        }
    }

    const handlePasswordChange = (e: any) => {
        const pwd = e.target.value;
        setPassword(pwd);
        if (pwd) {
            pwd && setErrPassword('')

            if (pwd.match(/^.{7,}$/)) {
                setMinChar(true);
            } else {
                setMinChar(false);
            }

            if (pwd.match(/^(?=.*[a-z])(?=.*[A-Z]).+$/)) {
                setUpperLower(true)
            } else {
                setUpperLower(false);
            }

            if (pwd.match(/^(?=.*\d)(?=.*[@#$%^&!*]).+$/)) {
                setNumberSymbol(true)
            } else {
                setNumberSymbol(false)
            }

            if (pwd && confirm_password !== pwd) {
                setPasswordMatch(false)
            } else {
                setPasswordMatch(true)
            }
        } else {
            setErrPassword('Please enter password!')
        }
    }

    const handleConfirmPassChange = (e: any) => {
        const confirmPass = e.target.value;
        setConfirmPassword(confirmPass);
        if (confirmPass) {
            confirmPass && setErrConfirmPassword('')


            if ((confirmPass && new_password !== confirmPass) || (!confirmPass)) {
                setPasswordMatch(false)
            }
            else {
                setPasswordMatch(true)
            }
        } else {
            setErrConfirmPassword('Please enter confirm password!')
        }

    }
    // ..........

    // custom theming function
    const inputtheme = createTheme({
        palette: {
            primary: {
                main: '#9a9999',
            }
        },
    });
    // ..........

    return (
        <>
            <div className="password-container">
                <h4 className='terminal-window-mainline'>Set new password</h4>
                <p className="terminal-window-subline">Create a strong password & secure your account.</p>
                <div className="fields">
                    <div className="row">
                        {/* <form onSubmit={setNewPassword}> */}
                        <div className="col-sm-12 input-group">
                            <label className="input-label">Enter New Password</label>
                            <span className='err_span input-error-line' id="error-line">{err_password}</span>
                            <div className="input-box" style={{ border: 'none' }}>
                                <ThemeProvider theme={inputtheme}>
                                    <OutlinedInput
                                        style={{ width: '100%' }}
                                        className='inputfield'
                                        id="outlined-adornment-weight"
                                        placeholder='Enter your password'
                                        value={new_password}
                                        type={showPassword ? 'text' : 'password'}
                                        onChange={handlePasswordChange}
                                        startAdornment={<img className="input-icon" src={password} alt='user_icon' />}
                                        endAdornment={<InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                    />
                                </ThemeProvider>
                                {/* <img className="input-icon" src={password} alt="user_icon"></img>
                                <input className='inputfield' value={new_password} onBlur={handlePasswordChange} onChange={handlePasswordChange} type={password_type} placeholder="Enter new password"></input>
                                {password_type === 'password' ? <VisibilityOutlinedIcon onClick={() => setPasswordType('text')} className='input-end-icon' /> : <VisibilityOffOutlinedIcon onClick={() => setPasswordType('password')} className='input-end-icon' />} */}
                                <div className='err-msg'>
                                    <div className="err-container"> {min_char ? (<><span><img src={trueIcon} alt="user_icon"></img></span></>) : (<><span><img src={closeIcon} alt="user_icon"></img></span></>)} <span style={{ color: min_char ? 'green' : '#E10000', marginLeft: '1%' }}>Minimum of 7 character</span></div>
                                    <div className="err-container"> {upper_lower ? (<><span><img src={trueIcon} alt="user_icon"></img></span></>) : (<><span><img src={closeIcon} alt="user_icon"></img></span></>)} <span style={{ color: upper_lower ? 'green' : '#E10000', marginLeft: '1%' }}>Must contain 1 uppercase and 1 lowercase letter</span></div>
                                    <div className="err-container"> {number_symbol ? (<><span><img src={trueIcon} alt="user_icon"></img></span></>) : (<><span><img src={closeIcon} alt="user_icon"></img></span></>)} <span style={{ color: number_symbol ? 'green' : '#E10000', marginLeft: '1%' }}>Must contain 1 number and 1 symbol</span></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 input-group second-input" style={{ marginTop: '8rem' }}>
                            <label className="input-label">Confirm Password</label>
                            <span className='err_span input-error-line' id="error-line">{err_confirm_password}</span>
                            <div className="input-box" style={{ border: 'none' }}>
                                <ThemeProvider theme={inputtheme}>
                                    <OutlinedInput
                                        style={{ width: '100%' }}
                                        className='inputfield'
                                        id="outlined-adornment-weight"
                                        placeholder='Enter confirm password'
                                        value={confirm_password}
                                        type={showPassword1 ? 'text' : 'password'}
                                        onChange={handleConfirmPassChange}
                                        startAdornment={<img className="input-icon" src={password} alt='user_icon' />}
                                        endAdornment={<InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword1}
                                                onMouseDown={handleMouseDownPassword1}
                                                edge="end"
                                            >
                                                {showPassword1 ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                    />
                                </ThemeProvider>
                                {/* <img className="input-icon" src={password} alt="user_icon"></img> */}
                                {/* <input className='inputfield' value={confirm_password} onBlur={handleConfirmPassChange} onChange={handleConfirmPassChange} type={confirm_password_type} placeholder="Enter confirm password"></input>
                                {confirm_password_type === 'password' ? <VisibilityOutlinedIcon onClick={() => setConfirmPasswordType('text')} className='input-end-icon' /> : <VisibilityOffOutlinedIcon onClick={() => setConfirmPasswordType('password')} className='input-end-icon' />} */}
                                {confirm_password && <div className="err-container" style={{ marginTop: '1%' }}><> {password_match ? (<><span className="err-msg-span"><img src={trueIcon} alt="user_icon"></img></span><span className="err-msg-span" style={{ color: 'green', marginLeft: '1%' }}>Passwords match</span></>) : (<><span className="err-msg-span"><img src={closeIcon} alt="user_icon"></img></span><span style={{ color: '#E10000', marginLeft: '1%' }} className='err-msg-span'>Passwords do not match</span></>)}</></div>}
                            </div>
                        </div>

                        <div className="col-sm-12 input-group set-btn" style={{ marginTop: confirm_password ? '3.5rem' : '2rem' }}>
                            <button className="teminal-primary-btn" onClick={setNewPassword}>{loading ? 'Loading...' : 'Set Password'}</button>
                        </div>
                        {/* </form> */}
                    </div>
                </div>

                <div className="col-sm-12 login-link" style={{ textAlign: 'center', marginTop: '0', paddingBottom: '3%' }}>
                    <Link className="terminal-link" to='../'>Back to Login</Link>
                </div>
            </div>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}
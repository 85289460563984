// react 
import React from 'react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Link, useNavigate } from 'react-router-dom'
// ..........

// scss file
import './gstDashboard.scss'
// ..........

// package components
import type { CalendarProps } from 'antd'
import { DatePicker as AntDatePicker, Button, Calendar, Checkbox, Form, Input, Modal, Radio, RadioChangeEvent, Select, Spin, Tag, Tooltip } from 'antd'
import Table from 'antd/es/table'
import dayjs, { Dayjs } from 'dayjs'
import axios from 'axios'
import { TextField } from '@mui/material'
import moment from 'moment'
// ..........

// child components
import { useGlobalState } from '../../Statecontext'
import Chart from '../../components/chart/index'
import { NotifyAlert } from '../../components/notify_alert'
import { ZTable2 } from '../../components/table2'
import { serviceConfig } from '../../core/services'
import { AppContext } from '../../core/utils/Context'
import { Utils } from '../../core/utils/utils'
// ..........

// icons
import attachIcon from '../../assets/images/attach_icon.svg'
import arrowRightIcon from '../../assets/images/chevron-right.svg'
import expandIcon from '../../assets/images/expandIcon.svg'
import expandIcon1 from '../../assets/images/expandIcon2.svg'
import filterIcon from '../../assets/images/filter.svg'
import active_filtericon from '../../assets/images/filter_active_icon.svg'
import gstIcon from '../../assets/images/gstin managed.svg'
import incorrectPasswordIcon from '../../assets/images/incorrect password.svg'
import noticeIcon from '../../assets/images/total notice count.svg'
import closeIcon from '../../assets/register/close.png'
import editIcon from '../../assets/images/editIcon.svg'
// ..........

interface CaseData {
	id: string;
	case_type_name: string;
	case_ref_id: string[];
	case_arn_no: string;
	case_description: string[];
	// Add other fields as necessary
}

interface GST {
	gst_no: string;
	trade_name: string;
	state: string;
	// Add other fields as necessary
}

interface ApiResponse {
	id: string;
	gst: GST;
	notice_status: string;
	notice_item_id: string | null;
	issue_date: string;
	due_date: string;
	case_data: CaseData;
	// Add other fields as necessary
	state: string;
	tax_amount: number;
	tax_period: string;
	attachments: any;
	// ...other fields
}

interface FormValues {
	portal_status: string;
	state: string;
	trade_name: string;
	gst_no: string;
	gstin_input: string;
	tax_period: string;
	refid: string;
	caseid: string;
	casetype: string;
	section: string;
	taxamount: number;
	// Add other form values as necessary
}

export const GstDashboard = () => {

	// form values states
	const [userRole, setUserRole] = useState<any>()
	const [duedate, setDuedate] = useState<any>(null)
	const [noticedate, setNoticedate] = useState<any>(null)
	const [tax_startdate, setStartdate] = useState<any>('')
	const [tax_enddate, setEnddate] = useState<any>('')
	const [States, setStates] = useState([]);
	const [case_type, setCasetype] = useState([]);
	const [Gst_in, setGstin] = useState([])
	const [gstid, setGstid] = useState('')
	const [noticeid, setNoticeid] = useState('')
	const [Trade_name, setTradename] = useState('')
	const [selectedstate, setSelectedState] = useState('')
	const [managegstdetails, setManagegstdetails] = useState([])
	const [fileurl, setFileurl] = useState([])
	const [overDueNotices, setOverDueNotices] = useState<any>()
	const [pendingNotices, setPendingNotices] = useState<any>()
	const [noticeObj, setNoticeObj] = useState(false);
	const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>(undefined);
	const [calendardata, setCalendarData] = useState<any>([])
	const [lastcalltab, setLastcalltab] = useState<any>('')
	const [actionType, setActionType] = useState("add")
	const [editId, setEditId] = useState('')
	// ..........

	// alerts and modal states
	const [notifyOpen, setNotifyOpen] = useState(false)
	const [notifyType, setNotifyType] = useState<any>()
	const [notifyMessage, setNotifyMessage] = useState('')
	var [tableHeader, setTableHeader] = useState<any>()
	const [noticeStatus, setNoticeStatus] = useState('Action to be taken');
	const [selectedOrgFiles, setSelectedOrgFiles] = useState<any>()
	const [selectedFiles, setSelectedFiles] = useState<any>([])
	const [sourcefiles, setSourceFiles] = useState([])
	const inputFileElement = useRef<any>();
	const { formValues, handleChange, handleInitial } = useContext(AppContext);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const { casestatus, state, trade_name, gstin, gstin_input, tax_period, refid, caseid, casetype, section, taxamount } = formValues;
	// ..........

	// loading state
	const [loading, setLoading] = useState(false)
	const [calendarloading, setCalendarloading] = useState(false)
	// ..........

	// limit & filter states
	const gstTableElement = useRef<any>();
	const [fromDueDate, setFromDueDate] = useState<any>('')
	const [toDueDate, setToDueDate] = useState<any>('')
	const [fromTaxAmount, setFromTaxAmount] = useState<any>('')
	const [amountOperator, setAmountOperator] = useState<any>('eq')
	const [filter, setFilter] = useState(false)
	const [caseIdFilter, setCaseIdFilter] = useState<any>([])
	const [secondaryFilter, setSecondaryFilter] = useState<any>([])
	const [secFilterCase, setSecFilterCase] = useState('')
	const [checkedArnItems, setCheckedArnItems] = useState<string[]>([])
	const [checkedCaseIdItems, setCheckedCaseIdItems] = useState<string[]>([])
	const [checkedSessionItems, setCheckedSessionItems] = useState<string[]>([])
	const [checkedItems, setCheckedItems] = useState<string[]>([]);
	const [arnFilter, setArnFilter] = useState<any>([])
	const [sessionFilter, setSessionFilter] = useState<any>([])
	const { setManageactive, setIsManagegst, expanded, setExpanded, masterData, setMasterData, overviewDetails, filterQuery, setFilterQuery, setNoticeQuery, sorterQuery, searchquery, setSearchquery, setSorterQuery, chartquery, setChartquery, gstSyncModal, setGstSyncModal, setTabview, totalpages, setTotalpages, page, setPage, setRowsPerPage, tabactive, setTabactive, sumpopup, setSumdrawer, drawerloading, setDrawerloading, rowsPerPage, statuschange, setStatuschange, selectedGstRows, setSelectedGstRows, addnotice, setAddnotice, userdata, setNoticedata, setOverviewDetails } = useGlobalState();
	const [secStateFilter, setSecStateFilter] = useState<any>({ state: [], arn_nos: [], case_ids: [], section: [] })
	const [x, setX] = useState(0)
	const [y, setY] = useState(0)
	// ..........

	//  datas states
	const [userTradeMaster, setUserTradeMaster] = useState<any>([])
	const [userGSTMaster, setUserGSTMaster] = useState<any>([])
	const [userStateMaster, setUserStateMaster] = useState<any>([])
	const [gstCounts, setGstCounts] = useState<any>()
	const [noticeData, setNoticeData] = useState<any>([]);
	const [selected, setSelected] = useState('Select Action')
	const [globalSearch, setGlobalSearch] = useState('')
	const TypeOfSolution = [
		{ id: 1, value: 'No action required' },
		{ id: 2, value: 'Action to be taken' },
		{ id: 3, value: 'Action taken' },
	]
	const Tax_period = [
		{
			id: 1,
			value: 'Jul 2017 - Mar 2018',

		},
		{
			id: 2,
			value: 'Apr 2018 - Mar 2019',

		},
		{
			id: 3,
			value: 'Apr 2019 - Mar 2020',

		},
		{
			id: 4,
			value: 'Apr 2020 - Mar 2021',

		},
		{
			id: 5,
			value: 'Apr 2021 - Mar 2022',

		},
		{
			id: 6,
			value: 'Apr 2022 - Mar 2023',

		}
	]
	const settings = ['Sort by Due date', 'Sort by Notice date', 'Sort by Tax period'];
	// ..........

	// reference states
	const { ticket, MultiNoticeUpdate } = Utils();
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const abortControllerRef = useRef<AbortController | null>(null);
	// ..........

	// filter and header function
	var dv = document.createElement("div");
	dv.className = 'secondary_filter'
	dv.style['height'] = '50px';
	dv.style['width'] = '60vw';
	dv.style['background'] = '#ecedf1';
	dv.style['position'] = 'absolute';
	dv.style['zIndex'] = '1000';
	dv.style['display'] = 'flex';
	dv.style['alignItems'] = 'center';
	dv.style['justifyContent'] = 'space-evenly';
	dv.style['padding'] = '0 2%';

	const stateFilterIcon = filterQuery.state && filterQuery.state.length > 0 ? active_filtericon : filterIcon;
	const caseIdFilterIcon = filterQuery.case_ids && filterQuery.case_ids.length > 0 ? active_filtericon : filterIcon;
	const arnFilterIcon = filterQuery.arn_nos && filterQuery.arn_nos.length > 0 ? active_filtericon : filterIcon;
	const sessionFilterIcon = filterQuery.section && filterQuery.section > 0 ? active_filtericon : filterIcon;

	dv.innerHTML = `
	<span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">
        State 
		<img class="filterIconQuery" id="StateFilter" style="margin-left:5%;cursor:pointer;" src=${stateFilterIcon} alt="filter icon" />
    </span>
    <span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">
        Case ID <img class="filterIconQuery" id="CaseidFilter" style="margin-left:5%;cursor:pointer;" src=${caseIdFilterIcon} alt="filter icon" />
    </span>
    <span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">
        Ref ID <img class="filterIconQuery" id="ArnFilter" style="margin-left:5%;cursor:pointer;" src=${arnFilterIcon} alt="filter icon" />
    </span>
    <span style="border-right:2px solid #d7d7d7;color: var(--text-black, #393938);font-family: Inter;font-size: 2vmin;font-weight: 500 !important;width: 12%;display:flex;justifyContent:center;">
        Section <img class="filterIconQuery" id="SessionFilter" style="margin-left:5%;cursor:pointer;" src=${sessionFilterIcon} alt="filter icon" />
    </span>
	`;

	let filConfig: any = {
		"StateFilter": masterData.states,
		"CaseidFilter": masterData.caseIdFilter,
		"ArnFilter": masterData.arnFilter,
		"SessionFilter": masterData.sessionFilter
	}

	const expandHeader = (ev: any) => {
		ev.stopPropagation();
		ev.preventDefault()
		tableHeader = ev.target.parentElement.parentElement.parentElement.parentElement.parentElement
		setTableHeader(tableHeader)
		console.log(filterQuery, 'filter query')
		handlePopupFilter()
	}

	const handlePopupFilter = () => {
		setExpanded(!expanded)
		if (!expanded) {
			tableHeader.appendChild(dv)
			let fi_icons = dv.getElementsByClassName('filterIconQuery')
			for (let ind = 0; ind < fi_icons.length; ind++) {
				fi_icons[ind].addEventListener('click', function (e: any) {
					e.stopPropagation();
					console.log(e)
					setX(e.clientX)
					console.log(e.clientX, e.clientY, e)
					setY(e.clientY)
					setSecondaryFilter(filConfig[e.target.id])
					setSecFilterCase(e.target.id);
					console.log(e.target.id, 'secfilter case')
					setFilter(true)
				});
			}
		} else {
			let fil = tableHeader.getElementsByClassName('secondary_filter')[0]
			tableHeader.removeChild(fil)
		}
	}

	const handleDueDateFilter = () => {
		function dateFormat(date: any) {
			let dates = date.split('T')
			return dates[0]
		}
		let query: any = { due_from_date: dateFormat(fromDueDate.$d.toISOString()), due_to_date: dateFormat(toDueDate.$d.toISOString()) }
		gstTableElement.current.setTableQuery(query)
	}

	const clearDueDateFilter = () => {
		setFromDueDate('')
		setToDueDate('')
		gstTableElement.current.setTableQuery({ due_from_date: '', due_to_date: '' })
	}

	const handleTaxAmountFilter = () => {
		let query: any = { amount_from: fromTaxAmount || 0, amount_oper: amountOperator }
		gstTableElement.current.setTableQuery(query)
	}

	const clearTaxAmountFilter = () => {
		setFromTaxAmount('')
		setAmountOperator('eq')
		gstTableElement.current.setTableQuery({ amount_from: '', amount_to: '' })
	}

	const secFilterChange = () => {
		gstTableElement.current.setTableQuery(secStateFilter)
		handlePopupFilter()
	}


	const handlecheck = (item: string, secFilter: any) => {
		switch (secFilterCase) {
			case 'StateFilter':
				setCheckedItems((prevCheckedItems: any) => {
					if (prevCheckedItems.includes(item)) {
						return prevCheckedItems.filter((checkedItem: any) => checkedItem !== item);
					} else {
						return [...prevCheckedItems, item];
					}
				});
				setSecStateFilter((prevState: any) => ({
					...prevState,
					state: prevState.state.includes(item)
						? prevState.state.filter((newItem: any) => newItem !== item)
						: [...prevState.state, item],
				}));
				break;
			case 'ArnFilter':
				setCheckedArnItems((prevCheckedArnItems: any) => {
					if (prevCheckedArnItems.includes(item)) {
						return prevCheckedArnItems.filter((checkedItem: any) => checkedItem !== item);
					} else {
						return [...prevCheckedArnItems, item];
					}
				});
				setSecStateFilter((prevState: any) => ({
					...prevState,
					arn_nos: prevState.arn_nos.includes(item)
						? prevState.arn_nos.filter((newItem: any) => newItem !== item)
						: [...prevState.arn_nos, item],
				}));
				break;
			case 'CaseidFilter':
				setCheckedCaseIdItems((prevCheckedCaseIdItems: any) => {
					if (prevCheckedCaseIdItems.includes(item)) {
						return prevCheckedCaseIdItems.filter((checkedItem: any) => checkedItem !== item);
					} else {
						return [...prevCheckedCaseIdItems, item];
					}
				});
				setSecStateFilter((prevState: any) => ({
					...prevState,
					case_ids: prevState.case_ids.includes(item)
						? prevState.case_ids.filter((newItem: any) => newItem !== item)
						: [...prevState.case_ids, item],
				}));
				break;
			case 'SessionFilter':
				setCheckedSessionItems((prevCheckedSessionItems: any) => {
					if (prevCheckedSessionItems.includes(item)) {
						return prevCheckedSessionItems.filter((checkedItem: any) => checkedItem !== item);
					} else {
						return [...prevCheckedSessionItems, item];
					}
				});
				setSecStateFilter((prevState: any) => ({
					...prevState,
					section: prevState.section.includes(item)
						? prevState.section.filter((newItem: any) => newItem !== item)
						: [...prevState.section, item],
				}));
				break;
			default:
				break;
		}
	};

	// popup ok function
	const handleOkButtonClick = () => {
		// Prepare updated filterQuery based on secStateFilter
		const updatedFilterQuery = {
			...filterQuery,
			state: secStateFilter.state.length > 0 ? secStateFilter.state : undefined,
			arn_nos: secStateFilter.arn_nos.length > 0 ? secStateFilter.arn_nos : undefined,
			case_ids: secStateFilter.case_ids.length > 0 ? secStateFilter.case_ids : undefined,
			section: secStateFilter.section.length > 0 ? secStateFilter.section : undefined,
		};

		// Set the updated filterQuery state
		setFilterQuery(updatedFilterQuery);

		// Call secFilterChange to apply filters and handle popup
		secFilterChange();
		setFilter(false); // Close the modal or reset UI
	};
	//   .........


	// popup filter cancel button
	const handleCancel = () => {
		setCheckedItems([]);
		setCheckedArnItems([]);
		setCheckedCaseIdItems([]);
		setCheckedSessionItems([]);
		setFilterQuery((prevQuery: any) => {
			const updatedQuery = { ...prevQuery };
			if (secFilterCase === 'StateFilter') {
				delete updatedQuery.state;
			} else if (secFilterCase === 'ArnFilter') {
				delete updatedQuery.arn_nos;
			} else if (secFilterCase === 'CaseidFilter') {
				delete updatedQuery.case_ids;
			} else if (secFilterCase === 'SessionFilter') {
				delete updatedQuery.section;
			}
			return updatedQuery;
		});
		setFilter(false);
		setExpanded(false)
		// Check if tableHeader and secondary_filter element exist
		if (tableHeader && tableHeader.getElementsByClassName('secondary_filter')[0]) {
			tableHeader.removeChild(tableHeader.getElementsByClassName('secondary_filter')[0]);
		}
	};
	//   .........

	// data get function
	const getGSTCounts = () => {
		serviceConfig.get("get_gst_counts", true, null, null).then((data: any) => {
			setGstCounts(data.data)
		}, (err: any) => {
			console.log(err)
		})
	}

	// const getStatusCount = () => {
	// 	serviceConfig.get("get_notices_status_count", true, null, null).then((data: any) => {
	// 		setOverDueNotices(data.data.overdue_notices)
	// 		setPendingNotices(data.data.pending_notices)
	// 	}).then((err: any) => {
	// 	})
	// }

	const loadGNotices = useCallback((search_data?: any) => {
		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		// Create a new AbortController for the new request
		const controller = new AbortController();
		abortControllerRef.current = controller;
		const signal = controller.signal;
		setLoading(true)
		let query: any = {
			by_page: true,
			page: page,
			page_size: rowsPerPage,
		}
		if (searchquery) {
			query['search'] = searchquery;
		}
		if (filterQuery.gst_no) {
			query['gst_no'] = filterQuery.gst_no.join(',')
		}

		if (filterQuery.state && filterQuery.state.length > 0) {
			query['state'] = filterQuery.state.join(',')
		}
		if (filterQuery.trade_name) {
			query['trade_name'] = filterQuery.trade_name.join(',')
		}
		if (filterQuery.tradename) {
			query['trade_name'] = filterQuery.tradename.join(',')
		}
		if (filterQuery.user_name) {
			query['user_name'] = filterQuery.user_name.join(',')
		}
		if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
			query['tax_period'] = filterQuery.tax_period.join(',')
		}
		if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
			query['arn_nos'] = filterQuery.arn_nos.join(',')
		}
		if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
			query['case_ids'] = filterQuery.case_ids.join(',')
		}
		if (filterQuery.section && filterQuery.section.length > 0) {
			query['section'] = filterQuery.section.join(',')
		}
		if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
			query['notice_status'] = filterQuery.notice_status.join(',')
		}
		if (filterQuery.due_day && filterQuery.due_day.length > 0) {
			query['days'] = filterQuery.due_day.join(',')
		}
		if (filterQuery.due_from_date && filterQuery.due_to_date) {
			query['notice_start_due_date'] = filterQuery.due_from_date
			query['notice_end_due_date'] = filterQuery.due_to_date
		}
		if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
			query['notice_start_issue_date'] = filterQuery.notice_from_date
			query['notice_end_issue_date'] = filterQuery.notice_to_date
		}
		if (filterQuery.amount_from && filterQuery.amount_oper) {
			query['tax_amount'] = filterQuery.amount_from
			if (filterQuery.amount_oper) {
				query['tax_amount_op'] = filterQuery.amount_oper
			}
		}
		if (sorterQuery && sorterQuery.columnKey) {
			query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
		}

		if (tabactive === 'Additional' || tabactive === 'Manual') {
			query['master_type'] = tabactive
		} else if (tabactive === 'Latest') {
			query['master_type'] = 'Additional'
		}

		const url = tabactive === 'GSTIN Summary' ? "get_group_notices" :
			tabactive === 'Notices & Orders' ? "get_notices_orders" :
				tabactive === 'Latest' ? "get_latest_notices" : "get_notices";

		serviceConfig.get(url, true, query, null, signal).then((data: any) => {
			if (!signal.aborted) {
				console.log(tabactive)
				console.log(query)
				console.log(url)
				setNoticeData(data.results || data.data || data.data.results)
				setTotalpages(data.count)
				setLoading(false)
			}
		}, (err: any) => {
			if (!signal.aborted) {
				console.log(err)
				setLoading(false)
				if (err.message === "Request failed with status code 401") {
					navigate('/login')
					localStorage.clear()
					window.location.reload();
				} if (err.message === "Network Error") {
					setNotifyOpen(true)
					setNotifyType('error')
					setNotifyMessage('Something Went Wrong!')
					setSorterQuery({})
				}
			}
		})
		return () => {
			controller.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, filterQuery, sorterQuery, searchquery, tabactive, chartquery])


	const loadmanagegstdata = () => {
		serviceConfig.get("manageGst", true, null, null).then((data: any) => {
			setManagegstdetails(data)
			// const names =
			// 	data.filter((item: any) => item.trade_name !== null)
			// 		.map((item: any) => item.trade_name as string);
			// setTradename(names);
			const gsts = data
				.filter((item: any) => item.gst_no !== null)
				.map((item: any) => item.gst_no);
			setGstin(gsts);
		})
			.catch((error: any) => { console.log(error) })
	}

	useEffect(() => {
		getGSTCounts()
		// getStatusCount()
		loadGNotices()
		loadmanagegstdata();
		const storedUserRole = localStorage.getItem('userRole');

		// Parse the storedUserRole if it's not null
		// if (storedUserRole !== 'Super Admin') {
		// 	loadMasterData(); // Call loadMasterData() after setUserRole()
		// }

		// get states list add notice manual
		const getStates = async (country: string) => {
			const url = `https://countriesnow.space/api/v0.1/countries/states/q/?country=${country}`;
			return await axios.get(url);
		};

		getStates('India').then((res: any) => {
			setStates(res.data.data.states)
		})

		return () => {
			if (abortControllerRef.current) {
				abortControllerRef.current.abort();
			}
		};
	}, [loadGNotices, filterQuery])

	useEffect(() => {
		const selectedTrade: any = managegstdetails.find((item: any) => item.gst_no === gstin.value);
		if (selectedTrade) {
			setSelectedState(selectedTrade.state || ''); // Set the state value or empty string if not found
			setTradename(selectedTrade.trade_name || '');
			setGstid(selectedTrade.id || '')
		}
	}, [formValues.gstin])

	useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
		setIsMobile(mediaQuery.matches);

		const handleResize = () => setIsMobile(mediaQuery.matches);

		mediaQuery.addListener(handleResize);

		return () => {
			mediaQuery.removeListener(handleResize);
		};
	}, []);

	useEffect(() => {
		loadcalendardetails()
		getGSTCounts()
	}, [])

	useEffect(() => {
		handleCancel()
		console.log('tab changed')
	}, [tabactive])

	// ...........

	// filter adding from chart and other datas function
	const setqueryvalue = (query: any) => {
		const updatedQuery = {
			...filterQuery,
			gst_no: null,
			tax_amount: null,
			tax_period: null,
			trade_name: null,
			notice_status: [query]
		};
		setFilterQuery(updatedQuery)
	}

	const handleChartLabelClick = (label: string) => {
		const updatedQuery = {
			...filterQuery,
			gst_no: null,
			tax_amount: null,
			tax_period: null,
			trade_name: null,
			notice_status: [label]
		};
		// setFilterQuery(updatedQuery)
	};
	// ............

	// 
	const update = () => {
		const idArray: string[] = selectedGstRows.map((obj: { id: any }) => obj.id);
		MultiNoticeUpdate(idArray, noticeStatus).then((data: any) => {
			loadcalendardetails()
			setNotifyType("success")
			setNotifyMessage("Notice status updated successfully.")
			setNotifyOpen(true)
			setSelectedGstRows([])
			setStatuschange(false)
			loadGNotices()
			setTimeout(() => getGSTCounts(), 8000)
		}, (err: any) => {
			setNotifyType("error")
			setNotifyMessage(err.response.message)
			setNotifyOpen(true)
			setSelectedGstRows([])
		})
	}
	// ............

	// radio selection 
	const handlechange = (value: string) => {
		setSelected(value);
		setDropdownOpen(false);
	};
	const onChange = (e: RadioChangeEvent) => {
		setNoticeStatus(e.target.value);
		setSelected(e.target.value);
		setDropdownOpen(false);
	};
	// ...........

	// ................. create task fileupload
	const handleselectedFile = (event: any) => {
		setSelectedOrgFiles(event.target.files)
		let oldFiles = [...selectedFiles]
		Array.from(event.target.files).forEach(fi => {
			oldFiles.push(fi)
		});
		setSelectedFiles(oldFiles)

	};

	const removeFile = (ind: any) => {
		let oldFiles = [...selectedFiles]
		oldFiles.splice(ind, 1)
		setSelectedFiles(oldFiles)
	}
	// ..............

	// Add notice manually ....
	const [form] = Form.useForm();


	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({ gstin, refid, casetype, tax_period }).some(
				(name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
			),
		[formValues, gstin, refid, casetype, tax_period]
	)


	// Date format change funcions for tax-period value

	const handleDateChange = (date: dayjs.ConfigType | null) => { // Use dayjs.ConfigType instead of Moment
		if (date) {
			// Convert Moment to Dayjs
			const validDate = dayjs(date);
			if (validDate.isValid()) {
				// Format date using Dayjs
				const formattedDate = validDate.format('YYYY-MM-DD');
				setDuedate(formattedDate);
			} else {
				console.error('Invalid date');
			}
		} else {
			setDuedate(null);
		}
	};


	const handlenoticeDateChange = (date: dayjs.ConfigType | null) => { // Use dayjs.ConfigType instead of Moment
		if (date) {
			// Convert Moment to Dayjs
			const validDate = dayjs(date);
			if (validDate.isValid()) {
				// Format date using Dayjs
				const formattedDate = validDate.format('YYYY-MM-DD');
				setNoticedate(formattedDate);
			} else {
				console.error('Invalid date');
			}
		} else {
			setNoticedate(null);
		}
	};

	// fetch filter data
	const fetchData = async () => {
		try {
			const gstData = await serviceConfig.get("master_gst", true, null, null);
			const statesData = await serviceConfig.get("master_states", true, null, null);
			const tradesData = await serviceConfig.get("master_trades", true, null, null);
			const caseIdData = await serviceConfig.get("get_case_id", true, null, null);
			const arnData = await serviceConfig.get("get_arn", true, null, null);
			const sessionData = await serviceConfig.get("get_session", true, null, null);
			const caseTypeData = await serviceConfig.get("get_notice_type", true, null, null);

			setMasterData({
				...masterData,
				gst: gstData.data,
				states: statesData.data,
				trades: tradesData.data,
				caseIdFilter: caseIdData.data,
				arnFilter: arnData.data,
				sessionFilter: sessionData.data,
				caseType: caseTypeData.data.map((item: any) => item.toUpperCase()),
			});

			localStorage.setItem('masterData', JSON.stringify({
				gst: gstData.data,
				states: statesData.data,
				trades: tradesData.data,
				caseIdFilter: caseIdData.data,
				arnFilter: arnData.data,
				sessionFilter: sessionData.data,
				caseType: caseTypeData.data.map((item: any) => item.toUpperCase()),
			}));
		} catch (err) {
			console.log(err);
		}
	};
	// .........

	const handlemanualfileupload = () => {

		setLoading(true)
		let req_body: any;
		req_body = {
			// 'file_count': selectedFiles.length,
			'master_type': 'Manual',
			"notice_status": casestatus.value,
			'section': section.value,
			'tax_amount': taxamount.value,
			'due_date': duedate,
			'issue_date': noticedate,
			'tax_period': tax_period.value,
			'gst': gstin.value && gstin.value === 'others' ? {
				"gst_no": gstin_input.value,
				"trade_name": trade_name.value,
				"state": state.value,
			} : gstid,
			'case_data': JSON.stringify({
				"id": null,
				"case_type_name": casetype.value,
				"case_ref_id": [
					refid.value
				],
				"case_arn_no": caseid.value,
				"case_description": [
					casetype.value
				],
				"portal_status": casestatus.value,
				"case_id": null,
				"case_task_id": [
					null
				]
			})
		}

		serviceConfig.upload("manual_notice_add", null, selectedFiles, req_body)
			.then((data: any) => {
				setAddnotice(false)
				loadGNotices()
				AddnoticeInitial()
				setLoading(false)
				fetchData()
				setNotifyType("success");
				setNotifyMessage("Manual Notice Created Successfully!");
				setNotifyOpen(true);
			}, (err: any) => {
				console.log(err)
				setLoading(false)
				setNotifyType("error");
				setNotifyMessage("Something went wrong!");
				setNotifyOpen(true);
				AddnoticeInitial();
				setSelectedFiles([])
				setSelectedState('');
				setTradename('')
			})
	}

	const AddnoticeInitial = () => {
		setStartdate('')
		setEnddate('')
		handleInitial()
		setDuedate('')
		setNoticedate('')
	}

	// notice edit function 

	// Flatten Object Utility
	const flattenObject = (ob: any): Record<string, any> => {
		let toReturn: Record<string, any> = {};

		for (let i in ob) {
			if (!ob.hasOwnProperty(i)) continue;

			if ((typeof ob[i]) === 'object' && ob[i] !== null) {
				let flatObject = flattenObject(ob[i]);
				for (let x in flatObject) {
					if (!flatObject.hasOwnProperty(x)) continue;

					toReturn[i + '.' + x] = flatObject[x];
				}
			} else {
				toReturn[i] = ob[i];
			}
		}
		return toReturn;
	};

	// Property Mapping
	const propertyMapping: Record<string, keyof FormValues> = {
		"case_data.case_status": "portal_status",
		"gst.state": "state",
		"gst.trade_name": "trade_name",
		"gst.gst_no": "gstin_input",
		// gstin_input: "gstin_input",
		tax_period: "tax_period",
		"case_data.case_ref_id": "refid",
		"case_data.case_arn_no": "caseid",
		"case_data.case_type_name": "casetype",
		section: "section",
		tax_amount: "taxamount",
	};

	const handleAction = (type?: string, data?: ApiResponse) => {
		setActionType(type || 'add');
		if (type === 'Edit' && data) {
			setEditId(data.id);
			handleInitial();

			const flattenedData = flattenObject(data);
			console.log("Flattened Data:", flattenedData);
			setTradename(data.gst.trade_name)
			setSelectedState(data.gst.state)
			setSelectedFiles(data.attachments)
			setSourceFiles(data.attachments)
			setDuedate(data.due_date)
			setNoticedate(data.issue_date)
			setNoticeid(data.id)
			Object.keys(flattenedData).forEach((key) => {
				const formValueKey = propertyMapping[key];
				if (formValueKey) {
					console.log(`Mapping ${key} to ${formValueKey}`);
					if (formValues[formValueKey] !== undefined) {
						handleChange({
							target: { type: 'text', name: formValueKey, value: flattenedData[key] },
						} as React.ChangeEvent<HTMLInputElement>);
					} else {
						console.warn(`Form value for ${formValueKey} not found`);
					}
				} else {
					console.warn(`No mapping found for ${key}`);
				}
			});
		}
		setAddnotice(true);
	};

	const handlenoticeedit = () => {

		const findExcessFiles = () => {
			return selectedFiles.filter(
				(file: any) => !sourcefiles.some((sourceFile: any) => sourceFile.name === file.name)
			);
		};

		const excessFiles = findExcessFiles();

		setLoading(true)
		let req_body: any;
		req_body = {
			'file_count': excessFiles.length,
			"notice_status": casestatus.value,
			'section': section.value,
			'tax_amount': taxamount.value,
			'due_date': duedate,
			'issue_date': noticedate,
			'tax_period': tax_period.value,
			'id': noticeid,
		}

		serviceConfig.putupload("manual_notice_add", null, excessFiles, req_body)
			.then((data: any) => {
				setAddnotice(false)
				loadGNotices()
				AddnoticeInitial()
				setLoading(false)
				fetchData()
				setNotifyType("success");
				setNotifyMessage("Manual Notice Updated Successfully!");
				setNotifyOpen(true);
				setActionType("add")
				setNoticeid('')
			}, (err: any) => {
				console.log(err)
				setLoading(false)
				setNotifyType("error");
				setNotifyMessage("Something went wrong!");
				setNotifyOpen(true);
				AddnoticeInitial();
				setSelectedFiles([])
				setSelectedState('');
				setTradename('')
				setActionType("add")
				setAddnotice(false)
			})
	}
	// .............

	// .............

	// button details props
	const buttondetails = [
		// {
		// 	type: 'syncall',
		// 	name: 'Sync All',
		// 	theme: 'theme3',
		// 	conditions: ['if_list'],
		// 	function: synccallfunction
		// },
		{
			type: 'download',
			name: 'Download',
			theme: 'theme4',
			conditions: ['if_list'],
		},
		// {
		// 	type: 'get',
		// 	name: 'Get Solution from Zen Tax Clinic',
		// 	theme: 'theme2',
		// 	conditions: ['if_list'],
		// 	function: createTickets
		// },
	]
	// ............

	// handledrawerfilter
	const handledrawerfilter = (gst_no?: any, query?: any, is_over_due?: any, all?: any, trade_name?: any) => {
		if (all) {
			navigate(`/home/${gst_no}/view-gst-details`)
			let query: any = {
				gst_no: [gst_no]
			};
			setFilterQuery(query);
			setOverviewDetails({ gst_no, trade_name });
		} else {
			navigate(`/home/${gst_no}/view-gst-details`)
			let updatedQuery: any = {
				gst: gst_no,
			};
			if (query) {
				updatedQuery['notice_status'] = [query]
			}
			if (is_over_due) {
				updatedQuery['is_over_due'] = true
			}
			setNoticeQuery(updatedQuery)
		}
	}

	const handleviewticket = (value: any) => {
		setNoticedata(value); localStorage.setItem('noticedata', JSON.stringify(value))
		navigate(`/home/${value.id}/view-gst-details`)
	}

	// anchor 
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};


	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	// ..........

	// search 
	const search = (value: any) => {
		if (noticeData[0].notice) {
			setNoticeObj(true);
		}
		const filterTable = noticeData.filter((o: any) =>
			Object.keys(noticeObj ? o.notice : o).some(k => {
				if (k !== 'gst' && k !== 'case_data') {
					return String(noticeObj ? o.notice[k] : o[k])
						.toLowerCase()
						.includes(value.toLowerCase())
				} else {
					return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
						.toLowerCase()
						.includes(value.toLowerCase()))
				}
			}
			)
		);

		setNoticeData(filterTable);
	};
	// .............


	// table columns and filter requirement props
	const Additionalcolumn = [
		{
			title: <>
				{/* {expanded ?
					<img alt='expand icon' src={expandIcon1} onClick={e => expandHeader(e)} style={{ cursor: "pointer", position: 'relative', left: '-24%', width: '3vmin' }} />
					:
					<img alt='expang icon' src={expandIcon} onClick={e => expandHeader(e)} style={{ cursor: "pointer", position: 'relative', left: '-24%', width: '3vmin' }} />
				} */}
				<img alt='expand icon' src={expanded ? expandIcon1 : expandIcon} onClick={e => expandHeader(e)} style={{ cursor: "pointer", position: 'relative', left: '-24%', width: '3vmin' }} />
				<span style={{ position: 'relative', top: '-20%' }}>Trade name</span></>,
			dataIndex: 'gst', key: 'trade_name',
			sorter: true,

			sortDirection: sorterQuery.columnKey === 'trade_name' ? sorterQuery.order : 'ascend',

			filters: masterData.trades
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					});
				}),
			// filteredValue: loadedValues.trade || null,
			// filteredValue: ['ROOTS CAST PRIVATE LTD', 'MESSER CUTTING SYSTEMS INDIA PRIVATE LIMITED', 'R K R GOLD PRIVATE LIMITED'] || null,
			filterIcon: () => < img src={filterQuery && filterQuery.trade_name ? active_filtericon : filterIcon} alt='filter_icon' />,
			filterSearch: userdata && userdata.role && userdata.role.role_name === 'Super Admin' ? false : true,
			maxWidth: 120,
			render(value: any, record: any, index: any) {
				return (<span style={{ whiteSpace: 'pre-wrap' }} > {value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span >)
			},
		},
		{
			title: 'GSTIN', dataIndex: 'gst', key: 'gst_no',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'gst' ? sorterQuery.order : 'ascend',

			filters: masterData.gst
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					})
				}),
			// filteredValue: loadedValues.gst || null,
			filterSearch: true,
			filterIcon: () => filterQuery && filterQuery.gst_no ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				// return value.gst_no
				let link = "/home/" + value.gst_no + "/view-gst-details"
				if (record.ticket_created && record.ticket_id && record.ticket_id !== 'None') {
					return <span style={{ whiteSpace: 'pre-wrap' }} > {value.gst_no && value.gst_no.length > 25 ? value.gst_no.substr(0, 25) + '...' : value.gst_no ? value.gst_no : '--'}</span >
				}
				else {
					return <Link onClick={() => { handledrawerfilter(value.gst_no) }} to={link} style={{ whiteSpace: 'pre-wrap' }}>{value.gst_no}</Link>
				}
			}
		},
		{
			title: 'Tax Period', dataIndex: 'tax_period', key: 'tax_period',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'tax_period' ? sorterQuery.order : 'ascend',
			filters: [
				{
					text: 'Jul 2017 - Mar 2018',
					value: 'Jul 2017 - Mar 2018',

				},
				{
					text: 'Apr 2018 - Mar 2019',
					value: 'Apr 2018 - Mar 2019',

				},
				{
					text: 'Apr 2019 - Mar 2020',
					value: 'Apr 2019 - Mar 2020',

				},
				{
					text: 'Apr 2020 - Mar 2021',
					value: 'Apr 2020 - Mar 2021',

				},
				{
					text: 'Apr 2021 - Mar 2022',
					value: 'Apr 2021 - Mar 2022',

				},
				{
					text: 'Apr 2022 - Mar 2023',
					value: 'Apr 2022 - Mar 2023',

				}
			],
			// filteredValue: loadedValues.tax_period || null,
			filterSearch: true,
			filterIcon: () => filterQuery && filterQuery.tax_period ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				return value || '--'
			}
		},
		{
			title: 'Due date', dataIndex: 'due_date', key: 'due_date',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'due_date' ? sorterQuery.order : 'ascend',
			filterDropdown: () => (
				<div style={{ display: 'flex', flexDirection: "column", padding: 10, gap: "10%" }}>
					<AntDatePicker value={fromDueDate} onChange={(date: any) => setFromDueDate(date)} style={{ marginBottom: 10 }} placeholder='From Date' />
					<AntDatePicker value={toDueDate} onChange={(date: any) => setToDueDate(date)} placeholder="To Date" />
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
						<Button style={{ backgroundColor: "none", border: "none" }} onClick={clearDueDateFilter}>Reset</Button>
						<Button style={{ backgroundColor: "#424B70", color: "white" }} onClick={handleDueDateFilter}>Ok</Button>

					</div>
				</div>




			),
			filterIcon: () => filterQuery && filterQuery.due_from_date && filterQuery.due_to_date ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterSearch: true,
			render(value: any, record: any, index: any) {
				return value && value.indexOf('/') !== -1 ? value.split('/').join('-') : value ? value : '--'
			}
		},
		{
			title: 'Description', dataIndex: 'case_data', key: 'reason',
			// filters: [
			// 	{
			// 		text: 'Male',
			// 		value: 'male',

			// 	}
			// ],
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				// function formatDesc(descs: any) {
				// 	let desc_data: any = []
				// 	descs.forEach((des: any) => {
				// 		desc_data.push(des.split(' ').pop())
				// 	});
				// 	return descs.pop()
				// }
				return (
					// <span style={{ whiteSpace: 'break-spaces' }}>{value.case_description && value.case_description.length > 0 ? value.case_description[value.case_description.length - 1] : '--'} {value.case_description && value.case_description.length > 1 && <Tooltip style={{ cursor: 'pointer' }} title={value.case_description.join(', ')}>({value.case_description && value.case_description.length})</Tooltip>}</span>
					<span style={{ whiteSpace: 'break-spaces' }}>
						{value && value.case_description && value.case_description.length > 0
							? value.case_description[value.case_description.length - 1]
							: '--'}
						{value &&
							value.case_description &&
							value.case_description.length > 1 && (
								<Tooltip style={{ cursor: 'pointer' }} title={value.case_description.join(', ')}>
									({value.case_description.length})
								</Tooltip>
							)}
					</span>
				)
			},
		},
		{
			title: 'Tax amount', dataIndex: 'tax_amount', key: 'tax_amount',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'tax_amount' ? sorterQuery.order : 'ascend',
			filterDropdown: () => (
				<div style={{ display: 'flex', flexDirection: "column", padding: 10, gap: "10%" }}>
					<input type='number' min='0' value={fromTaxAmount} onChange={(ev: any) => setFromTaxAmount(ev.target.value)} style={{ marginBottom: 10, height: '30px', paddingLeft: '10px', borderRadius: '5px', border: '1px solid #ccc' }} placeholder='Search Amount' />
					<select value={amountOperator} onChange={(ev: any) => setAmountOperator(ev.target.value)} style={{ height: '30px', paddingLeft: '10px', borderRadius: '5px', border: '1px solid #ccc' }}>
						<option value='eq'>Equal To</option>
						<option value='lt'>Lesser Than</option>
						<option value='gt'>Greater Than</option>
					</select>
					{/* <input type='number' min='0' value={toTaxAmount} onChange={(ev: any) => setToTaxAmount(ev.target.value)} placeholder="To Amount"  /> */}
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
						<Button style={{ backgroundColor: "none", border: "none" }} onClick={clearTaxAmountFilter}>Reset</Button>
						<Button style={{ backgroundColor: "#424B70", color: "white" }} onClick={handleTaxAmountFilter}>Ok</Button>

					</div>
				</div>
			),
			filterIcon: () => filterQuery && filterQuery.amount_from && filterQuery.amount_oper ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				return (<span style={{ whiteSpace: 'pre-wrap' }}>{value && value ? '₹ ' + formatter.format(Number(value.toFixed(2))) : "--"}</span>)
			},
		},
		{
			title: 'Status', dataIndex: 'notice_status', key: 'notice_status',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'notice_status' ? sorterQuery.order : 'ascend',
			filters: [
				{
					text: 'No action required',
					value: 'No action required',
				},
				{
					text: 'Action to be taken',
					value: 'Action to be taken',
				},
				{
					text: 'Action taken',
					value: 'Action taken',
				}
			],
			filteredValue: filterQuery.notice_status || null,
			filterIcon: () => filterQuery && filterQuery.notice_status ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				if (value === "No action required") {
					return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
				}
				if (value === "Action to be taken") {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
				}
				if (value === "Action taken") {
					return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
				}
			},
		},
		Table.SELECTION_COLUMN,
	];

	const Notices_Orderscolumn = [
		{
			title: "Trade name",
			dataIndex: 'gst', key: 'trade_name',
			sorter: true,

			sortDirection: sorterQuery.columnKey === 'trade_name' ? sorterQuery.order : 'ascend',

			filters: masterData.trades
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					});
				}),
			filterIcon: () => filterQuery && filterQuery.trade_name ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterSearch: userdata && userdata.role && userdata.role.role_name === 'Super Admin' ? false : true,
			maxWidth: 120,
			render(value: any, record: any, index: any) {
				return (<span style={{ whiteSpace: 'pre-wrap' }} > {value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span >)
			},
		},
		{
			title: "Notice/Order Id",
			dataIndex: 'notice_order_id', key: 'notice_order_id',
			maxWidth: 120,
		},
		{
			title: 'Type', dataIndex: 'type', key: 'type',
		},
		{
			title: 'Notice/Order Description', dataIndex: 'description', key: 'description', maxWidth: 120,
		},
		{
			title: 'Date of Issuance', dataIndex: 'issue_date', key: 'issue_date',
		},
		{
			title: 'Due Date', dataIndex: 'due_date', key: 'due_date',
		},
		// {
		// 	title: 'Amount of Demand', dataIndex: 'amount', key: 'amount',
		// 	render(value: any, record: any, index: any) {
		// 		return (value ? <CurrencyFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'₹ '} /> : '--')
		// 	},
		// },
		// Table.SELECTION_COLUMN,
	];
	const formatter = new Intl.NumberFormat('en-IN');

	const GSTIN_Summarycolumn = [
		{
			title: 'Company Name',
			dataIndex: 'gst', key: 'trade_name',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'trade_name' ? sorterQuery.order : 'ascend',
			filters: masterData.trades
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					});
				}),
			// filteredValue: loadedValues.trade || null,
			// filteredValue: ['ROOTS CAST PRIVATE LTD', 'MESSER CUTTING SYSTEMS INDIA PRIVATE LIMITED', 'R K R GOLD PRIVATE LIMITED'] || null,
			filterIcon: () => filterQuery && filterQuery.trade_name ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterSearch: true,
			maxWidth: 120,
			render(value: any, record: any, index: any) {
				return (<span style={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }} onClick={() => value && handledrawerfilter(record.gst.gst_no, null, false, true, record.gst.trade_name)}>{value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span>)
			},
			width: 10
		},
		{
			title: 'GSTIN', dataIndex: 'gst', key: 'gst_no',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'gst' ? sorterQuery.order : 'ascend',
			filters: masterData.gst
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					})
				}),
			// filteredValue: loadedValues.gst || null,
			filterSearch: true,
			filterIcon: () => filterQuery && filterQuery.gst_no ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				return (<span style={{ whiteSpace: 'pre-wrap', cursor: 'pointer' }} onClick={() => value && handledrawerfilter(record.gst.gst_no, null, false, true, record.gst.trade_name)}>
					{value.gst_no}
				</span>)
				// let link = "/home/" + record.id + "/view-gst-details"
				// return <Link to={link} style={{ whiteSpace: 'pre-wrap' }}>{value.gst_no}</Link>
			}
		},
		{
			title: 'Total Notices',
			dataIndex: 'total_notices',
			key: 'total_notices',
			sortDirection: sorterQuery.columnKey === 'total_notices' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return <span onClick={() => value === 0 ? null : handledrawerfilter(record.gst.gst_no, null, false, true, record.gst.trade_name)} style={{ color: '#E96919', cursor: 'pointer', fontWeight: 'bold' }}> {value}</span >
			},
			width: 20
		},
		{
			title: 'Action Taken', dataIndex: 'action_taken', key: 'action_taken',
			sortDirection: sorterQuery.columnKey === 'action_taken' ? sorterQuery.order : 'ascend',
			filterSearch: true,
			render(value: any, record: any, index: any) {
				return <span onClick={() => value === 0 ? null : handledrawerfilter(record.gst.gst_no, 'Action taken')} style={{ color: '#318EFF', cursor: 'pointer' }}>{value}</span>
			},
			width: 20
		},
		{
			title: 'Action to be taken', dataIndex: 'action_to_be_taken', key: 'action_to_be_taken',
			sortDirection: sorterQuery.columnKey === 'action_to_be_taken' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return <span onClick={() => value === 0 ? null : handledrawerfilter(record.gst.gst_no, 'Action to be taken')} style={{ color: '#318EFF', cursor: 'pointer' }}>{value}</span>
			},
		},
		{
			title: 'No Action required', dataIndex: 'no_action_required', key: 'no_action_required',
			sortDirection: sorterQuery.columnKey === 'no_action_required' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return <span onClick={() => value === 0 ? null : handledrawerfilter(record.gst.gst_no, 'No action required')} style={{ color: '#318EFF', cursor: 'pointer' }}>{value}</span>
			},
		},
		{
			title: 'Overdue', dataIndex: 'overdue', key: 'overdue',
			sortDirection: sorterQuery.columnKey === 'overdue' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return <span onClick={() => value === 0 ? null : handledrawerfilter(record.gst.gst_no, null, true)} style={{ color: '#318EFF', cursor: 'pointer' }}>{value}</span>
			},
		},
		{
			title: 'Latest notices', dataIndex: 'latest_notices', key: 'latest_notices',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'latest_notices' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return <span onClick={() => value === 0 ? null : handledrawerfilter(record.gst.gst_no, null, true)} style={{ color: '#318EFF', cursor: 'pointer' }}>{value}</span>
			},
		},
		{
			title: 'Demand amount O/S', dataIndex: 'demand_amount', key: 'demand_amount',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'demand_amount' ? sorterQuery.order : 'ascend',

			render(value: any, record: any, index: any) {
				return (<span style={{ whiteSpace: 'pre-wrap' }}>{value && value ? '₹ ' + formatter.format(Number(value.toFixed(2))) : "--"}</span>)
			},
			width: 20
		}
	];

	const Manualcolumn = [
		{
			title: <>
				{expanded ?
					<img alt='expand icon' src={expandIcon1} onClick={e => expandHeader(e)} style={{ cursor: "pointer", position: 'relative', left: '-24%', width: '3vmin' }} />
					:
					<img alt='expang icon' src={expandIcon} onClick={e => expandHeader(e)} style={{ cursor: "pointer", position: 'relative', left: '-24%', width: '3vmin' }} />
				}
				<span style={{ position: 'relative', top: '-17%' }}>Trade name</span></>,
			dataIndex: 'gst', key: 'trade_name',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'trade_name' ? sorterQuery.order : 'ascend',
			filters: masterData.trades
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					});
				}),
			// filteredValue: loadedValues.trade || null,
			// filteredValue: ['ROOTS CAST PRIVATE LTD', 'MESSER CUTTING SYSTEMS INDIA PRIVATE LIMITED', 'R K R GOLD PRIVATE LIMITED'] || null,
			filterIcon: () => filterQuery && filterQuery.trade_name ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterSearch: true,
			maxWidth: 120,
			render(value: any, record: any, index: any) {
				return (<span style={{ whiteSpace: 'pre-wrap' }}>{value.trade_name && value.trade_name.length > 25 ? value.trade_name.substr(0, 25) + '...' : value.trade_name ? value.trade_name : '--'}</span>)
			},
		},
		{
			title: 'GSTIN', dataIndex: 'gst', key: 'gst_no',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'gst_no' ? sorterQuery.order : 'ascend',
			filters: masterData.gst
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return ({
						text: item,
						value: item,
					})
				}),
			// filteredValue: loadedValues.gst || null,
			filterSearch: true,
			filterIcon: () => filterQuery && filterQuery.gst_no ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				// return value.gst_no
				let link = "/home/" + record.id + "/view-gst-details"
				return <Link onClick={() => { console.log(record, 'record for notice store'); setNoticedata(record); localStorage.setItem('noticedata', JSON.stringify(record)) }} to={link} style={{ whiteSpace: 'pre-wrap' }}>{value.gst_no}</Link>
			}
		},
		{
			title: 'Tax Period', dataIndex: 'tax_period', key: 'tax_period',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'tax_period' ? sorterQuery.order : 'ascend',
			filters: [
				{
					text: 'Jul 2017 - Mar 2018',
					value: 'Jul 2017 - Mar 2018',

				},
				{
					text: 'Apr 2018 - Mar 2019',
					value: 'Apr 2018 - Mar 2019',

				},
				{
					text: 'Apr 2019 - Mar 2020',
					value: 'Apr 2019 - Mar 2020',

				},
				{
					text: 'Apr 2020 - Mar 2021',
					value: 'Apr 2020 - Mar 2021',

				},
				{
					text: 'Apr 2021 - Mar 2022',
					value: 'Apr 2021 - Mar 2022',

				},
				{
					text: 'Apr 2022 - Mar 2023',
					value: 'Apr 2022 - Mar 2023',

				}
			],
			// filteredValue: loadedValues.tax_period || null,
			filterSearch: true,
			filterIcon: () => filterQuery && filterQuery.tax_period ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				return value || '--'
			}
		},
		{
			title: 'Due date', dataIndex: 'due_date', key: 'due_date',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'address' ? sorterQuery.order : 'ascend',
			filterDropdown: () => (
				<div style={{ display: 'flex', flexDirection: "column", padding: 10, gap: "10%" }}>
					<AntDatePicker value={fromDueDate} onChange={(date: any) => setFromDueDate(date)} style={{ marginBottom: 10 }} placeholder='From Date' />
					<AntDatePicker value={toDueDate} onChange={(date: any) => setToDueDate(date)} placeholder="To Date" />
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
						<Button style={{ backgroundColor: "none", border: "none" }} onClick={clearDueDateFilter}>Reset</Button>
						<Button style={{ backgroundColor: "#424B70", color: "white" }} onClick={handleDueDateFilter}>Ok</Button>

					</div>
				</div>




			),
			filterIcon: () => filterQuery && filterQuery.due_from_date && filterQuery.due_to_date ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			filterSearch: true,
			render(value: any, record: any, index: any) {
				return value && value.indexOf('/') !== -1 ? value.split('/').join('-') : value ? value : '--'
			}
		},
		{
			title: 'Description', dataIndex: 'case_data', key: 'reason',
			// filters: [
			// 	{
			// 		text: 'Male',
			// 		value: 'male',

			// 	}
			// ],
			// filterIcon: () => <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				// function formatDesc(descs: any) {
				// 	let desc_data: any = []
				// 	descs.forEach((des: any) => {
				// 		desc_data.push(des.split(' ').pop())
				// 	});
				// 	return descs.pop()
				// }
				return (
					<span style={{ whiteSpace: 'break-spaces' }}>
						{value && value.case_description && value.case_description.length > 0
							? value.case_description[value.case_description.length - 1]
							: '--'}{' '}
						{value && value.case_description && value.case_description.length > 1 && (
							<Tooltip style={{ cursor: 'pointer' }} title={value.case_description.join(', ')}>
								({value.case_description.length})
							</Tooltip>
						)}
					</span>
					// <span style={{ whiteSpace: 'break-spaces' }}>{value.case_description.length > 0 ? value.case_description[value.case_description.length - 1] : '--'} {value.case_description.length > 1 && <Tooltip style={{ cursor: 'pointer' }} title={value.case_description.join(', ')}>({value.case_description.length})</Tooltip>}</span>
				)
			},
		},
		{
			title: 'Tax amount', dataIndex: 'tax_amount', key: 'tax_amount',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'tax_amount' ? sorterQuery.order : 'ascend',
			filterDropdown: () => (
				<div style={{ display: 'flex', flexDirection: "column", padding: 10, gap: "10%" }}>
					<input type='number' min='0' value={fromTaxAmount} onChange={(ev: any) => setFromTaxAmount(ev.target.value)} style={{ marginBottom: 10, height: '30px', paddingLeft: '10px', borderRadius: '5px', border: '1px solid #ccc' }} placeholder='Search Amount' />
					<select value={amountOperator} onChange={(ev: any) => setAmountOperator(ev.target.value)} style={{ height: '30px', paddingLeft: '10px', borderRadius: '5px', border: '1px solid #ccc' }}>
						<option value='eq'>Equal To</option>
						<option value='lt'>Lesser Than</option>
						<option value='gt'>Greater Than</option>
					</select>
					{/* <input type='number' min='0' value={toTaxAmount} onChange={(ev: any) => setToTaxAmount(ev.target.value)} placeholder="To Amount"  /> */}
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
						<Button style={{ backgroundColor: "none", border: "none" }} onClick={clearTaxAmountFilter}>Reset</Button>
						<Button style={{ backgroundColor: "#424B70", color: "white" }} onClick={handleTaxAmountFilter}>Ok</Button>

					</div>
				</div>
			),
			filterIcon: () => filterQuery && filterQuery.amount_from && filterQuery.amount_oper ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				return (<span style={{ whiteSpace: 'pre-wrap' }}>{value && value ? '₹ ' + formatter.format(Number(value.toFixed(2))) : "--"}</span>)
			},
		},
		{
			title: 'Status', dataIndex: 'notice_status', key: 'notice_status',
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'notice_status' ? sorterQuery.order : 'ascend',
			filters: [
				{
					text: 'No action required',
					value: 'No action required',
				},
				{
					text: 'Action to be taken',
					value: 'Action to be taken',
				},
				{
					text: 'Action taken',
					value: 'Action taken',
				}
			],
			// filteredValue: loadedValues.notice_status || null,
			filterIcon: () => filterQuery && filterQuery.notice_status ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			render(value: any, record: any, index: any) {
				if (value === "No action required") {
					return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
				}
				if (value === "Action to be taken") {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
				}
				if (value === "Action taken") {
					return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
				}
			},
		},
		{
			title: "Action",
			dataIndex: "sync_status",
			fixed: "right",
			render(value: any, record: any, index: any) {
				return (
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8%", fontSize: "20px", color: "#00000045", cursor: "default" }} >
						<img src={editIcon} alt='password icon' style={{ cursor: "pointer" }} onClick={() => {
							handleAction("Edit", record)
							console.log(record)
						}
						} />
					</div>
				)
			}
		},
		// Table.SELECTION_COLUMN,
	];
	// ............

	// counts card array
	const gstCountsData = {
		total_gst: gstCounts && gstCounts.total_gst || 0,
		wrong_gst: gstCounts && gstCounts.wrong_gst || 0,
		total_notices: gstCounts && gstCounts.total_notices || 0,
		action_to_be_taken: gstCounts && gstCounts.action_to_be_taken || 0,
		seven_days_due: gstCounts && gstCounts.seven_days_due || 0,
		over_due: gstCounts && gstCounts.overdue || 0
	};

	const cards = [
		{
			id: 1,
			name: 'GSTIN Managed',
			theme: '#BEDCE7',
			font: '#5FAABC',
			img: require('../../assets/GST-dash/GSTIN.png'),
			count: gstCountsData.total_gst,
			path: '/home/manage-gst'
		},
		{
			id: 2,
			name: 'Incorrect Password',
			theme: '#BEDCE7',
			font: '#5FAABC',
			img: require('../../assets/GST-dash/Incorrect_pass.png'),
			count: gstCountsData.wrong_gst,
			path: '/home/manage-gst'
		},
		{
			id: 3,
			name: 'Total Notice Count',
			theme: '#EEDEC1',
			font: '#FDB23C',
			img: require('../../assets/GST-dash/Totalnotice.png'),
			count: gstCountsData.total_notices,
		},
		{
			id: 4,
			name: 'Open Notice',
			theme: '#EEDEC1',
			font: '#FDB23C',
			img: require('../../assets/GST-dash/Open_notice.png'),
			count: gstCountsData.action_to_be_taken,
		},
		{
			id: 5,
			name: '7 Days Due',
			theme: '#ACBDD8',
			font: '#20438A',
			img: require('../../assets/GST-dash/7daydue.png'),
			count: gstCountsData.seven_days_due,
			// path: '/home/due-dashboard'
		},
		{
			id: 6,
			name: 'Over Due',
			theme: '#ACBDD8',
			font: '#20438A',
			img: require('../../assets/GST-dash/over-Due.png'),
			count: gstCountsData.over_due,
			// path: '/home/due-dashboard'
		}
	]
	// ...........

	const cardnavigation = (card: any) => {
		setSearchquery('')
		setFilterQuery({})
		setPage(1);
		setTotalpages(0);
		setIsManagegst(false)
		setRowsPerPage(10);
		navigate(card.path)
		if (card.name === 'Incorrect Password') {
			setManageactive('Incorrect')
			// let query: any = {
			// 	wrong_password: [true]
			// };
			// setFilterQuery(query);
		}
		// else if (card.name === 'Open Notice') {
		// 	setChartquery(false)
		// 	let query: any = {
		// 		notice_status: ['Action to be taken']
		// 	};
		// 	setFilterQuery(query)
		// 	setTabview(true)
		// 	setTabactive('Additional')
		// } else if (card.name === 'Total Notice Count') {
		// 	setChartquery(false)
		// 	setTabview(true)
		// 	setTabactive('Additional')
		// }
		// else if (card.name === '7 Days Due') {
		// 	let query: any = {
		// 		seven_days_due: [true]
		// 	};
		// 	setFilterQuery(query);
		// } else if (card.name === 'Over Due') {
		// 	let query: any = {
		// 		over_due: [true]
		// 	};
		// 	setFilterQuery(query);
		// }
	}
	// .........

	// due calendar
	const loadcalendardetails = useCallback((search_data?: any) => {
		setCalendarloading(true)
		let query: any = {
			by_page: true,
			page: page,
			page_size: rowsPerPage
		}
		if (searchquery) {
			query['search'] = searchquery;
		}

		if (filterQuery.due_from_date && filterQuery.due_to_date) {
			query['notice_start_due_date'] = filterQuery.due_from_date
			query['notice_end_due_date'] = filterQuery.due_to_date
		}

		if (filterQuery.gst_no) {
			query['gst_no'] = filterQuery.gst_no.join(',')
		}
		if (filterQuery.state) {
			query['state'] = filterQuery.state.join(',')
		}
		if (filterQuery.due_day) {
			query['due_day'] = filterQuery.due_day.join(',')
		}
		if (filterQuery.trade_name) {
			query['trade_name'] = filterQuery.trade_name.join(',')
		}
		if (filterQuery.tradename) {
			query['trade_name'] = filterQuery.tradename.join(',')
		}
		if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
			query['tax_period'] = filterQuery.tax_period.join(',')
		}
		if (sorterQuery && sorterQuery.columnKey) {
			query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === 'ascend' ? 'asc' : 'desc'}`
		}

		query['is_due'] = true

		serviceConfig.get("get_notices", true, query, null).then((data: any) => {
			setCalendarData(data.results || data.data)
			setCalendarloading(false)
		}, (err: any) => {
			setCalendarloading(false)
			console.log(err)
			if (err.message === "Request failed with status code 401") {
				navigate('/login')
				localStorage.clear()
				window.location.reload();
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const arrayOfObjects: any = calendardata;

	const generateSwitchCases = (datesArray: any): any => {
		const switchCases: any = {};
		datesArray.forEach((item: any) => {
			const formattedDate = moment(item.due_date).format('DD-MM-YYYY');
			if (formattedDate in switchCases) {
				switchCases[formattedDate].push({ type: 'success', content: `${item.case_data.case_arn_no}`, gstid: `${item.id}` })
			} else {
				switchCases[formattedDate] = [
					{ type: 'success', content: `${item.case_data.case_arn_no}`, gstid: `${item.id}` },
				];
			}
		});


		const defaultCase = [
			{ type: 'warn', content: 'No Due.' }
		];

		switchCases.default = defaultCase;

		return switchCases;
	};

	const switchCases = generateSwitchCases(arrayOfObjects);

	const getListData = (value: any): any[] => {
		const date = new Date(value.$d); // Assuming value is of type DateObject
		const formattedDate = moment(date).format('DD-MM-YYYY');
		const listData = switchCases[formattedDate] || [];
		return listData;
	};


	const getMonthData = (value: Dayjs) => {
		if (value.month() === 8) {
			return 1394;
		}
	};

	const monthCellRender = (value: Dayjs) => {
		const num = getMonthData(value);
		return num ? (
			<div className="notes-month">
				<section>{num}</section>
				<span>Backlog number</span>
			</div>
		) : null;
	};

	const navigate = useNavigate();
	const handleCellClick = (idvalue: any, value: any) => {
		const date = new Date(value.$d); // Assuming value is of type DateObject
		const formattedDate = moment(date).format('YYYY-MM-DD');
		navigate(`/home/${idvalue}/view-solution-details`)
		let query: any = {
			by_page: true,
			page: page,
			page_size: rowsPerPage
		}
		query['is_due'] = true;
		query['notice_start_due_date'] = formattedDate
		query['notice_end_due_date'] = formattedDate
		setNoticeQuery(query)
	};

	const dateCellRender = (value: Dayjs) => {
		const listData: any = getListData(value);
		const hasBadge = listData.length > 0;
		const count = listData.length;
		return (
			<>
				{hasBadge && (
					<Tooltip
						placement="topLeft"
						title={listData.map((item: any) => item.content).join(', ')}
						arrow={true}
						color={'#E96919'}
					>
						<span className="ant-picker-cell-inner" style={{ backgroundColor: '#E96919', color: '#fff', position: "absolute", top: 0, left: 0 }} onClick={() => handleCellClick(listData[0].gstid, value)}>
							{value.date()}
						</span>
					</Tooltip>
				)
				}
			</>
		);
	};

	const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
		if (info.type === 'date') return dateCellRender(current);
		if (info.type === 'month') return monthCellRender(current);
		return info.originNode;
	};
	// .... 

	return (
		<>
			< div id="gst-parent-container" className="gst-container" >
				<Modal
					maskStyle={{ padding: '8px !important' }}
					bodyStyle={{ maxHeight: '120px', overflowY: 'auto' }}
					closeIcon={false}
					open={filter}
					onOk={handleOkButtonClick}
					onCancel={handleCancel}
					cancelText="Reset"
					style={{
						top: y,
						left: x,
						height: 220,
						maxHeight: 320,
						width: 'max-content',
						position: "absolute"
					}} width={'15%'} >
					{secondaryFilter.map((item: any, index: any) => (
						item && <div key={index}>
							<Checkbox
								value={item}
								onChange={() => { handlecheck(item, secondaryFilter) }}
								checked={
									checkedItems.includes(item) ||
									checkedArnItems.includes(item) ||
									checkedCaseIdItems.includes(item) ||
									checkedSessionItems.includes(item)}
							/>
							<span style={{ marginLeft: 10 }} >{item}</span>
						</div>
					))}

				</Modal>
				<Modal
					title={""}
					onCancel={() => setGstSyncModal(false)}
					width={'35%'}
					className='action-popup'
					centered
					style={{ color: "#031742" }}
					open={gstSyncModal}
					okButtonProps={{ style: { display: 'none' } }}
					cancelButtonProps={{ style: { display: 'none' } }}
				>
					<div>
						<b>Sync scheduled successfully</b>
						<p>You can minimize this tab or even close the website, and return later to check the process. You're all set!</p>
					</div>
				</Modal>
				<Modal
					title={"Update Status"}
					onCancel={() => setStatuschange(false)}
					width={'20%'}
					className='action-popup status-chng-popup'
					centered
					style={{ color: "#031742" }}
					open={statuschange}
					onOk={update}
				>
					<div className="select-container">
						<span className='heading'>New status</span>
						<Select
							className="dropdown-btn"
							defaultValue="Select Action"
							onChange={handlechange}
							value={noticeStatus}
							open={dropdownOpen}
							onDropdownVisibleChange={(open) => setDropdownOpen(open)}

							dropdownRender={menu => {

								return (
									<div>

										<Radio.Group onChange={onChange} value={noticeStatus} style={{ display: 'flex', flexDirection: 'column' }}>
											<Radio value={'No action required'}>No action required</Radio>
											<Radio value={'Action to be taken'}>Action to be taken</Radio>
											<Radio value={'Action taken'}>Action taken</Radio>

										</Radio.Group>

									</div>
								)
							}}
							options={[
								{ value: 'No action required' },
								{ value: 'Action to be taken', },
								{ value: 'Action taken' },
							]}
						/>
						{/* <Button className='save-btn' onClick={updateNotice}>SAVE</Button> */}
					</div>
				</Modal>
				<Modal
					title={actionType === 'add' ? "Add notice" : `Edit notice`}
					onCancel={() => { setAddnotice(false); AddnoticeInitial(); setSelectedFiles([]); setSelectedState(''); setTradename(''); setActionType("add"); setNoticeid('') }}
					width={'60%'}
					className='action-popup status-chng-popup'
					centered
					style={{ color: "#031742" }}
					open={addnotice}
					onOk={handlemanualfileupload}
					okButtonProps={{ style: { display: 'none' } }}
					cancelButtonProps={{ style: { display: 'none' } }}
				>
					<div className="notice-form-container">
						<div className='left-grid'>
							<span style={{ fontSize: '2vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> GSTIN</span>
							{gstin_input.error && <span className="gst-err-msg">{gstin_input.error}</span>}


							{gstin.value !== 'others' && actionType === 'add' ? <TextField
								fullWidth
								focused
								required
								select
								SelectProps={{ native: true, }}
								variant='outlined'
								name="gstin"
								value={gstin.value}
								onBlur={handleChange}
								onChange={handleChange}
								autoComplete='off'
								style={{ margin: '2% 0' }}
							>
								{Gst_in.map((type: any, index: any) =>
									<option key={index} value={type}>{type}</option>
								)}
							</TextField> :
								<Input
									type='text'
									readOnly
									name='gstin_input'
									value={gstin_input.value}
									onChange={handleChange}
									onBlur={handleChange}
									style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Gstin' />
							}
							{gstin.value === 'others' && <Input
								type='text'
								name='gstin_input'
								value={gstin_input.value}
								onChange={handleChange}
								onBlur={handleChange}
								style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Kindly Input Gstin' />
							}
							<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Trade Name</span>
							{trade_name.error && <span className="gst-err-msg">{trade_name.error}</span>}

							{gstin.value === 'others' && <Input
								type='text'
								name='trade_name'
								onChange={handleChange}
								onBlur={handleChange}
								value={trade_name.value}
								style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Kindly Input Trade name' />
							}
							{gstin.value !== 'others' && <Input
								type='text'
								readOnly
								defaultValue={Trade_name}
								value={Trade_name}
								style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Kindly Select Gstin' />
							}
							<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> State</span>
							{state.error && <span className="gst-err-msg">{state.error}</span>}

							{gstin.value === 'others' &&
								<TextField
									fullWidth
									focused
									required
									select
									SelectProps={{ native: true, }}
									variant='outlined'
									name="state"
									value={state.value}
									onBlur={handleChange}
									onChange={handleChange}
									autoComplete='off'
									style={{ margin: '2% 0' }}
								>
									<option value='' disabled>...</option>
									{States.map((type: any, index: any) =>
										<option key={index} value={type.name}>{type.name}</option>
									)}
								</TextField>
							}
							{gstin.value !== 'others' && <Input
								type='text'
								defaultValue={selectedstate}
								value={selectedstate}
								readOnly
								style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='Kindly Select Gstin' />
							}
							<span style={{ fontSize: '2vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Tax Period</span>
							<TextField
								fullWidth
								focused
								required
								select
								SelectProps={{ native: true, }}
								variant='outlined'
								name="tax_period"
								value={tax_period.value}
								onBlur={handleChange}
								onChange={handleChange}
								autoComplete='off'
								style={{ margin: '2% 0' }}
							>
								<option value='' disabled>...</option>
								{Tax_period.map((type: any) =>
									<option key={type.id} value={type.value}>{type.value}</option>
								)}
							</TextField>

							<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">

								<Form.Item
									name="Notice Date"
									messageVariables={{ name: 'Case Date' }}
									className='form_item two'
								>
									<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Notice Date</span>

									<AntDatePicker value={noticedate ? dayjs(noticedate, 'YYYY-MM-DD') : null} onChange={(date, dateString: any) => handlenoticeDateChange(dateString)} style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: "2%" }} placeholder='Case Date' />
								</Form.Item>

								<Form.Item
									name="Due Date"
									messageVariables={{ name: 'Due Date' }}
									className='form_item two'
								>
									<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Due Date</span>

									<AntDatePicker value={duedate ? dayjs(duedate, 'YYYY-MM-DD') : null} onChange={(date, dateString: any) => handleDateChange(dateString)} style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: "2%" }} placeholder='Due Date' />
								</Form.Item>

							</Form>

						</div>
						<div className='right-grid'>

							<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
								<Form.Item
									name="taxamount"
									messageVariables={{ name: 'taxamount' }}
									className='form_item two'
									rules={[{ required: true }]}
								>
									{taxamount.error && <span className="gst-err-msg">{taxamount.error}</span>}
									<span style={{ fontSize: '2.1vmin ', color: '#000' }}><span style={{ color: 'red', fontSize: '2.2vmin' }}>*</span> Tax Amount</span>
									<Input type='text'
										name="taxamount"
										value={taxamount.value}
										onChange={handleChange}
										onBlur={handleChange}
										style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%' }} />
								</Form.Item>

								<Form.Item
									name="refid"
									messageVariables={{ name: 'refid' }}
									label="Ref Id"
									className='form_item two'
								>
									{refid.error && <span className="gst-err-msg">{refid.error}</span>}
									<Input type='text'
										name="refid"
										value={refid.value}
										onChange={handleChange}
										onBlur={handleChange}
										style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
								</Form.Item>

								<Form.Item
									name="caseid"
									messageVariables={{ name: 'caseid' }}
									label="Case Id"
									className='form_item two'
								>
									{caseid.error && <span className="gst-err-msg">{caseid.error}</span>}
									<Input type='text'
										name="caseid"
										value={caseid.value}
										onChange={handleChange}
										onBlur={handleChange}
										style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
								</Form.Item>
								<Form.Item
									name="section"
									messageVariables={{ name: 'section' }}
									label="Section"
									className='form_item two'
								>
									{section.error && <span className="gst-err-msg">{section.error}</span>}
									<Input type='text'
										name="section"
										value={section.value}
										onChange={handleChange}
										onBlur={handleChange}
										style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0 }} />
								</Form.Item>

								{<div style={{ paddingTop: 10 }}>
									<input
										ref={inputFileElement}
										onChange={handleselectedFile}
										multiple
										type="file"
										style={{ display: 'none' }} />
									<span
										onClick={() => inputFileElement.current.click()}
										className='attach_file_line'
										style={{
											color: '#4472C4',
											fontSize: '1.8vmin',
											fontWeight: 400,
											textDecoration: 'underline',
											cursor: 'pointer'
										}}>
										<img
											src={attachIcon}
											className='attach_file_img'
											style={{
												marginRight: '5px'
											}}
											height={'13vmin'}
											alt='attach' />
										Attachments
									</span>
									<div
										style={{
											display: 'flex',
											// marginTop: '10px',
											marginBottom: '10px',
											width: '100%',
											flexWrap: 'wrap'
										}}>
										{selectedFiles.map((file: any,
											index: any) =>
											<span
												key={file.name}
												style={{
													width: '50%',
													padding: '1% 2%',
													backgroundColor: '#FCE7D9',
													border: '0.5px solid #E96919',
													marginRight: '1%',
													marginBottom: '1%',
													justifyContent: 'space-between',
													wordBreak: 'break-all',
													borderRadius: '5px',
													display: 'flex',
													alignItems: 'center'
												}}>{file.docName || file.name}
												{file.name && <img
													alt="close-icon"
													onClick={() => removeFile(index)}
													src={closeIcon}
													style={{
														marginLeft: '5px',
														cursor: 'pointer'
													}}
													height={'10vmin'} />}
											</span>)
										}
									</div>
								</div>}

							</Form>
							<span style={{ fontSize: '2vmin ', color: '#000' }}>Case Type</span>
							{actionType === 'add' ? <TextField
								fullWidth
								focused
								select
								SelectProps={{ native: true, }}
								variant='outlined'
								name="casetype"
								value={casetype.value}
								onBlur={handleChange}
								onChange={handleChange}
								autoComplete='off'
								style={{ margin: '2% 0' }}
							>
								<option value='' disabled>...</option>
								{masterData.caseType && masterData.caseType.map((type: any) =>
									<option key={type} value={type}>{type}</option>
								)}
							</TextField> :
								<Input
									type='text'
									readOnly
									name='casetype'
									value={casetype.value}
									onChange={handleChange}
									onBlur={handleChange}
									style={{ height: '45px', width: '100%', border: '1px solid rgba(0, 0, 0, 0.13)', marginLeft: 0, marginTop: '2%', marginBottom: '2%' }} placeholder='casetype' />
							}

							<span style={{ fontSize: '2vmin ', color: '#000' }}>Status</span>
							<TextField
								fullWidth
								focused
								select
								SelectProps={{ native: true, }}
								variant='outlined'
								name="casestatus"
								// value={typeOfSolution.value}
								onBlur={handleChange}
								onChange={handleChange}
								defaultValue='Action to be taken'
								autoComplete='off'
								style={{ margin: '2% 0' }}
							>
								<option value='' disabled>...</option>
								{TypeOfSolution.map((type: any) =>
									<option key={type.id} value={type.value}>{type.value}</option>
								)}
							</TextField>



						</div>
					</div>
					<Form.Item className='form_item' style={{ display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '16px' }} >
						<Button
							htmlType="submit"
							id='popup-submit-btn'
							className='create-popup-btn'
							// disabled={!gstin.value || !tax_period.value || !taxamount.value || !duedate || !noticedate || isError()}
							loading={loading}
							style={{
								background: "#424B70",
								color: "white",
								border: "none",
								width: "20vw",
								height: "6vh",
								fontSize: '2.3vmin'
							}}
							onClick={() => { actionType === 'add' ? handlemanualfileupload() : handlenoticeedit() }}>
							{!loading && <>{actionType === 'add' ? 'Submit' : 'Update'}</>}
							{loading && <>Loading...</>}
						</Button>
					</Form.Item>
				</Modal >
				{
					gstCounts &&
					<>
						<div className='gst-stats'>
							<div className='count-card-wrap'>
								{cards.map((card) => (
									<div key={card.id}
										onClick={() => { card.count > 0 && cardnavigation(card) }}
										className="count-card" style={{ backgroundColor: `${card.theme}`, color: `${card.font}` }}>
										<img src={card.img} alt='card-img' />
										<div className='card-content'>
											<span>{card.count}</span>
											{card.name}
										</div>
									</div>
								))}
							</div>
							<div className='calenderAndChart'>
								<div className='cal1'>
									{calendarloading ?
										<Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
										</Spin> :
										<Calendar
											onSelect={(date: Dayjs | undefined) => setSelectedDate(date)}
											className='calendar1style'
											cellRender={cellRender}
											fullscreen={false}
											value={selectedDate}
										/>
									}
								</div>
								<div className='cal2'>
									<div className="stats-boxcal2" id="gst-chart2">
										<div className="gst-chart2">
											<Chart className='dashboard-chart' counts={[gstCounts.no_action_required, gstCounts.action_taken, gstCounts.action_to_be_taken]} onLabelClick={handleChartLabelClick} />
										</div>
										<div className="chart-stat2">
											<div className="stat-item2"
												onClick={() => {
													setChartquery(true)
													let query: any = {
														notice_status: ['No action required']
													};
													setFilterQuery(query)
												}}
											>
												<div className="stat-sub2">
													<span className="dot2" style={{ backgroundColor: "#299CDB" }}></span>
													<p>No Action Required</p>
												</div>
												<div className="stat-subs2">
													<span style={{ color: '#000' }}>{gstCounts.no_action_required}</span>
													<img src={arrowRightIcon} alt="" />
												</div>

											</div>
											<div className="stat-item2"
												onClick={() => {
													setChartquery(true)
													let query: any = {
														notice_status: ['Action to be taken']
													};
													setFilterQuery(query)
												}}
											>
												<div className="stat-sub2">
													<span className="dot" style={{ backgroundColor: "#F06548" }}></span>
													<p>Action to be Taken</p>
												</div>
												<div className="stat-subs2">
													<span style={{ color: '#000' }}>{gstCounts.action_to_be_taken}</span>
													<img src={arrowRightIcon} alt="" />

												</div>

											</div>   <div className="stat-item2"
												onClick={() => {
													setChartquery(true)
													let query: any = {
														notice_status: ['Action taken']
													};
													setFilterQuery(query)
												}}
											>
												<div className="stat-sub2">
													<span className="dot2" style={{ backgroundColor: "#0AB39C" }}></span>
													<p>Action Taken</p>
												</div>
												<div className="stat-subs2">
													<span style={{ color: '#000' }}>{gstCounts.action_taken}</span>
													<img src={arrowRightIcon} alt="" />

												</div>

											</div>
										</div>


									</div>
								</div>
							</div>
							<div className="calendar-wrap">
								{calendarloading ?
									<Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
									</Spin> :
									<Calendar
										onSelect={(date: Dayjs | undefined) => setSelectedDate(date)}
										className='calendar'
										cellRender={cellRender}
										fullscreen={false}
										value={selectedDate}
									/>
								}
							</div>
						</div>
						<div className='mob-gst-stats'>
							<div className="first-stats-box" id="gst-chart">
								<div className="chart-stat">

									<div className="stat-item" >
										<div className="stat-sub">
											<img src={gstIcon} alt='gst icon' className='mob-gst-icon' />
											<p id="blue" style={{ color: "#5E88FF" }}>GSTIN MANAGED</p>
										</div>
										<div className="stat-subs">
											<p className='count' style={{ color: "#5E88FF" }} >{gstCounts.total_gst}</p>
											<img src={arrowRightIcon} alt="" />
										</div>
									</div>
									<div className="stat-item">
										<div className="stat-sub">
											<img src={incorrectPasswordIcon} alt='password icon' className='mob-gst-icon' />
											<p style={{ color: "#F49C01" }}>INCORRECT PASSWORD</p>
										</div>
										<div className="stat-subs">
											<p className='count' style={{ color: "#F49C01" }} >{gstCounts.wrong_gst}</p>
											<img src={arrowRightIcon} alt="" />
										</div>
									</div>
									<div className="stat-item">
										<div className="stat-sub">
											<img src={noticeIcon} alt='notice icon' className='mob-gst-icon' />
											<p style={{ color: "#04AA8C" }}>TOTAL NOTICE  COUNT</p>
										</div>
										<div className="stat-subs">
											<p className='count' style={{ color: "#04AA8C" }}>{gstCounts.total_notices}</p>
											<img src={arrowRightIcon} alt="" />
										</div>

									</div>
								</div>
							</div>
							<div className="stats-box" id="gst-chart">
								<div className="gst-chart">
									<Chart className='dashboard-chart' counts={[gstCounts.no_action_required, gstCounts.action_taken, gstCounts.action_to_be_taken]} onLabelClick={handleChartLabelClick} />
								</div>
								<div className="chart-stat">
									<div className="stat-item"
									// onClick={() => {
									// 	setChartquery(true)
									// 	let query: any = {
									// 		notice_status: ['No action required']
									// 	};
									// 	setFilterQuery(query)
									// }}
									>
										<div className="stat-sub">
											<span className="dot" style={{ backgroundColor: "#299CDB" }}></span>
											<p>No action required</p>
										</div>
										<div className="stat-subs">
											<span style={{ color: '#000' }}>{gstCounts.no_action_required}</span>
											<img src={arrowRightIcon} alt="" />
										</div>

									</div>
									<div className="stat-item"
									// onClick={() => {
									// 	setChartquery(true)
									// 	let query: any = {
									// 		notice_status: ['Action to be taken']
									// 	};
									// 	setFilterQuery(query)
									// }}
									>
										<div className="stat-sub">
											<span className="dot" style={{ backgroundColor: "#F06548" }}></span>
											<p>Action to be taken</p>
										</div>
										<div className="stat-subs">
											<span style={{ color: '#000' }}>{gstCounts.action_to_be_taken}</span>
											<img src={arrowRightIcon} alt="" />

										</div>

									</div>   <div className="stat-item"
									// onClick={() => {
									// 	setChartquery(true)
									// 	let query: any = {
									// 		notice_status: ['Action taken']
									// 	};
									// 	setFilterQuery(query)
									// }}
									>
										<div className="stat-sub">
											<span className="dot" style={{ backgroundColor: "#0AB39C" }}></span>
											<p>Action taken</p>
										</div>
										<div className="stat-subs">
											<span style={{ color: '#000' }}>{gstCounts.action_taken}</span>
											<img src={arrowRightIcon} alt="" />

										</div>

									</div>
								</div>

							</div>

						</div>
					</>
				}
				<div className='Chart-table-cover'>
					<div className='chart-wrap'>
						<div className="stats-box" >
							{gstCounts ?
								<>
									<div className="gst-chart">
										<Chart className='chart' counts={[gstCounts.no_action_required, gstCounts.action_taken, gstCounts.action_to_be_taken]} onLabelClick={handleChartLabelClick} />
									</div>
									<div className="chart-stat">
										<div className="stat-item"
										// onClick={() => {
										// 	setChartquery(false)
										// 	let query: any = {
										// 		notice_status: ['No action required']
										// 	};
										// 	setFilterQuery(query)
										// 	setTabview(true)
										// 	setTabactive('Additional')
										// }}
										>
											<div className="stat-sub">
												<span className="dot" style={{ backgroundColor: "#299CDB" }}></span>
												<p>No action required</p>
											</div>
											<div className="stat-subs">
												<span style={{ color: '#000' }}>{gstCounts.no_action_required || 0}</span>
												<img src={arrowRightIcon} alt="" />

											</div>

										</div>
										<div className="stat-item"
										// onClick={() => {
										// 	setChartquery(false)
										// 	let query: any = {
										// 		notice_status: ['Action to be taken']
										// 	};
										// 	setFilterQuery(query)
										// 	setTabview(true)
										// 	setTabactive('Additional')
										// }}
										>
											<div className="stat-sub">
												<span className="dot" style={{ backgroundColor: "#F06548" }}></span>
												<p>Action to be taken</p>
											</div>
											<div className="stat-subs">
												<span style={{ color: '#000' }}>{gstCounts.action_to_be_taken || 0}</span>
												<img src={arrowRightIcon} alt="" />

											</div>

										</div>
										<div className="stat-item"
										// onClick={() => {
										// 	setChartquery(false)
										// 	let query: any = {
										// 		notice_status: ['Action taken']
										// 	};
										// 	setFilterQuery(query)
										// 	setTabview(true)
										// 	setTabactive('Additional')
										// }}
										>
											<div className="stat-sub">
												<span className="dot" style={{ backgroundColor: "#0AB39C" }}></span>
												<p>Action taken</p>
											</div>
											<div className="stat-subs">
												<span style={{ color: '#000' }}>{gstCounts.action_taken || 0}</span>
												<img src={arrowRightIcon} alt="" />

											</div>

										</div>
									</div>
								</> :
								<Spin spinning={true} size='default' >
								</Spin>
							}

						</div>
					</div>
					<div className='table-wrap' >
						<ZTable2
							label={'Table of Contents'}
							button={buttondetails}
							columns={(tabactive === 'Additional' && Additionalcolumn) || (tabactive === 'Notices & Orders' && Notices_Orderscolumn) || (tabactive === 'Manual' && Manualcolumn) || (tabactive === 'Appeal' && Notices_Orderscolumn) || (tabactive === 'GSTIN Summary' && GSTIN_Summarycolumn) || (tabactive === 'Latest' && Additionalcolumn)}
							expand={(tabactive === 'GSTIN Summary' || tabactive === 'Notices & Orders') ? false : true}
							ref={gstTableElement}
							tabledata={noticeData}
							loading={loading}
							chartclick={chartquery}
							hideSelect={tabactive === 'GSTIN Summary' ? true : false}
							totalpages={totalpages} />
					</div>

				</div>

				<NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
			</div >
		</>
	)
}
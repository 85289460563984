// FileUpload.tsx
import React, { useRef, useState } from 'react';

// sccs file
import './gstloan.scss';
// .........

// package components
import { FormLabel } from '@mui/material';
// .........

// iocns
import closeIcon from '../../assets/register/close.png'
// .........

interface FileUploadProps {
    serialNumber: number;
    label: string;
    selectedFiles: File[];
    onFilesSelected: (field: string, files: FileList) => void;
    onRemoveFile: (field: string, index: number) => void;
}

const Fileupload: React.FC<FileUploadProps> = ({ serialNumber, label, selectedFiles = [], onFilesSelected, onRemoveFile }) => {
    const inputFileElement = useRef<HTMLInputElement | null>(null);
    const [inputKey, setInputKey] = useState<number>(Date.now());

    const handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            onFilesSelected(label, event.target.files);
            setInputKey(Date.now());
        }
    };
    return (
        <div className='field-cover'>
            <FormLabel className="field-label"><span style={{color:'#E96919'}}>{`${serialNumber}.`}</span> {`${label}`}</FormLabel>
            <div className='upload-input-cover'>
                <input
                    ref={inputFileElement}
                    key={inputKey}
                    onChange={handleSelectedFile}
                    type="file"
                    style={{ display: 'none' }} />
                <span
                    onClick={() => inputFileElement.current?.click()}
                    className='attach_file_line'
                >
                    Upload
                </span>
                <div className='selection_list'>
                    {selectedFiles.map((file, index) => (
                        <span
                            key={file.name}
                            style={{
                                width: 'auto',
                                padding: '1% 2%',
                                backgroundColor: '#FCE7D9',
                                border: '0.5px solid #E96919',
                                marginRight: '1%',
                                justifyContent: 'space-between',
                                borderRadius: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'pre'
                            }}>
                            {file.name}
                            <img
                                alt="close-icon"
                                onClick={() => onRemoveFile(label, index)}
                                src={closeIcon}
                                style={{
                                    marginLeft: '5px',
                                    cursor: 'pointer'
                                }}
                                height={'10vmin'} />
                        </span>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default Fileupload;

import React, { useState, useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { serviceConfig } from '../../core/services';
import { Modal, Radio, RadioChangeEvent, Select, Spin } from 'antd';
import { SelectWithRadioGroup } from './select_radio';
import EditIcon from '@mui/icons-material/Edit';
import { NotifyAlert } from '../../components/notify_alert';

interface ApiResponse {
    id: string;
    [key: string]: any;
}

interface LoandetailsProps {
    Loading: any;
    data: any;
    func: any;
}

const fieldMappings: { [key: string]: string } = {
    cibil_letter: 'Consent letter for CIBIL',
    pan_doc: 'PAN',
    business_rent_agreement: 'Business GST certificate and Udyam Certificate',
    bank_statement: 'Bank Statement for the latest 6 months (OD/CC/CA)',
    partnership_deed: 'Partnership Deed (Registered)',
    sanction_letter: 'Sanction letter For OD/CC (if any)',
    selfie_photo: 'Selfie / Photo',
    selfie_photo_directors: 'Selfie / Photo of atleast 2 Directors',
    poi_pan_ph: 'POI (PAN)',
    poa_adhaar_ph: 'POA (Aadhar) of atleast 2 Partners ',
    poi_pan_directors: 'POI (PAN) of atleast 2 Partners ',
    beneficiary_owner: 'Beneficiary Owners: PAN, Aadhar & selfie ',
    ledgers: 'Last 2 years Provisional financials/ledgers',
    aoa_moa_coi: 'AOA, MOA, COI',
    sharehold_cod: 'Latest Shareholding pattern (Certified), LOD',
    sharehold_loc: 'Latest Shareholding pattern (Certified), LOD'
};



const Loanlist: React.FC<LoandetailsProps> = ({ Loading, data, func }) => {
    const userRole = localStorage.getItem('userRole') || '';

    const [taskcollapse, setTaskcollapse] = useState<string | null>(null);
    const [loanDetailsList, setLoanDetailsList] = useState<ApiResponse[]>([]);

    const [updateid, setUpdateid] = useState('')

    const [loading, setLoading] = useState(true);
    const [poploading, setPoploading] = useState(false);

    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')

    const [statuschange, setStatuschange] = useState(false)

    const [aplicdropOpen, setAplicDropOpen] = useState<boolean>(false);
    const [sanctdropOpen, setSanctDropOpen] = useState<boolean>(false);
    const [aplicStatus, setAplicStatus] = useState('Waiting for approval');
    const [sanctStatus, setSanctStatus] = useState('Rejected');

    useEffect(() => {
        setLoading(Loading)
        setLoanDetailsList(data);
        if (data.length > 0) {
            setTaskcollapse(data[0].id);
        }
    }, [Loading, data, func]);

    const toggleCollapse = (id: string) => {
        setTaskcollapse(prevId => (prevId === id ? null : id));
    };

    const getFileNameFromUrl = (url: string) => {
        return url.substring(url.lastIndexOf('/') + 1);
    };

    // status submission and initial state function.....
    const initialstate = () => {
        setSanctStatus('Rejected')
        setAplicStatus('Waiting for approval')
        setSanctDropOpen(false)
        setAplicDropOpen(false)
        setUpdateid('')
    }
    const update = () => {
        setPoploading(true)
        const req_body = {
            application_Status: aplicStatus,
            sanctioned: sanctStatus
        };

        serviceConfig.put("gst_loan_update", true, req_body, null, { id: updateid }, false).then(
            (data: any) => {
                setPoploading(false)
                initialstate()
                setStatuschange(false)
                setNotifyType("success")
                setNotifyMessage("Application status updated successfully.")
                setNotifyOpen(true)
                func()
            }).catch((err: any) => {
                setPoploading(false)
            })
    }
    // ............

    //selection changes 

    const options1 = [
        { value: 'Approved', label: 'Approved' },
        { value: 'Waiting for approval', label: 'Waiting for approval' },
    ];

    const options2 = [
        { value: 'Sanctioned', label: 'Sanctioned' },
        { value: 'Rejected', label: 'Rejected' },
    ];

    // ...........

    return (
        <>
            <Modal
                title={"Update Application Status"}
                onCancel={() => { setStatuschange(false); initialstate() }}
                width={'27%'}
                className='action-popup status-chng-popup'
                centered
                style={{ color: "#031742" }}
                open={statuschange}
                onOk={update}
            >
                <div style={{ height: '27vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <SelectWithRadioGroup
                        title="Approval status"
                        options={options1}
                        selectedValue={aplicStatus}
                        onChange={(value) => {
                            setAplicStatus(value);
                            setAplicDropOpen(false);
                        }}
                        open={aplicdropOpen}
                        setOpen={setAplicDropOpen}
                    />

                    <SelectWithRadioGroup
                        title="Sanction status"
                        options={options2}
                        selectedValue={sanctStatus}
                        onChange={(value) => {
                            setSanctStatus(value);
                            setSanctDropOpen(false);
                        }}
                        open={sanctdropOpen}
                        setOpen={setSanctDropOpen}
                    />
                    < Spin
                        spinning={poploading}
                        size='default'
                        style={{
                            maxWidth: '100%',
                            display: 'flex',
                            position: 'absolute',

                        }
                        }>
                    </Spin >
                </div>
            </Modal >
            {< Spin
                spinning={loading}
                size='default'
                style={{
                    maxWidth: '100%',
                    display: 'flex',
                    position: 'absolute',
                    marginTop: '40vh',
                    marginLeft: '43%'
                }
                }>
            </Spin >}
            {
                loanDetailsList.map((task, index) => (
                    <div className='task-info-wrap' key={task.id}>
                        <div className='task-info-header' onClick={() => toggleCollapse(task.id)}>
                            <ArrowBackIosNewIcon
                                onClick={() => toggleCollapse(task.id)}
                                fontSize='small'
                                className='icon'
                                style={{ transform: `rotate(${taskcollapse === task.id ? '270deg' : '180deg'})` }}
                            />
                            <h4 onClick={() => toggleCollapse(task.id)} className='task-info-title'>
                                {index + 1} . Loan Information
                            </h4>
                            {userRole === 'Super Admin' && <EditIcon
                                style={{ marginLeft: 'auto', marginRight: '2%', color: '#E96919' }}
                                onClick={(e: any) => { e.stopPropagation(); setStatuschange(true); setUpdateid(task.id); setSanctStatus(task.sanctioned); setAplicStatus(task.application_Status) }}>
                            </EditIcon>}
                        </div>
                        {taskcollapse === task.id && (
                            <div className='task-info-content-wrap'>
                                {Object.keys(task).map((key, index) => (
                                    key !== 'id' && task[key] && task[key].length > 0 && (
                                        <div className='task-details-tile' key={key}>
                                            <span className='details-tile-label'>{fieldMappings[key] || key.replace(/_/g, ' ')}</span>                                        <span className='details-tile-value'>
                                                {Array.isArray(task[key]) ? (
                                                    task[key].map((document: string, idx: number) => (
                                                        <div key={idx}>
                                                            <span
                                                                style={{
                                                                    width: 'max-content',
                                                                    padding: '2% 2%',
                                                                    backgroundColor: '#FCE7D9',
                                                                    border: '0.5px solid #E96919',
                                                                    marginRight: '1%',
                                                                    justifyContent: 'space-between',
                                                                    borderRadius: '5px',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    fontSize: '1.8vmin',
                                                                }}>
                                                                {getFileNameFromUrl(document)}
                                                            </span>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div>{task[key]}</div>
                                                )}
                                                {/* {task[key].map((document: string, idx: number) => (
                                                    <div key={idx}>
                                                        <span
                                                            style={{
                                                                width: 'max-content',
                                                                padding: '2% 2%',
                                                                backgroundColor: '#FCE7D9',
                                                                border: '0.5px solid #E96919',
                                                                marginRight: '1%',
                                                                justifyContent: 'space-between',
                                                                borderRadius: '5px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                fontSize: '1.8vmin',
                                                            }}>
                                                            {getFileNameFromUrl(document)}
                                                        </span>

                                                    </div>
                                                ))} */}
                                            </span>
                                        </div>
                                    )
                                ))}
                            </div>
                        )}
                    </div>
                ))
            }
            {!loading && loanDetailsList.length === 0 && <p style={{ marginLeft: '39vw', marginTop: '40vh' }}>No Data!</p >}
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>

        </>
    );
};

export default Loanlist;

// react 
import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// ..........

// scss file
import './user-profile.scss';
// ..........

// package components
import { Dialog, DialogContent, DialogTitle, FormControlLabel, RadioGroup, StepLabel, TextField, FormHelperText, InputAdornment, OutlinedInput, ThemeProvider, } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Step from '@mui/material/Step';
import { StepIconProps } from '@mui/material/StepIcon';
import Stepper from '@mui/material/Stepper';
import { createTheme, styled } from '@mui/material/styles';
import { Form, Modal, Radio } from 'antd';
import copy from 'clipboard-copy';
// ..........

// child components
import { NotifyAlert } from '../../components/notify_alert';
import { serviceConfig } from '../../core/services';
import { useGlobalState } from '../../Statecontext';
// ..........

// icons 
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import primary from '../../assets/images/primary.png';
import secondary from '../../assets/images/secondary.png';
import { AppContext } from '../../core/utils/Context';
import SendIcon from '@mui/icons-material/Send';
// ..........

const steps = ['Primary', 'Secondary'];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


// custom theming function
const inputtheme = createTheme({
    palette: {
        primary: {
            main: '#9a9999',
        }
    },
});
// ..........


export const UserProfile = ({ open, setOpen, refresh }: any) => {

    // form values states
    const [form] = Form.useForm();

    const mobile_verfy = localStorage.getItem('')
    const [pincode, setPincode] = useState('')
    const [pinchanged, setpinChanged] = useState(false)
    const [user_name, setUsername] = useState('')
    const [user_email, setUserEmail] = useState('')
    const [user_mobile, setUserMobile] = useState('')
    const [user_id, setUserId] = useState('')
    const [new_password, setPassword] = useState('**********')

    const [sec_email, setSecEmail] = useState<any>('')
    const [sec_mobile, setSecMobile] = useState('')
    const [err_pincode, setErrPincode] = useState('')
    const [err_user_name, setErrUserName] = useState('')
    const [err_user_mobile, setErrUserMobile] = useState('')
    const [err_user_email, setErruserEmail] = useState('')

    const [err_sec_email, setErrSecEmail] = useState('')
    const [err_sec_mobile, setErrSecMobile] = useState('')

    const [cf_code, setCf_code] = useState('')

    const [cgstcheck, setCgstcheck] = useState('Daily');
    const [sgstcheck, setSgstcheck] = useState('Daily');
    const [gstcheck, setGstcheck] = useState('Fresher');

    const [location, setLocation] = useState<any>([]);
    const [locationdetails, setLocationdetails] = useState<any>(null)
    const [showaddress, setShowaddress] = useState(false);

    const [selectedValue, setSelectedValue] = React.useState('Monday,Wednesday,Friday');

    const [popuptype, setPopuptype] = useState('')
    const [usertype, setUsertype] = useState<string>('GST Trainee');
    const [tempUsertype, setTempUsertype] = useState<string>('GST Trainee');

    const { formValues, handleChange, handleInitial } = useContext(AppContext);
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const [err_otp, setErr_otp] = useState('');

    const { fullname, email, experience, address, ticContactNumber, organisation_name, qualification, team_size, pin_code, city, alt_contactnumber } = formValues;
    // ..........

    // alerts and modal states
    const [phpopup, setPhpopup] = useState(false)

    const [modaltype, setModaltype] = useState('Role')
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('');

    const [otpmodal, setOtpmodal] = useState(false);
    // ..........

    // loading state    
    const [loading, setLoading] = useState(false)
    const [phloading, setPhloading] = useState(false)
    // ....

    // reference states 
    const { userdata, setUserdata } = useGlobalState();
    const [sourcecf, setSourceCf] = useState(false)
    const [accept_cf, setCf] = useState(false)
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    const navigate = useNavigate()
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    const ph_request_str = localStorage.getItem('ph_request');
    const ph_request = ph_request_str && ph_request_str !== 'undefined' ? JSON.parse(ph_request_str) : {};
    // ..........

    // data get function
    useEffect(() => {
        let user_data = JSON.parse(localStorage.getItem('userData') || '')
        setCf_code(user_data.cf_code)
        // setPh_code(user_data.ph_code)
        setSourceCf(user_data.is_cf)
        if (user_data.is_cf) {
            setShowaddress(true)
            setPincode(user_data.address.postal_code || '')
            setLocationdetails(user_data.address || '')
        } else {
            setShowaddress(false)
        }
        setCf(user_data.is_cf)
        setUserId(user_data.id)
        setUserEmail(user_data.email)
        setUsername(user_data.user_name)
        setUserMobile(user_data.contact_number)
        setSecEmail(user_data.secondary_email)
        setSecMobile(user_data.secondary_contact_number)
        setUsertype(user_data.user_type)
        setTempUsertype(user_data.user_type)
        setSelectedValue(user_data.email_option === false ? 'Monday,Wednesday,Friday' : 'Daily')
    }, [open]);
    // ..........

    // username availability check function
    const checkUserName = () => {
        let req_body = {
            "name": user_name
        }
        serviceConfig.get('check_user', true, req_body, null).then((data: any) => {
            console.log("data result", data.response)
            setErrUserName("");
        },
            (err: any) => {
                console.log("res err", err.response);
                setErrUserName(err.response.status === 400 ? '' : "This user name is already in use")
            })

    }
    // ..........

    // form validation and field Onchange functions

    const validatePincode = (number: any) => {
        const pattern = /^[1-9][0-9]{5}$/;
        return pattern.test(number);
    };
    const handlePincodeChange = (e: any) => {
        const newval = e.target.value;
        setPincode(newval)
        setErrPincode(validatePincode(newval) ? '' : '*Invalid pincode');
        if (sourcecf) {
            setpinChanged(true)
        }
    }

    const validateMobileNumber = (number: any) => {
        const pattern = /^[0-9]{10}$/;
        return pattern.test(number);
    };
    const handleMobileChange = (e: any, mob: string) => {
        console.log('cheking')
        const newval = e.target.value;
        if (mob === 'primary') {
            setUserMobile(newval);
            setErrUserMobile(validateMobileNumber(newval) ? '' : '*Invalid mobile number');
        } else {
            setSecMobile(newval);
            setErrSecMobile(validateMobileNumber(newval) ? '' : '*Invalid mobile number');
        }
    }

    const validateEmail = (email: any) => {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return pattern.test(email);
    }
    const handleEmailChange = (e: any, mail: any) => {
        const newval = e.target.value;
        if (mail === 'primary') {
            setUserEmail(newval);
            setErruserEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
        } else {
            setSecEmail(newval);
            setErrSecEmail(validateEmail(newval) ? '' : '*Invalid E-Mail id')
        }
    }


    const handlelocationChange = (e: any, newLocation: any) => {
        setLocation(newLocation);
    }
    const opt = ['']

    // ..........

    // pincode  verification
    const postpincode = () => {
        setLoading(true)
        let query: any = {}
        query['pincode'] = pincode;
        serviceConfig.get('checkpin', true, query, null).then((res: any) => {
            setLocationdetails(res.data)
            setShowaddress(true)
            setLoading(false)
            setpinChanged(false)
        },
            (err: any) => {
                setNotifyType("error")
                setNotifyMessage('Kindly Check your Pincode')
                setNotifyOpen(true)
                setLoading(false)
                setShowaddress(false)
            }
        )
    }
    // ..........

    // phpartneregister
    const phpartneregister = () => {
        setPhpopup(!phpopup)
    }
    // ..........

    // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
            Object.keys({ fullname, email, experience, address, ticContactNumber, organisation_name, qualification, team_size, pin_code, city, alt_contactnumber }).some(
                (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
            ),
        [formValues, fullname, email, experience, address, ticContactNumber, organisation_name, qualification, team_size, pin_code, city, alt_contactnumber]
    )
    // ..........

    // user update function Phregister
    const profileupdate = () => {
        if (!sourcecf && accept_cf) {
            if (accept_cf && !locationdetails) {
                setNotifyOpen(true)
                setNotifyType('info')
                setNotifyMessage('Kindly submit your Area Pincode!')
            } else {
                setOpenDialog(true);
                setPopuptype('CF')
            }
        } else if (sourcecf && pinchanged) {
            setNotifyOpen(true)
            setNotifyType('info')
            setNotifyMessage('Kindly submit your Area Pincode!')
        }
        else {
            updateUser()
        }
    }

    const updateUser = () => {
        setLoading(true)
        let body: any = {
            id: user_id,
            user_name: user_name,
            email: user_email.toLowerCase(),
            contact_number: user_mobile,
            secondary_email: sec_email ? sec_email.toLowerCase() : null,
            secondary_contact_number: sec_mobile,
            page: 'self',
            is_cf: accept_cf,
            address: locationdetails,
            user_type: usertype,
            email_option: selectedValue === 'Monday,Wednesday,Friday' ? false : true
        }
        if (new_password !== '**********') {
            body['password'] = new_password
        }
        serviceConfig.put('profile_user', true, body, null).then((res: any) => {
            console.log("response", res);
            { userdata.mobile_verified && !res.mobile_verified && handleSubmitnumber() }

            localStorage.setItem('is_cf', JSON.stringify(res.is_cf))
            localStorage.setItem('cf_code', JSON.stringify(res.cf_code))
            // localStorage.setItem('ph_code', JSON.stringify(res.ph_code))
            setUserdata(res)
            if (!res.is_cf) {
                navigate('/home/gst-dashboard');
            }
            setOpen(false)
            localStorage.setItem('userData', JSON.stringify(res))
            refresh()
            setLoading(false)
            setCf_code(res.cf_code)
            // setPh_code(res.ph_code)
            setSourceCf(res.is_cf)
            if (res.is_cf) {
                setShowaddress(true)
            } else {
                setShowaddress(false)
            }
            setCf(res.is_cf)
        },
            (err: any) => {
                setNotifyType("error")
                console.log(err)
                setNotifyMessage(err.response.data.detail || 'Something went wrong!')
                setNotifyOpen(true)
                setLoading(false)
            }
        )
    }
    // ..........

    // stepper icons customization function
    function profileIcons(props: StepIconProps) {
        const { active, completed, className } = props;
        const icons: { [index: string]: React.ReactElement } = {
            1: <img src={primary} height={40} alt='primary' />,
            2: <img src={secondary} height={40} alt='secondary'></img>
        };

        return (
            <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    const ColorlibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            backgroundColor: 'rgb(237, 125, 33)'
        })
    }));
    // ..........

    //stepper component code
    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };
    // ..........

    // modal close function
    const handleClose = () => {
        setOpen(false);
        setCf(false)
        setLocationdetails(null)
        setErrPincode('')
        setPincode('')
        setShowaddress(false)
        setUsertype('GST Trainee')
        setTempUsertype('GST Trainee')
        setGstcheck('Fresher')
        setSgstcheck('Daily')
        setCgstcheck('Daily')
        setChips([])
    };
    // ..........

    //  function Phregister submit
    const Phregister = () => {
        setPhloading(true)
        let body: any = {
            full_name: fullname.value,
            email: email.value.toLowerCase(),
            experience: experience.value,
            address: address.value,
            phone_no: ticContactNumber.value,
            org_name: organisation_name.value,
            qualification: qualification.value,
            team_size: team_size.value,
            pin_code: pin_code.value,
            city: city.value,
            alt_phone_no: alt_contactnumber.value,
            depth_gst: gstcheck,
            exist_fre_visit_sgst: sgstcheck,
            exist_fre_visit_cgst: cgstcheck,
            area_of_practices: chips,
            approval_status: 'pending',
            user: userData.id,
            ph_request: true
        }
        serviceConfig.post('ph_register', true, body, null).then((res: any) => {
            if (res.id) {
                setPhpopup(false)
            }
            let requestbody: any = {
                id: user_id,
                'ph_request': true
            }
            serviceConfig.put('profile_user', true, requestbody, null).then((res: any) => {
            })
            localStorage.setItem('ph_request', JSON.stringify(res.ph_request))

            setPhloading(false)
            handleClose()

            setNotifyOpen(true)
            setNotifyMessage('Ph registration request submitted successfully.')
            setNotifyType('success')

        },
            (err: any) => {
                setNotifyType("error")
                setNotifyMessage(err)
                setNotifyOpen(true)
                setPhloading(false)
            }
        )
    }
    // ..........

    // Copy to clipboard option function
    const handleCopyClick = () => {
        const content = cf_code
        copy(content);
        setNotifyType("success")
        setNotifyMessage("Refer Code copied successfully.")
        setNotifyOpen(true)
    };
    // ..........

    //checkbox
    const checkboxData = [
        { label: 'Fresher' },
        { label: 'Return Filing' },
        { label: 'Expert' },
        { label: 'Basic Litigation Handling' }
    ];
    const checkboxData1 = [
        { label: 'Daily' },
        { label: 'At least once a week' },
        { label: 'At least once a month' },
    ];
    // ..........

    const [chips, setChips] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            const trimmedInput = inputValue.trim();
            if (!chips.includes(trimmedInput)) {
                setChips([...chips, trimmedInput]);
            }
            setInputValue('');
        }
    };

    const handleChipDelete = (chipIndex: number) => {
        const newChips = chips.filter((_, index) => index !== chipIndex);
        setChips(newChips);
    };

    // radio function 
    const handleradioChange = (event: any) => {
        const newTypeValue = (event.target as HTMLInputElement).value;

        setSelectedValue(newTypeValue);
    };
    // ......

    // confirmation function
    const handleConfirm = () => {
        if (popuptype === 'CF') {
            setOpenDialog(false);
            updateUser()
        } else {
            Phregister()
            setOpenDialog(false);
        }
    };
    // .........

    // usertype change function
    const handlechange = (event: any) => {
        const { value } = event.target;
        setTempUsertype(value);
        setModaltype('Type');
        setOpenDialog(true);
    };

    const userType: any = [
        'GST Trainee',
        'GST Practioner',
        'GST Consultant',
        'GST Accountant',
        'GST Filing Agent',
    ]

    const handlechangetype = () => {
        setUsertype(tempUsertype);
        setOpenDialog(false);
        setModaltype('')
    }

    const handlecanceltype = () => {
        setOpenDialog(false);
        setModaltype('');

    }
    // ..........


    // mobile number submission
    const handleSubmitnumber = () => {
        let query: any = {}
        serviceConfig.get("check_otp", true, query, null).then((data: any) => {
            console.log(data)

            setOtpmodal(true)
        }, (err: any) => {
            console.log(err)
            setNotifyType("error")
            setNotifyMessage('Something Went Wrong !')
            setNotifyOpen(true)
        })
    }
    // ..........

    // otp field
    const handleChangeotp = (value: string, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);


        // Move focus to the next input if a digit is entered
        if (value && index < otp.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            nextInput?.focus();
        }

        setErr_otp('')
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            prevInput?.focus();
        }
    };

    const validateOtp = (): boolean => {
        // Check if any of the OTP fields are empty
        return otp.every((digit) => digit !== '');
    };
    // ..........

    // otp verification function
    const verification = () => {
        if (!loading) {
            if (!validateOtp()) {
                if (!validateOtp()) {
                    setErr_otp('*Please enter all 4 digits of the OTP');
                    return;
                }
            } else {
                setLoading(true)

                let req_body = {
                    'otp': otp.join('')
                }
                serviceConfig.post('verify_otp', true, req_body, null).then((res: any) => {
                    setLoading(false)
                    setNotifyType("success")
                    setNotifyMessage('User mobile number verified successfully.')
                    setNotifyOpen(true)
                    setOtp(['', '', '', ''])

                },
                    (err: any) => {
                        console.log(err, 'cheking')
                        setNotifyType("error")
                        setNotifyMessage(err && err.response && err.response.data.error || 'Something Went Wrong !')
                        setNotifyOpen(true)
                        setLoading(false)
                    }
                )
            }
        }
    }
    // ..........

    return (
        <>
            <BootstrapDialog
                onClose={() => { setOtpmodal(false); setOtp(['', '', '', '']) }}
                aria-labelledby='customized-dialog-title'
                open={otpmodal}
                id='confirm_container'>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">

                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setOtpmodal(false); setOtp(['', '', '', ''])
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent
                    dividers
                    style={{ padding: "0px 20px 11px 20px" }}>
                    <div className="verify-container">
                        <h4 className='terminal-window-mainline heading'>OTP verification</h4>
                        <p className="terminal-window-subline">Otp sended to your registered Mobile number for verfication.</p>
                        <div className="col-sm-12 otp-group" style={{ paddingBottom: err_otp ? '0%' : '' }}>
                            <div className='otp-cover'>
                                {otp.map((digit, index) => (
                                    <ThemeProvider theme={inputtheme}>
                                        <OutlinedInput
                                            className='otp_input'
                                            key={index}
                                            id={`otp-input-${index}`}
                                            autoComplete='off'
                                            value={digit}
                                            onChange={(e: any) => handleChangeotp(e.target.value, index)}
                                            onKeyDown={(e: any) => handleKeyDown(e, index)}
                                            inputProps={{
                                                maxLength: 1,
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </ThemeProvider>
                                ))}

                            </div>
                            <FormHelperText id='error-line' style={{ color: '#E10000', fontSize: '1.8vmin ', textAlign: 'right' }} >{err_otp}</FormHelperText>
                        </div>
                        <div className="col-sm-12 input-group">
                            <button className="register teminal-primary-btn submt_btn" onClick={verification}>{loading ? 'Loading...' : 'Submit'}</button>
                        </div>
                    </div>
                </DialogContent>
            </BootstrapDialog >
            <BootstrapDialog
                onClose={() => { setPhpopup(false); handleInitial(); handleClose() }}
                aria-labelledby="customized-dialog-title"
                open={phpopup}
                id='ph_partner_container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">
                    PH Partner Registration
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setPhpopup(false); handleInitial(); handleClose() }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers className='dialog-content' style={{ marginTop: '0%' }}>
                    <div className='ph-content-cover'>
                        <div className='ph-content-split'>
                            <div className="fields">
                                <div className="row">
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0rem' }}>
                                        <label className="label">Full Name</label>
                                        <span>{fullname.error}</span>
                                        <div className="input-box" >
                                            {/* <img className="icons" src={fullnameIcon} alt='usernameIcon'></img> */}
                                            <input
                                                type="text"
                                                placeholder="Enter your full name"
                                                value={fullname.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                                autoComplete='off'
                                                name="fullname"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Email</label>
                                        <span>{email.error}</span>
                                        <div className="input-box" >
                                            {/* <img className="icons" src={emailicon} alt='emailicon'></img> */}
                                            <input
                                                type="text"
                                                placeholder="Enter your Email"
                                                readOnly
                                                value={userData.email}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                                autoComplete='off'
                                                name="email" ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Experience (Yrs)</label>
                                        <span>{experience.error}</span>
                                        <div className="input-box" >
                                            {/* <img className="icons" src={Exp_icon} alt='Exp_icon'></img> */}
                                            <input
                                                type="text"
                                                placeholder="Enter your experience"
                                                name='experience'
                                                value={experience.value}
                                                onBlur={handleChange}
                                                autoComplete='off'
                                                onChange={handleChange}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Address</label>
                                        <span>{address.error}</span>
                                        <div className="input-box" >
                                            <input type="text"
                                                placeholder="Enter your user name"
                                                autoComplete='off'
                                                name='address'
                                                value={address.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Phone Number</label>
                                        <span>{ticContactNumber.error}</span>
                                        <div className="input-box" >
                                            <input type="text"
                                                placeholder="Enter your phone number"
                                                autoComplete='off'
                                                name='ticContactNumber'
                                                value={ticContactNumber.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Existing Frequency of visits to CGST Department :</label>
                                    </div>
                                    <div className='checkbox-over-wrap'>
                                        {checkboxData1.map((item, index) => (
                                            <div className='checkbox-cover' key={index} onClick={() => setCgstcheck(item.label)}>
                                                <span
                                                    className={cgstcheck === item.label ? 'checked_box' : 'check_box'}

                                                ></span>
                                                <div className="label">{item.label}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Existing Frequency of visits to SGST Department :</label>
                                    </div>
                                    <div className='checkbox-over-wrap'>
                                        {checkboxData1.map((item, index) => (
                                            <div className='checkbox-cover' key={index} onClick={() => setSgstcheck(item.label)}>
                                                <span
                                                    className={sgstcheck === item.label ? 'checked_box' : 'check_box'}

                                                ></span>
                                                <div className="label">{item.label}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='ph-content-split'>
                            <div className="fields">
                                <div className="row">
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0rem' }}>
                                        <label className="label">Organisation Name</label>
                                        <span>{organisation_name.error}</span>
                                        <div className="input-box" >
                                            <input
                                                type="text"
                                                placeholder="Enter your organisation name"
                                                autoComplete='off'
                                                name='organisation_name'
                                                value={organisation_name.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Qualification</label>
                                        <span>{qualification.error}</span>
                                        <div className="input-box" >
                                            <input type="text"
                                                placeholder="Enter your qualification"
                                                autoComplete='off'
                                                name='qualification'
                                                value={qualification.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Team Size</label>
                                        <span>{team_size.error}</span>
                                        <div className="input-box" >
                                            <input
                                                type="text"
                                                placeholder="Enter your team size"
                                                autoComplete='off'
                                                name='team_size'
                                                value={team_size.value}
                                                onBlur={handleChange}
                                                onChange={handleChange} ></input>
                                        </div>
                                    </div>
                                    <div style={{ width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem', width: '48%' }}>
                                            <label className="label">Pin Code</label>
                                            <span>{pin_code.error}</span>
                                            <div className="input-box" >
                                                <input type="text"
                                                    placeholder="Enter your pin code"
                                                    autoComplete='off'
                                                    name='pin_code'
                                                    value={pin_code.value}
                                                    onBlur={handleChange}
                                                    onChange={handleChange} ></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem', width: '48%' }}>
                                            <label className="label">City</label>
                                            <span>{city.error}</span>
                                            <div className="input-box" >
                                                <input type="text"
                                                    name='city'
                                                    value={city.value}
                                                    placeholder="Enter your city"
                                                    autoComplete='off'
                                                    onBlur={handleChange}
                                                    onChange={handleChange} ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Alternative Phone Number</label>
                                        <span>{alt_contactnumber.error}</span>
                                        <div className="input-box" >
                                            <input type="text"
                                                placeholder="Enter your alter phone number"
                                                autoComplete='off'
                                                name='alt_contactnumber'
                                                value={alt_contactnumber.value}
                                                onBlur={handleChange}
                                                onChange={handleChange}  ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label">Depth of GST Practice :</label>
                                    </div>
                                    <div className='checkbox-over-wrap'>
                                        {checkboxData.map((item, index) => (
                                            <div className='checkbox-cover' key={index} onClick={() => setGstcheck(item.label)}>
                                                <span
                                                    className={gstcheck === item.label ? 'checked_box' : 'check_box'}

                                                ></span>
                                                <div className="label">{item.label}</div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.6rem' }}>
                                        <label className="label" id="area-label" style={{ marginBottom: '0.6rem' }}>Areas of practice</label>
                                        <div className="chip-input-container">
                                            {chips.map((chip, index) => (
                                                <div key={index} className="chip">
                                                    {chip}
                                                    <button onClick={() => handleChipDelete(index)}>x</button>
                                                </div>
                                            ))}
                                            <div className="input-box" >
                                                <input
                                                    type="text"
                                                    placeholder="Type and press Enter to add Area"
                                                    name='alt_contactnumber'
                                                    value={inputValue}
                                                    onKeyDown={handleInputKeyDown}
                                                    autoComplete='off'
                                                    onChange={handleInputChange}  ></input>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 btn-group " id="last-one" >
                        <button disabled={isError()} className="register_ph" onClick={() => { setOpenDialog(true); setPopuptype('PH') }}>{!loading ? 'submit for registration' : 'Loading...'}</button>
                    </div>
                </DialogContent>
            </BootstrapDialog >
            <BootstrapDialog
                onClose={() => {
                    handleClose();
                }}
                aria-labelledby="customized-dialog-title"
                open={open}
                id='profile_container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="pwd">
                    My Profile
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers style={{ marginTop: '0%' }}>

                    <div>
                        <Box sx={{ width: '100%' }}>
                            <Stepper nonLinear activeStep={activeStep} alternativeLabel style={{ width: '80%', marginLeft: '10%' }}>
                                {steps.map((label, index) => (
                                    <Step key={label} completed={completed[index]}>
                                        <StepLabel StepIconComponent={profileIcons} onClick={handleStep(index)}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                <React.Fragment>
                                    <div>
                                        {activeStep === 0 && <div>
                                            <div className="fields">
                                                <div className="row">
                                                    {userData.role.role_name === 'User' && <div className="col-sm-12  cf_cover">
                                                        {sourcecf ?
                                                            <div className='cf_code-wrap' >
                                                                <span>Certified Partner Code -</span>
                                                                <span><b>{cf_code}</b>
                                                                    <ContentCopyIcon fontSize='small' className='clipboard-icon' onClick={handleCopyClick} />
                                                                </span>
                                                            </div> :
                                                            <>
                                                                <span className={!accept_cf ? 'check_box' : 'checked_box'} onClick={() => {
                                                                    setCf(!accept_cf);
                                                                    setLocationdetails(null)
                                                                    setErrPincode('')
                                                                    setPincode('')
                                                                    setShowaddress(false)
                                                                }}>
                                                                    <span className="input-error-line">
                                                                        <div className="checkmark_stem"></div>
                                                                        <div className="checkmark_kick"></div>
                                                                    </span>
                                                                </span>
                                                                <div className="title">Include Certified Partner.
                                                                    {/* <PopupState variant="popover" popupId="demo-popup-popover">
                                                                        {(popupState: any) => (
                                                                            <div className='info-icon-wrap' >
                                                                                <InfoIcon className='info-icon'  {...bindTrigger(popupState)} />
                                                                                <Popover
                                                                                    {...bindPopover(popupState)}
                                                                                    anchorOrigin={{
                                                                                        vertical: 'bottom',
                                                                                        horizontal: 'center',
                                                                                    }}
                                                                                    transformOrigin={{
                                                                                        vertical: 'top',
                                                                                        horizontal: 'center',
                                                                                    }}
                                                                                >
                                                                                    <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
                                                                                </Popover>
                                                                            </div>
                                                                        )}
                                                                    </PopupState> */}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>}

                                                    {accept_cf &&
                                                        <div className="col-sm-12 pin_input-group" style={{ marginTop: '0.8rem', alignItems: err_pincode ? 'center' : 'flex-end' }}>
                                                            <div className='input-wrap'>
                                                                <label className="label">Pincode</label>

                                                                <div className="input-box" >
                                                                    <input type="text"
                                                                        placeholder="Enter your Pincode"
                                                                        value={pincode}
                                                                        onChange={handlePincodeChange}
                                                                        onBlur={handlePincodeChange}
                                                                    // readOnly={sourcecf === true}
                                                                    ></input>
                                                                    <span>{err_pincode}</span>
                                                                </div>
                                                            </div>
                                                            <button className="pincode_btn" onClick={postpincode}>
                                                                <SearchIcon className='pincode_search' />
                                                            </button>
                                                        </div>
                                                    }
                                                    {showaddress &&
                                                        <><div className="col-sm-12 input-group" style={{ marginTop: err_pincode ? '0' : '0.8rem' }}>
                                                            <label className="label">District</label>
                                                            <div className="input-box" >
                                                                <input type="text" placeholder="Your District" readOnly value={locationdetails && locationdetails.district} onChange={(e) => setUsername(e.target.value)}></input>
                                                            </div>
                                                        </div>
                                                            <div className="col-sm-12 input-group" style={{ marginTop: '0.8rem' }}>
                                                                <label className="label">State</label>
                                                                <div className="input-box" >
                                                                    <input type="text" placeholder="Your State" readOnly value={locationdetails && locationdetails.state} onChange={(e) => setUsername(e.target.value)}></input>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {/* {!accept_cf && <div className="col-sm-12 input-group " id="last-one" style={{ marginTop: '1rem' }}>
                                                        <button className="ph-partner-register" onClick={phpartneregister}>{!loading ? 'PH Partner Registration' : 'Loading...'}</button>
                                                    </div>} */}


                                                    <div className="col-sm-12 input-group" style={{ marginTop: err_pincode ? '0' : '0.8rem' }}>
                                                        <label className="label">User Name</label>
                                                        <span>{err_user_name}</span>
                                                        <div className="input-box" >
                                                            <input type="text" placeholder="Enter your user name" value={user_name} onChange={(e) => setUsername(e.target.value)}
                                                                onBlur={checkUserName} ></input>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 input-group" style={{ marginTop: '1.2rem' }}>
                                                        <label className="label">Email</label>
                                                        <div className="input-box" >
                                                            <input type="text" readOnly placeholder="Enter your registered mail id" value={user_email}
                                                                onChange={e => handleEmailChange(e, 'primary')} ></input>
                                                            {<span>{err_user_email}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 input-group" style={{ marginTop: '1.2rem' }}>
                                                        <label className="label">Mobile Number</label>
                                                        <div className="input-box" style={{ border: 'none' }}>

                                                            <ThemeProvider theme={inputtheme}>
                                                                <OutlinedInput
                                                                    autoComplete='off'
                                                                    style={{ width: '100%' }}
                                                                    className='inputfield'
                                                                    id="outlined-adornment-weight"
                                                                    placeholder='Enter your registered mobile number'
                                                                    value={user_mobile}
                                                                    error={!!err_user_mobile}
                                                                    onChange={e => handleMobileChange(e, 'primary')}
                                                                    endAdornment={
                                                                        userData && !userData.mobile_verified && (
                                                                            <InputAdornment position="end">
                                                                                < IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={handleSubmitnumber}
                                                                                    edge="end"
                                                                                    disabled={!!err_user_mobile || !user_mobile}
                                                                                >
                                                                                    <SendIcon
                                                                                        style={{
                                                                                            color: !!err_user_mobile || !user_mobile ? '#E96919' : '#05103E',
                                                                                            cursor: !!err_user_mobile || !user_mobile ? 'not-allowed' : 'pointer',
                                                                                        }} />
                                                                                </IconButton>
                                                                            </InputAdornment>)
                                                                    }
                                                                    aria-describedby="outlined-weight-helper-text"
                                                                />
                                                            </ThemeProvider>
                                                            <span>{err_user_mobile}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 input-group" style={{ marginTop: '1.2rem' }}>
                                                        <label className="label">User Type</label>
                                                        <TextField
                                                            fullWidth
                                                            focused
                                                            select
                                                            SelectProps={{ native: true, }}
                                                            variant='outlined'
                                                            name="casetype"
                                                            value={usertype}
                                                            onChange={handlechange}
                                                            autoComplete='off'
                                                            style={{ margin: '2% 0' }}
                                                        >
                                                            <option value='' disabled>...</option>
                                                            {userType.map((type: any) =>
                                                                <option key={type} value={type}>{type}</option>
                                                            )}
                                                        </TextField>
                                                    </div>

                                                    <div className="col-sm-12 input-group" style={{ marginTop: '0.7rem' }}>
                                                        <label className="label">Mail Frequent</label>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-form-control-label-placement"
                                                            id='radio-grp'
                                                        >
                                                            <FormControlLabel
                                                                value="Monday,Wednesday,Friday"
                                                                id='first-radio'
                                                                control={
                                                                    <Radio
                                                                        checked={selectedValue === 'Monday,Wednesday,Friday'}
                                                                        onChange={handleradioChange}
                                                                        value="Monday,Wednesday,Friday"
                                                                        name="radio-buttons"
                                                                    />}
                                                                label="Monday,Wednesday,Friday" />
                                                            <FormControlLabel
                                                                value="Daily"
                                                                control={
                                                                    <Radio
                                                                        checked={selectedValue === 'Daily'}
                                                                        onChange={handleradioChange}
                                                                        value="Daily"
                                                                        name="radio-buttons"
                                                                    />}
                                                                label="Daily" />
                                                        </RadioGroup>
                                                    </div>

                                                    <div className="col-sm-12 input-group " id="last-one" >
                                                        {/* <button className="ph-partner-register" onClick={phpartneregister}>{!phloading ? 'PH Partner Registration' : 'Loading...'}</button> */}
                                                        {userData.role.role_name === 'User' && !ph_request && <button className="ph-partner-register" onClick={phpartneregister}>{!phloading ? 'PH Partner Registration' : 'Loading...'}</button>}
                                                        <button className="register" onClick={profileupdate} >{!loading ? 'Save' : 'Loading...'}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {activeStep === 1 && <div>

                                            <div className="fields">
                                                <div className="row">
                                                    <div className="col-sm-12 input-group">
                                                        <label className="label">Secondary Email</label>
                                                        <div className="input-box" >
                                                            <input type="text" placeholder="Enter your registered mail id" value={sec_email}
                                                                onChange={e => handleEmailChange(e, 'sec')} ></input>
                                                            {<span>{err_sec_email}</span>}
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 input-group">
                                                        <label className="label">Seconday Mobile Number</label>
                                                        <div className="input-box" >
                                                            <input type="text" placeholder="Enter your mobile number" value={sec_mobile} onChange={e => handleMobileChange(e, 'sec')}></input>
                                                            <span>{err_sec_mobile}</span>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 input-group" id='secondary-sav-btn'>
                                                        <button className="register" onClick={profileupdate}>{!loading ? 'Save' : 'Loading...'}</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                    </div>
                                </React.Fragment>

                            </div>
                        </Box>
                    </div>

                </DialogContent >
            </BootstrapDialog >
            <BootstrapDialog
                onClose={() => { setOpenDialog(false) }}
                aria-labelledby='customized-dialog-title'
                open={openDialog}
                id='confirm_container'>
                <DialogTitle
                    sx={{ m: 0, p: 1 }}
                    className='confirm-head'>
                    Confirmation
                </DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={() => { setOpenDialog(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                </IconButton>
                {modaltype === 'Type' ?
                    <DialogContent
                        dividers
                        style={{ padding: "0px 20px 11px 20px" }}>
                        <p className="confirm-msg">Are you sure, Do you want to Change your User type ?</p>
                        <div>
                            <button
                                className='confirm-btn no-btn'
                                onClick={handlechangetype}>Yes</button>
                            <button
                                className="confirm-btn"
                                onClick={handlecanceltype}>No</button>

                        </div>
                    </DialogContent>
                    : <DialogContent
                        dividers
                        style={{ padding: "0px 20px 11px 20px" }}>
                        <p className="confirm-msg">Are you sure, Do you want to Change your Account type ?</p>
                        <div>
                            <button
                                className='confirm-btn no-btn'
                                onClick={handleConfirm}>Yes</button>
                            <button
                                className="confirm-btn"
                                onClick={() => { setOpenDialog(false) }}>No</button>

                        </div>
                    </DialogContent>}
            </BootstrapDialog >
            < NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType} ></NotifyAlert >
        </>
    )
}
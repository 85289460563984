import React from 'react';
import './compliance.scss';

type FilingPeriod = {
  fy: string;
  taxp: string;
  mof: string;
  dof: string;
  rtntype: string;
  status: string;
  filingStatus?: string;
};

type FiledData = {
  [key: string]: FilingPeriod[];
};

type Props = {
  compli: {
    filed_data: FiledData;
  };
};

const StatusTable: React.FC<Props> = ({ compli }) => {
  const { filed_data } = compli;
  const filedDataKeys = Object.keys(filed_data);

  return (
    <div className='status-table-column'>
      {filedDataKeys.map((formName, index) => (
        <div
          className={index === 1 ? 'status-second-row' : 'status-first-row'}
          key={formName}
        >
          <div className='row-header'>
            <span>{formName}</span>
          </div>
          <div className='row-card-wrap'>
            {filed_data[formName].map((retPrd, idx) => (
              <div
                key={idx}
                className={retPrd.status !== 'Filed' ? 'row-card row-card-active' : 'row-card'}
              >
                <span className='row-card-mainline'>{retPrd.taxp}</span>
                <span className='row-card-mainline'>{retPrd.fy}</span>
                <span className='row-card-subline'>{retPrd.status === 'Not Filed' ? 'To be filed' : retPrd.status}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StatusTable;
